<div class="conversation-messages-wrapper">
    <div class="conversation-messages-container">
        <div class="messages-container">
            <div class="message" *ngFor="let message of messages; index as i; trackBy: identify"
                [ngClass]="{
                    'sameAuthor': messages[i + 1]?.author?.userId === message.author.userId,
                    'myMessage': message.author.userId === myUserId,
                    'otherMessage': message.author.userId !== myUserId,
                    'lastSameAuthor2': messages[i + 1]?.author?.userId !== message.author.userId,
                }"
            >
                <div class="my-message" *ngIf="message.author.userId === myUserId">
                    
                    <div class="message-content">
                        <div class="message-text">{{message.content}}</div>
                    </div>
                    <hype-s-profile-picture-custom 
                        class="profile-picture" 
                        [size]="'30px'" [borderSize]="'2px'" 
                        [url]="message.author.profileImage.url"
                        *ngIf="messages[i + 1]?.author?.userId !== message.author.userId"
                    ></hype-s-profile-picture-custom>
                    <div class="message-carrot">
                        <svg width="17" height="19" viewBox="0 0 17 19"  xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 19C6.5 12.5 10.5 7.5 10 0.5L0 13.9119C3.46667 15.7453 4.5 19 17 19Z"/>
                            </svg>
                            
                    </div>
                </div>
                <div class="other-message" *ngIf="message.author.userId !== myUserId">
                    <hype-s-profile-picture-custom 
                        class="profile-picture" 
                        [size]="'30px'" [borderSize]="'2px'" 
                        [url]="message.author.profileImage.url"
                        *ngIf="messages[i + 1]?.author?.userId !== message.author.userId"
                    ></hype-s-profile-picture-custom>
                    <div class="message-content">
                        <!-- <div class="message-header">
                            <div class="message-sender">{{message.author.username}}</div>
                            <div class="message-timestamp">{{message.timestamp}}</div>
                        </div> -->
                        <div class="message-text">{{message.content}}</div>
                    </div>
                    <div class="message-carrot">
                        <svg width="17" height="19" viewBox="0 0 17 19" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 19C10.5 12.5 6.5 7.5 7 0.5L17 13.9119C13.5333 15.7453 12.5 19 0 19Z"/>
                            </svg>
                            
                    </div>
                </div>
                
            </div>
        </div>
        <div class="messages-footer">
            <hype-s-profile-picture-custom class="profile-picture" [size]="'50px'" [borderSize]="'2px'" [url]="profileImage"></hype-s-profile-picture-custom>
            <input [(ngModel)]="newMessage" type="text" placeholder="Write a message..." >
            <button class="send-message-button" (click)="addMessage()">
                <hype-ion-icon-wrapper [iconName]="'send'"></hype-ion-icon-wrapper>
            </button>
            <button class="add-image-button">
                <hype-ion-icon-wrapper [iconName]="'image'" [iconSize]="'30px'"></hype-ion-icon-wrapper>
            </button>
            <button class="view-members-button" (click)="showGroupMembers()">
                <hype-ion-icon-wrapper [iconName]="'people'" [iconSize]="'30px'"></hype-ion-icon-wrapper>
            </button>
        </div>
    </div>
</div>

<hype-overlay [(display)]="showingGroupMembersMenu">
    <hype-conversation-members [conversationId]="conversationId" [(display)]="showingGroupMembersMenu"></hype-conversation-members>
</hype-overlay>