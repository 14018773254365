import { Component } from '@angular/core';

@Component({
  selector: 'hype-feed-post-comments-button',
  templateUrl: './feed-post-comments-button.component.html',
  styleUrls: ['./feed-post-comments-button.component.scss']
})
export class FeedPostCommentsButtonComponent {

}
