import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MessageFactory } from 'src/app/Models/Message.model';
import { environment } from 'src/environments/environment';
import * as SockJS from 'sockjs-client';
import { io } from 'socket.io-client';
// import * as Stomp from 'stompjs';
import { WebSocketService } from '../WebSocket/web-socket.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private readonly conversationsUrl = `${environment.apiBaseUrl}/core-api/v2/conversations`;
  private readonly webSocketUrl = `${environment.wsBaseUrl}/hypechat-api`;
  private socket: WebSocket | null = null;
  private messagesSubject: Subject<any> = new Subject<any>();
  public messagesObservable: Observable<any> = this.messagesSubject.asObservable();

  constructor(
    private http: HttpClient,
    private webSocketService: WebSocketService
  ) { }

  async getConversationMessages(conversationId: string, pageNumber: number) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);

    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      }
    };

    let response = await this.http.post(`${this.conversationsUrl}/${conversationId}/messages`, body, { headers: headers }).toPromise();
    return MessageFactory.shared.createArrayFromHttp(response);
  }

  // connect(conversationId: string) {
  //   let sessionToken = localStorage.getItem('sessionId') || '';
  //   let myUserId = localStorage.getItem('userId') || '';
  //   let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
  //   let deviceId = localStorage.getItem('deviceId') || 'web1';
  //   headers = headers.set('X-UserId', myUserId);
  //   headers = headers.set('X-DeviceId', deviceId);

  //   let socket = new SockJS(`${this.webSocketUrl}?conversationId=${conversationId}`);
  //   let stompClient = Stomp.over(socket);

  //   stompClient.connect({ 'X-UserId': myUserId, 'X-DeviceId': deviceId, 'authorization': sessionToken }, () => {
  //     console.log('WebSocket connection opened');
  //     stompClient.subscribe(`/topic/conversations/${conversationId}/messages`, (message) => {
  //       console.log('WebSocket message:', message.body);
  //       this.messagesSubject.next(message.body);
  //     });
  //   });
  // }

  async connect(conversationId: string) {
    let sessionToken = localStorage.getItem('sessionId');
    let myUserId = localStorage.getItem('userId');
    let deviceId = localStorage.getItem('deviceId');
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);

    if (!sessionToken || !myUserId || !deviceId) {
      console.error('Missing session token, userId, or deviceId');
      return;
    }

    headers = headers.set('X-UserId', myUserId || '');
    headers = headers.set('X-DeviceId', deviceId || '');

    // Try to get a socketToken
    let socketToken = await this.webSocketService.getWebSocketToken();

    this.socket = new WebSocket(`${this.webSocketUrl}?conversationId=${conversationId}&socketToken=${socketToken}&userId=${myUserId}`);

    this.socket.onopen = () => {
      console.log('WebSocket connection opened');
      // Send the userId in the headers
      // this.socket?.send(JSON.stringify({ 
      //   action:'connect', 
      //   'X-UserId': myUserId,
      //   'X-DeviceId': 'web1',
      //   'authorization': sessionToken
      // }));
    }

    this.socket.onmessage = (event) => {
      console.log('WebSocket message:', event.data);
      this.messagesSubject.next(event.data);
    }

    this.socket.onclose = () => {
      console.log('WebSocket connection closed');
    }

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    }
  }

  getMessages() {
    return this.messagesSubject.asObservable();
  }

  // connect(conversationId: string) {
  //   let sessionToken = localStorage.getItem('sessionId');
  //   let myUserId = localStorage.getItem('userId');
  //   let deviceId = localStorage.getItem('deviceId');
  //   let headers = new HttpHeaders().set('authorization', `${sessionToken}`);

  //   if (!sessionToken || !myUserId || !deviceId) {
  //     console.error('Missing session token, userId, or deviceId');
  //     return;
  //   }

  //   headers = headers.set('X-UserId', myUserId || '');
  //   headers = headers.set('X-DeviceId', deviceId || '');

  //   const socket = io(`${this.webSocketUrl}?conversationId=${conversationId}`, {
  //     extraHeaders: {
  //       'authorization': sessionToken,
  //       'X-UserId': myUserId,
  //       'X-DeviceId': deviceId,
  //     }
  //   });

  //   socket.on('connect', () => {
  //     console.log('WebSocket connection opened');
  //   });

  //   socket.on('message', (message) => {
  //     console.log('WebSocket message:', message);
  //     this.messagesSubject.next(message);
  //   });

  //   socket.on('disconnect', () => {
  //     console.log('WebSocket connection closed');
  //   });

  //   socket.on('error', (error) => {
  //     console.error('WebSocket error:', error);
  //   });

    
  // }

  sendMessage(message: string) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);

    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ action: 'sendMessage', message: message }));
    } else {
      console.error('WebSocket is not connected');
    }
  }

  disconnect() {
    if (this.socket) { this.socket.close(); }
  }
}
