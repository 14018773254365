import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Post, PostFactory } from 'src/app/Models/Post.model';
import { User, UserFactory } from 'src/app/Models/User.model';
import { FollowingsService } from 'src/app/Services/Followings/followings.service';

@Component({
  selector: 'hype-feed-post-header',
  templateUrl: './feed-post-header.component.html',
  styleUrls: ['./feed-post-header.component.scss']
})
export class FeedPostHeaderComponent implements AfterViewInit, OnChanges {
  @Input() post: Post = PostFactory.shared.createMock();
  @Input() user: User = UserFactory.shared.createMock();

  constructor(
    private router: Router,
    private followingsService: FollowingsService,
  ) { }

  ngAfterViewInit(): void {
    console.log('User:', this.user);
  }
  ngOnChanges(changes: SimpleChanges): void {

  }

  async goToProfile() {
    console.log('Going to profile', this.user.userhandle);
    this.router.navigate([`/profile/${this.user.userhandle}`]);
  }

  async followButtonClicked(){
    console.log('Follow button clicked');
    const response = await this.followingsService.followUser(this.user.userId);
    if(response.status === 200){
      console.log('Followed user');
      this.user.isFollowing = true;
    }
  }
}
