import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CommentV2 } from 'src/app/Models/Comment.model';
import { PostV2, PostV2Factory } from 'src/app/Models/Post.model';
import { UserFactory } from 'src/app/Models/User.model';
import { PostsService } from 'src/app/Services/Posts/posts.service';
import { UserManagementService } from 'src/app/Services/user-management.service';

@Component({
  selector: 'hype-profile-content-posts',
  templateUrl: './profile-content-posts.component.html',
  styleUrls: ['./profile-content-posts.component.scss']
})
export class ProfileContentPostsComponent implements AfterViewInit, OnInit {
  // posts = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
  posts = PostV2Factory.shared.createMockArray(0);
  commentAuthor = UserFactory.shared.createMock()
  @Input() user = UserFactory.shared.createMock();
  showingComments: boolean = false;
  postId: string = '';
  iconSize = '20px';
  pageNumber: number = 1;

  constructor(
    private postsService: PostsService,
    private userManagementService: UserManagementService,
  ) { }
  
  async ngOnInit() {
    // this.posts = await PostV2Factory.shared.asyncCreateMockArray(5);
    this.handleScroll();
    // console.log(this.posts);
    // this.posts = [];
  }
  ngAfterViewInit(): void {
    // console.log('Profile content posts component initialized');
  }

  async handleScroll() {
    this.posts = this.posts.concat(await this.postsService.getUserPosts(this.user.userId, this.pageNumber));
    this.pageNumber++;
  }

  likePostButtonClicked(post: PostV2){
    console.log('Like button clicked:', post);
    if (post.isLiked){
      this.postsService.unlikePost(post.postId).then((response) => {
        console.log('Unlike response:', response);
        post.isLiked = false;
        post.hypescore -= 1;
      }).catch((error) => {
        console.error('Unlike error:', error);
      });
    } else {
      this.postsService.likePost(post.postId).then((response) => {
        console.log('Like response:', response);
        post.isLiked = true;
        post.hypescore += 1;
      }).catch((error) => {
        console.error('Like error:', error);
      });
    }
  }

  async viewCommentsButtonClicked(post: PostV2){
    console.log('View comments button clicked:', post);
    this.postId = post.postId;
    this.showingComments = !this.showingComments;
  }

  // getPosts(userId: string): Promise<[PostV2]>{
  //   // let userId = localStorage.getItem('userId') || '';

  //   this.posts =  this.postsService.getUserPosts(userId) as Promise<[PostV2]>;
  //   return this.posts;
  // }
}
