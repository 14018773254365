import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { PartyV2, PartyV2Factory } from 'src/app/Models/Party.model';

@Component({
  selector: 'hype-party-list-row',
  templateUrl: './party-list-row.component.html',
  styleUrls: ['./party-list-row.component.scss']
})
export class PartyListRowComponent implements OnInit, AfterViewInit {
  parties: PartyV2[] = PartyV2Factory.createMockPartyArray(0);
  @Input() previewParties: PartyV2[] = [];
  previewCount: number = 10;
  showingPartyDetails: boolean = false;
  selectedParty: PartyV2 = PartyV2Factory.shared.createMock();
  constructor() { }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  ngAfterViewInit(): void {
    for (let i = 0; i < this.previewCount ; i++) {
      if (i >= this.parties.length) break;
      this.previewParties.push(this.parties[i]);
    }
  }
  showPartyDetails(party: PartyV2) {
    console.log('Show party details:', party);
    this.selectedParty = party;
    this.showingPartyDetails = true;
  }
  closePartyDetails() {
    this.showingPartyDetails = false;
  }
}
