import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Post, PostFactory } from 'src/app/Models/Post.model';
import { CommentFactory, CommentV2, CommentV2Factory } from 'src/app/Models/Comment.model';
import { User, UserFactory } from 'src/app/Models/User.model';
import { UserManagementService } from 'src/app/Services/user-management.service';
import { IonIconWrapperComponent } from 'src/app/Components/Common/ion-icon-wrapper/ion-icon-wrapper.component';
import { SProfilePictureCustomComponent } from 'src/app/Components/Common/s-profile-picture-custom/s-profile-picture-custom.component';
import { SFeedPostCommentsComponent } from './s-feed-post-comments/s-feed-post-comments.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { PostsService } from 'src/app/Services/Posts/posts.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@Component({
  selector: 'hype-feed-post-comments',
  standalone: true,
  imports: [
    CommonModule, 
    IonIconWrapperComponent,
    SProfilePictureCustomComponent,
    SFeedPostCommentsComponent,
    ReactiveFormsModule,
    InfiniteScrollModule
  ],
  templateUrl: './feed-post-comments.component.html',
  styleUrls: ['./feed-post-comments.component.scss']
})
export class FeedPostCommentsComponent {
  @Input() post: Post = PostFactory.shared.createMock();
  @Input() display: boolean = false;
  @Output() displayChange = new EventEmitter<boolean>();
  @Input() postId = '';
  comments: CommentV2[] = [];
  user = UserFactory.shared.createMock();
  numComments: number = 0;
  pageNumber: number = 2;

  commentText = new FormControl('', Validators.required);

  constructor(
    private userManagementService : UserManagementService,
    private postsService: PostsService,
  ) { }
  
  async handleScroll() {
    await this.postsService.getPostComments(this.postId, this.pageNumber).then(response => {
      this.comments = this.comments.concat(response[1])
    })
    this.pageNumber++;
  }
  
  async ngOnInit () {
    this.user = await this.userManagementService.getMyUser() || UserFactory.shared.createMock();
  }

  async ngOnChanges() {
    this.commentText.setValue('');
    if (this.postId !== '' && this.postId !== undefined && this.postId !== null && this.display === true) {
      await this.postsService.getPostComments(this.postId, 1).then(response => {
        this.comments = response[1];
        this.numComments = response[0].data.maxRecords;
      })
    }
  }

  closeComments() {
    this.display = false;
    this.displayChange.emit(this.display);
  }

  async sendComment() {
    const commentText = this.commentText.value || '';
    this.postsService.createComment(this.postId, commentText).then(async response => {
      var newComment = CommentV2Factory.createCommentFromData(response);
      this.comments.unshift(newComment);
    })
    this.commentText.setValue('');
  }

  updateCommentArray(deletedCommentId: string) {
    this.comments = this.comments.filter((comment) => comment.commentId !== deletedCommentId);
  }
}
