import { Component } from '@angular/core';

@Component({
  selector: 'hype-see-all-followed-parties',
  templateUrl: './see-all-followed-parties.component.html',
  styleUrls: ['./see-all-followed-parties.component.scss']
})
export class SeeAllFollowedPartiesComponent {

}
