import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PartyV2Factory } from 'src/app/Models/Party.model';
import { PartyService } from 'src/app/Services/Party/party.service';

@Component({
  selector: 'hype-party-management',
  templateUrl: './party-management.component.html',
  styleUrls: ['./party-management.component.scss']
})
export class PartyManagementComponent {
  followedParties = PartyV2Factory.shared.createMockArray(0);
  upcomingParties = PartyV2Factory.shared.createMockArray(0);
  pastParties = PartyV2Factory.shared.createMockArray(0);
  myParties = PartyV2Factory.shared.createMockArray(0);
  showingPartyDetails: boolean = false;

  constructor(
    private router: Router,
    private partyService: PartyService,
  ) { }
  async ngOnInit() {
    this.followedParties = await this.partyService.getMyFollowedParties(1);
    this.upcomingParties = await this.partyService.getMyUpcomingParties(1);
    this.pastParties = await this.partyService.getMyPastParties(1);
    this.myParties = await this.partyService.getMyParties(1);
  }

  goToPartyCreation() {
    this.router.navigate(['/party/party-creation']);
  }

  closePartyDetails() {
    this.showingPartyDetails = false;
  }

  viewAllFollowedParties() {
    this.router.navigate(['/party/followed-parties']);
  }

  viewAllUpcomingParties() {
    this.router.navigate(['/party/upcoming-parties']);
  }
  
  viewAllRecentParties() {
    this.router.navigate(['/party/recent-parties']);
  }
  
  viewAllMyParties() {
    this.router.navigate(['/party/my-parties']);
  }
}