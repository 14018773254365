import { Component } from '@angular/core';

@Component({
  selector: 'hype-see-all-my-parties',
  templateUrl: './see-all-my-parties.component.html',
  styleUrls: ['./see-all-my-parties.component.scss']
})
export class SeeAllMyPartiesComponent {

}
