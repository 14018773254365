import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SignupService } from 'src/app/Services/Signup/signup.service';

@Component({
  selector: 'hype-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit{
  recaptchaResponse: string = '';
  recapchaSiteKey: string = '6LdgOsopAAAAADg0J9Nu_cAqvaGZngtYFJ6vBU9B';

  username: string = '';
  password: string = '';
  email: string = '';
  confirmPassword: string = '';
  firstName: string = '';
  lastName: string = '';
  userhandle: string = '';
  signUpError: string = '';
  // loginError: string = '';
  hasSignupError: boolean = false;
  loginForm: FormGroup = this.fb.group({
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    firstName: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
    userhandle: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private signupService: SignupService,
  ) { }

  ngOnInit(): void {
    this.loginForm.valueChanges.subscribe((value) => {
      this.username = value.username;
      this.password = value.password;
      this.email = value.email;
      this.confirmPassword = value.confirmPassword;
      this.firstName = value.firstName;
      this.lastName = value.lastName;
      this.userhandle = value.userhandle;
      if (value.password !== value.confirmPassword) {
        this.loginForm.get('confirmPassword')?.setErrors({ 'passwordMismatch': true });
      } else {
        this.loginForm.get('confirmPassword')?.setErrors(null);
      }
      if (value.username === value.password) {
        this.loginForm.get('password')?.setErrors({ 'usernamePasswordMatch': true });
      } else {
        this.loginForm.get('password')?.setErrors(null);
      }
      if (value.email === value.password) {
        this.loginForm.get('password')?.setErrors({ 'emailPasswordMatch': true });
      } else {
        this.loginForm.get('password')?.setErrors(null);
      }
      if (this.loginForm.valid) {
        this.hasSignupError = false;
      }
    });
  }

  resolved(captchaResponse: any) {
    this.recaptchaResponse = captchaResponse;
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  submitSignUp(): void {
    this.signupService.submitSignup(this.username, this.password, this.email, this.confirmPassword, this.firstName, this.lastName, this.userhandle, this.recaptchaResponse).then((response) => {
      if (response.success) {
        this.router.navigate(['/login']);
      } else {
        this.signUpError = response.message;
        console.log(response);
        this.hasSignupError = true;
      }
    });
    console.log('Sign up submitted');
  }
}
