import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Conversation } from 'src/app/Models/Conversation.model';
import { Member } from 'src/app/Models/Members.model';
import { User, UserFactory } from 'src/app/Models/User.model';
import { ConversationsService } from 'src/app/Services/Conversations/conversations.service';
import { FollowingsService } from 'src/app/Services/Followings/followings.service';
import { UserManagementService } from 'src/app/Services/user-management.service';

@Component({
  selector: 'hype-create-conversation-menu',
  templateUrl: './create-conversation-menu.component.html',
  styleUrl: './create-conversation-menu.component.scss'
})
export class CreateConversationMenuComponent implements OnInit{
  friends: Member[] = [];
  user: User = UserFactory.createMockUser();
  friendsAdded: any = [];
  message =  new FormControl('');
  messageImage = new FormControl();

  @Input() display: boolean = false;
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() conversationCreated = new EventEmitter<Conversation>();

  constructor(
    private userService: UserManagementService,
    private followingsService: FollowingsService,
    private conversationsService: ConversationsService,
  ) {}

  async ngOnInit() {
    this.friends = await this.followingsService.getMutuals(1);
    this.user = await this.userService.getMyUser();
  }

  addFriend(friend: Member) {
    this.friendsAdded.push(friend)
  }

  removeFriend(friend: Member) {
    this.friendsAdded = this.friendsAdded.filter((currentFriend: Member) => {
      currentFriend.userId != friend.userId
    })
  }

  checkIfFriendAdded(friend: Member) {
    return this.friendsAdded.includes(friend);
  }

  async startConversation() {
    let addedFriendIds = this.friendsAdded.map((friend: Member) => {
      return friend.userId;
    })
    let newConversation: Conversation = await this.conversationsService.createConversation(String(this.message.value), addedFriendIds);
    console.log(newConversation)
    this.message.setValue('');
    this.friendsAdded = [];
    this.display = false;
    this.displayChange.emit(this.display);
    this.conversationCreated.emit(newConversation); 
  }

  closeCreateConversationMenu() {
    this.display = false;
    this.displayChange.emit(this.display);
  }
}
