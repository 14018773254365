import { Component, Input, OnInit } from '@angular/core';
import { CommentV2 } from 'src/app/Models/Comment.model';
import { PostV2, PostV2Factory } from 'src/app/Models/Post.model';
import { PostsService } from 'src/app/Services/Posts/posts.service';

@Component({
  selector: 'hype-livefeed-post-footer',
  templateUrl: './livefeed-post-footer.component.html',
  styleUrls: ['./livefeed-post-footer.component.scss']
})
export class LivefeedPostFooterComponent implements OnInit {
  @Input() post: PostV2 = PostV2Factory.shared.createMock();
  postId: string = '';
  isMockPost: boolean = false;
  showingAddMenu: boolean = false;
  comments: CommentV2[] = [];
  iconSize: string = "25px";
  constructor(
    private postsService: PostsService,
  ) { }
  ngOnInit(): void {
    this.postId = this.post.postId;
    this.isMockPost = this.post.userId === '';
  }
  showComments() {
    this.showingAddMenu = !this.showingAddMenu;
  }
  likePostButtonClicked(post: PostV2){
    console.log('Like button clicked:', post);
    if (this.isMockPost) return;
    if (post.isLiked){
      this.postsService.unlikePost(post.postId).then((response) => {
        console.log('Unlike response:', response);
        post.isLiked = false;
        post.hypescore -= 1;
      }).catch((error) => {
        console.error('Unlike error:', error);
      });
    } else {
      this.postsService.likePost(post.postId).then((response) => {
        console.log('Like response:', response);
        post.isLiked = true;
        post.hypescore += 1;
      }).catch((error) => {
        console.error('Like error:', error);
      });
    }
  }
}
