import { Component, Input } from '@angular/core';
import { CommonUrls } from 'src/app/MockData/CommonMockData';

@Component({
  selector: 'hype-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent {
  @Input() url?: string = CommonUrls.randomPhoto;
}
