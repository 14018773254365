import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@Component({
  standalone: true,
  selector: 'hype-left-navbar',
  templateUrl: './left-navbar.component.html',
  styleUrls: ['./left-navbar.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LeftNavbarComponent {
  username: string = "username";
  constructor(private router: Router) {}
  navigateToProfile() {
    // this.router.navigate([`/profile/&${this.username}`]);
    this.router.navigate([`/profile`]);
  }
  navigateToHome() {
    this.router.navigate(['/home']);
  }
  navigateToLogin() {
    this.router.navigate(['/login']);
  }
  navigateToSignUp() {
    this.router.navigate(['/signup']);
  }
  navigateToSearch() {
    this.router.navigate(['/search']);
  }
  navigateToMessages() {
    this.router.navigate(['/messages']);
  }
  navigateToParty() {
    this.router.navigate(['/party']);
  }
}