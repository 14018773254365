import { Component } from '@angular/core';

@Component({
  selector: 'hype-profile-content-media',
  templateUrl: './profile-content-media.component.html',
  styleUrls: ['./profile-content-media.component.scss']
})
export class ProfileContentMediaComponent {

}
