<div class="settings-wrapper">
    <div class="themes-container">
        <p>Select theme</p>
        <div class="theme-option-container">
            <label class="light-label" for="light">Light
                <input id="light" type="radio" name="theme" (click)="toggleLightMode()" [checked]="selectedTheme === 'default'">
            </label>
            <label class="gray-label" for="gray">Gray
                <input id="gray" type="radio" name="theme" (click)="toggleGrayMode()" [checked]="selectedTheme === 'gray'">
            </label>
            <label class="dim-label" for="dim">Dim
                <input id="dim" type="radio" name="theme" (click)="toggleDimMode()" [checked]="selectedTheme === 'dim'">
            </label>
            <label class="dark-label" for="dark">Dark
                <input id="dark" type="radio" name="theme" (click)="toggleDarkMode()" [checked]="selectedTheme === 'dark'">
            </label>
        </div>
    </div>
    <div class="save-button-container">
        <button class="save-theme-button" (click)="saveTheme()">Save</button>
    </div>
</div>
