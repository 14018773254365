import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hype-my-profile-content-parties',
  templateUrl: './my-profile-content-parties.component.html',
  styleUrls: ['./my-profile-content-parties.component.scss']
})
export class MyProfileContentPartiesComponent implements OnInit {
  ngOnInit() {
    document.querySelector('.list-type')?.classList.add('hide');
  }
}
