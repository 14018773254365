import { Component, Input } from '@angular/core';
import { PostV2, PostV2Factory } from 'src/app/Models/Post.model';

@Component({
  selector: 'hype-livefeed-post-content',
  templateUrl: './livefeed-post-content.component.html',
  styleUrls: ['./livefeed-post-content.component.scss']
})
export class LivefeedPostContentComponent {
  @Input() post: PostV2 = PostV2Factory.shared.createMock();
  constructor() { }
}
