
<div class="create-conversation-menu-wrapper">
    <div class="create-conversation-menu-container">
        <div class="menu-header">
            <h2>New Conversation</h2>
            <button class="close-button" (click)="closeCreateConversationMenu()">
                <hype-ion-icon-wrapper [iconSize]="'17px'" [iconName]="'close'"></hype-ion-icon-wrapper>
            </button>
        </div>
        <div class="search-bar">
            <input type="text" placeholder="Search...">
        </div>
        <div class="selected-friends">
            <div class="selected-friend" *ngFor="let friend of friendsAdded">
                <div class="userhandle">{{friend.userhandle}}</div>
                <button class="remove-friend" (click)="removeFriend(friend)">
                    <hype-ion-icon-wrapper [iconSize]="'16px'" [iconName]="'close'"></hype-ion-icon-wrapper>
                </button>
            </div>
        </div>
        <div class="friend-list">
            <div class="friend" *ngFor="let friend of friends">
                <hype-s-profile-picture-custom [size]="'45px'" [borderSize]="'1px'" [url]="friend.profilePicture"></hype-s-profile-picture-custom>
                <span class="friend-name">{{friend.username}}</span>
                <button *ngIf="!checkIfFriendAdded(friend)" (click)="addFriend(friend)" class="add-friend">Add</button>
                <button *ngIf="checkIfFriendAdded(friend)" class="add-friend checked"><hype-ion-icon-wrapper [iconName]="'checkmark'"></hype-ion-icon-wrapper></button>

            </div>
        </div>
        <div class="message-bar">
            <hype-s-profile-picture-custom [size]="'40px'" [borderSize]="'1px'" [url]="user.profilePicture"></hype-s-profile-picture-custom>
            <input type="text" placeholder="Write a message..." [value]="message" [formControl]="message">
            <button class="send-message-button" (click)="startConversation()" [disabled]="!message.value">
                <hype-ion-icon-wrapper [iconName]="'send'"></hype-ion-icon-wrapper>
            </button>
            <label for="photo-input" class="image-button">
                <hype-ion-icon-wrapper [iconSize]="'30px'" [iconName]="'image'"></hype-ion-icon-wrapper>
            </label>
            <input 
                type="file"
                id="photo-input" 
                accept="image/png, image/jpeg, image/jpg"
                disabled 
            >
        </div>
    </div>
</div>