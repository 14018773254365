import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private readonly webSocketAuthUrl = `${environment.apiBaseUrl}/socket-auth-api/sessions`;

  constructor(private http: HttpClient) { }

  async getWebSocketToken(): Promise<string> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = {
      'authorization': sessionToken,
      'X-UserId': myUserId,
    };

    let response: any = await this.http.put(this.webSocketAuthUrl, {}, { headers: headers }).toPromise();
    let token = '';
    console.log('WebSocket response:', response);
    if (response?.status !== 200) {
      token = '';
    } else {
      token = response.data.socketToken;
      console.log('WebSocket token:', token);
    }

    return token;
  }
}
