import { Component, Input } from '@angular/core';
import { PartyV2, PartyV2Factory } from 'src/app/Models/Party.model';

@Component({
  selector: 'hype-party-details',
  templateUrl: './party-details.component.html',
  styleUrls: ['./party-details.component.scss']
})
export class PartyDetailsComponent {
  @Input() party: PartyV2 = PartyV2Factory.shared.createMock();
  constructor() { }
}
