import { Component, Input, OnInit } from '@angular/core';
import { PostV2, PostV2Factory } from 'src/app/Models/Post.model';
import { User, UserFactory } from 'src/app/Models/User.model';
import { UserManagementService } from 'src/app/Services/user-management.service';

@Component({
  selector: 'hype-livefeed-post',
  templateUrl: './livefeed-post.component.html',
  styleUrls: ['./livefeed-post.component.scss']
})
export class LivefeedPostComponent implements OnInit {
  @Input() feedPost: PostV2 = PostV2Factory.shared.createMock();
  @Input() user: User = UserFactory.shared.createMock();

  constructor(
    private userManagementService: UserManagementService,
  ) { }

  async ngOnInit() {
    let userArray = (this.feedPost.userId === "") ? [UserFactory.shared.createMock()] : await this.userManagementService.getUserByUserId(this.feedPost.userId);
    this.user = userArray[0];
  }
}
