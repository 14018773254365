// import { Component } from '@angular/core';

// @Component({
//   selector: 'hype-overlay',
//   templateUrl: './overlay.component.html',
//   styleUrls: ['./overlay.component.scss']
// })
// export class OverlayComponent {

// }


import { Component, Input, Output, EventEmitter, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { trigger, state, style, animate, transition, animateChild, sequence, query } from '@angular/animations';
import { CommonModule } from '@angular/common';
@Component({
  standalone: true,
  selector: 'hype-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  imports: [CommonModule],
  animations: [
    trigger('toggleBlur', [
      state('blurred', style({
        'backdrop-filter': 'blur(20px)'
      })),
      transition('void => *', [
        style({
          'backdrop-filter': 'blur(0px)',
        }),
        animate('0.2s')
      ]),
      transition('* => void', [
        animate('0.2s', style({}))
      ]),
    ]),
    ///  Version 2 of tog with sequence
    trigger('toggleBlur2', [
      state('blurred', style({
        'backdrop-filter': 'blur(20px)'
      })),
      transition('void => blurred', [
        style({
          'backdrop-filter': 'blur(0px)',
        }),
        animate('0.1s'),
        query('.hello', animateChild())
      ]),
      transition('* => void', [
        sequence([
          query('.hello', animateChild()),
          query(':self', animate('0.1s', style({
            'backdrop-filter': 'blur(0px)',
          })))
        ])
        
      ])
    ]),
    trigger('flyInOut', [
      state('in', style({
        'transform': 'translate(0px, 0px)',
        opacity: '1',
        'filter': 'blur(0px)'
      })),
      transition('void => *', [
        style({
          'transform': 'translate(0px, 200px)',
          opacity: '0',
          'filter': 'blur(5px)'
        }),
        animate('0.25s')
      ]),
      transition('* => void', [
        animate('0.25s', style({
          'transform': 'translate(0px, 200px)',
          opacity: '0',
          'filter': 'blur(5px)'
        }))
      ])
    ])
  ]
})
export class OverlayComponent implements OnInit, AfterViewInit {
  @Input() display: boolean = false;
  @Output() displayChange = new EventEmitter<boolean>();
  @Input() disableCloseOnClick: boolean = false;

  @ViewChild('childContainer') childContainer: any;
  displayingContent: boolean = true;

  ngAfterViewInit(): void {
    // this.displayingContent = true;
    // this.display = true;
    // this.displayingContent = false;
    // this.display = false;
  }

  ngOnInit(): void {
    // this.displayingContent = true;
    // this.display = true;
  }

  stopDisplaying() {
    if (this.disableCloseOnClick) return;
    this.display = false;
    this.displayChange.emit(this.display);
  }
}

