import { Component, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Post, PostFactory } from 'src/app/Models/Post.model';
import { User, UserFactory } from 'src/app/Models/User.model';
import { UserManagementService } from 'src/app/Services/user-management.service';

@Component({
  selector: 'hype-feed-post',
  templateUrl: './feed-post.component.html',
  styleUrls: ['./feed-post.component.scss']
})
export class FeedPostComponent {
  @Input() post: Post = PostFactory.shared.createMock();
  @Input() user: User = UserFactory.shared.createMock();

  constructor(
    private userManagementService: UserManagementService,
    private router: Router,
  ) { }

  async ngOnInit() {
    // this.post = await PostFactory.shared.createMock();
    // Get the user from the post
    let userArray = await this.userManagementService.getUserByUserId(this.post.authorId);
    this.user = userArray[0];
    console.log('The User:', this.user);
  }
}
