<div class="party-details-container">
    <div class="flyer-container">
        <img src="{{party.partyFlyer}}" alt="party-flyer">
        <div class="overlay"></div>
    </div>
    <div class="description-wrapper">
        <div class="header-container">
            <!-- <h1>{{party.name}}</h1> -->
            <!-- <h2>{{party.timestamp}}</h2> -->
        </div>
        <div class="description-container">
            <h2>Description</h2>
            <p>{{party.description}}</p>
        </div>
        <!-- <h1 style="" *ngFor="let item of [1,1,1,1,1,1,1,1,1]">HELLO WORLD</h1> -->
    </div>
    <div class="footer-container">
        <button>Buy Tickets</button>
        <button>View Location</button>
        <button>Follow</button>
    </div>
    
</div>