import { Component, Input } from '@angular/core';
import { UserFactory } from 'src/app/Models/User.model';

@Component({
  selector: 'hype-profile-content-parties',
  templateUrl: './profile-content-parties.component.html',
  styleUrls: ['./profile-content-parties.component.scss']
})
export class ProfileContentPartiesComponent {
  @Input() user = UserFactory.shared.createMock();

  ngOnInit() {
    document.querySelector('.list-type')?.classList.add('hide');
  }
}
