import { Component } from '@angular/core';

@Component({
  selector: 'hype-party-not-found',
  templateUrl: './party-not-found.component.html',
  styleUrl: './party-not-found.component.scss'
})
export class PartyNotFoundComponent {

}
