<div class="header-wrapper">
    <div class="header-container">
        <div class="header-left" (click)="goToProfile()">
            <hype-profile-picture [url]="user.profilePicture"></hype-profile-picture>
            <div class="name-title-container">
                <h4 class="name" (click)="goToProfile()">
                    {{user.username}}
                </h4>
                <p class="title" *ngIf="post.title">
                    {{post.title}}
                </p>
            </div>
        </div>
        <!-- Follow Button -->
        <div class="header-right">
            <div class="button-container">
                <button class="follow-button" *ngIf="!user.isFollowing" (click)="followButtonClicked()">Follow</button>
            </div>
            <!-- <div class="button-container">
                <button class="unfollow-button" *ngIf="user.isFollowing">Unfollow</button>
            </div> -->
            <!-- <hype-feed-post-follow-button *ngIf="!user.isFollowing"></hype-feed-post-follow-button> -->
        </div>
    </div>
</div>

