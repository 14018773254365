import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PostsService } from 'src/app/Services/Posts/posts.service';

@Component({
  selector: 'hype-post-creation',
  templateUrl: './post-creation.component.html',
  styleUrls: ['./post-creation.component.scss']
})
export class PostCreationComponent {

  postForm: FormGroup;
  photos: string[] = [];
  files: any[] = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private postService: PostsService,
  ) {
    // Initialize form
    this.postForm = this.formBuilder.group({
      postText: '',
      postImages: [null],
    });
   }

  submitPost(){
    const postText = this.postForm.get('postText')?.value;
    const postImages = this.postForm.get('postImages')?.value;

    const formData = new FormData();
    for (const file of this.files) {
      formData.append('images', file, file.name);
    }
    formData.append('data', JSON.stringify({
      newPost: {
        textContent: postText,
        isPrivate: false,
      }
    }));

    // console.log('Form Data:', formData.get('text'));
    // console.log('Form Data:', formData.get('images')?.toString());
    // console.log('Form Data:', formData.get('data'));

    // Call post creation endpoint
    this.postService.createPost(formData).then(response => {
      console.log('Post created:', response);
      this.router.navigate(['/home']);
    }).catch(error => {
      console.error('Error creating post:', error);
      // Handle error
    });
  }

  removePhoto(index: number){
    this.photos.splice(index, 1);
    this.files.splice(index, 1);
  }

  attatchPhoto(event: any){
    // Logic to attatch photo
    const element = event.target as HTMLInputElement;
    if (element.files && element.files[0]){
      const files = element.files;
      this.files.push(files[0])
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.photos.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    }
  }

}
