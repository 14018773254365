import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Components/home/home.component';
import { ProfileComponent } from './Components/profile/profile.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { HeaderComponent } from './Components/Common/header/header.component';
import { FeedPostComponent } from './Components/home/ChildComponents/feed-post/feed-post.component';
import { LoginComponent } from './Components/login/login.component';
import { SignUpComponent } from './Components/sign-up/sign-up.component';
import { FeedPostHeaderComponent } from './Components/home/ChildComponents/feed-post-header/feed-post-header.component';
import { FeedPostFooterComponent } from './Components/home/ChildComponents/feed-post-footer/feed-post-footer.component';
import { ProfilePictureComponent } from './Components/Common/profile-picture/profile-picture.component';
import { FeedPostContentComponent } from './Components/home/ChildComponents/feed-post-content/feed-post-content.component';
import { FeedPostFollowButtonComponent } from './Components/home/ChildComponents/feed-post-follow-button/feed-post-follow-button.component';
import { OverlayComponent } from './Components/Common/overlay/overlay.component';
import { FeedPostCommentsButtonComponent } from './Components/home/ChildComponents/feed-post-comments-button/feed-post-comments-button.component';
import { LeftNavbarComponent } from './Components/Common/left-navbar/left-navbar.component';
import { MyProfileComponent } from './Components/my-profile/my-profile.component';
import { ProfilePictureCustomComponent } from './Components/Common/profile-picture-custom/profile-picture-custom.component';
import { MyProfileContentMediaComponent } from './Components/my-profile/ChildComponents/my-profile-content-media/my-profile-content-media.component';
import { MyProfileContentPostsComponent } from './Components/my-profile/ChildComponents/my-profile-content-posts/my-profile-content-posts.component';
import { PartyManagementComponent } from './Components/party-management/party-management.component';
import { SearchComponent } from './Components/search/search.component';
import { MessagesComponent } from './Components/messages/messages.component';
import { PartyListRowComponent } from './Components/party-management/ChildComponents/party-list-row/party-list-row.component';
import { MapComponent } from './Components/search/ChildComponents/map/map.component';
import { IonIconWrapperComponent } from './Components/Common/ion-icon-wrapper/ion-icon-wrapper.component';
import { HttpClientModule } from '@angular/common/http';
import { userResolverFactory } from './Resolvers/user.resolver';
import { UserManagementService } from './Services/user-management.service';
import { ProfileContentPartiesComponent } from './Components/profile/ChildComponents/profile-content-parties/profile-content-parties.component';
import { ProfileContentPostsComponent } from './Components/profile/ChildComponents/profile-content-posts/profile-content-posts.component';
import { ProfileContentMediaComponent } from './Components/profile/ChildComponents/profile-content-media/profile-content-media.component';
import { MyProfileContentPartiesComponent } from './Components/my-profile/ChildComponents/my-profile-content-parties/my-profile-content-parties.component';
import { PostCreationComponent } from './Components/post-creation/post-creation.component';
import { PartyCreationComponent } from './Components/party-creation/party-creation.component';
import { ProfileSkeletonComponent } from './Components/Skeletons/profile-skeleton/profile-skeleton.component';
import { FeedPostCommentsComponent } from './Components/home/ChildComponents/feed-post-comments/feed-post-comments.component';
import { LivefeedPostComponent } from './Components/home/ChildComponents/livefeed-post/livefeed-post.component';
import { LivefeedPostHeaderComponent } from './Components/home/ChildComponents/livefeed-post-header/livefeed-post-header.component';
import { LivefeedPostFooterComponent } from './Components/home/ChildComponents/livefeed-post-footer/livefeed-post-footer.component';
import { LivefeedPostContentComponent } from './Components/home/ChildComponents/livefeed-post-content/livefeed-post-content.component';
import { PartyDetailsComponent } from './Components/party-management/ChildComponents/party-details/party-details.component';
import { SettingsComponent } from './Components/settings/settings.component';
import { PartyGridComponent } from './Components/party-management/ChildComponents/party-grid/party-grid.component';
import { SeeAllFollowedPartiesComponent } from './Components/party-management/ChildComponents/see-all-followed-parties/see-all-followed-parties.component';
import { SeeAllUpcomingPartiesComponent } from './Components/party-management/ChildComponents/see-all-upcoming-parties/see-all-upcoming-parties.component';
import { SeeAllRecentPartiesComponent } from './Components/party-management/ChildComponents/see-all-recent-parties/see-all-recent-parties.component';
import { SeeAllMyPartiesComponent } from './Components/party-management/ChildComponents/see-all-my-parties/see-all-my-parties.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { RecaptchaModule } from 'ng-recaptcha';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConversationListComponent } from './Components/messages/conversation-list/conversation-list.component';
import { SProfilePictureCustomComponent } from './Components/Common/s-profile-picture-custom/s-profile-picture-custom.component';
import { CreateConversationMenuComponent } from './Components/messages/conversation-list/create-conversation-menu/create-conversation-menu.component';
import { ConversationMessagesComponent, ReversePipe } from './Components/messages/conversation-messages/conversation-messages.component';
import { UserNotFoundComponent } from './Components/not-found/user-not-found/user-not-found.component';
import { PartyNotFoundComponent } from './Components/not-found/party-not-found/party-not-found.component';
import { PostNotFoundComponent } from './Components/not-found/post-not-found/post-not-found.component';
import { ConversationMembersComponent } from './Components/messages/conversation-members/conversation-members.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ProfileComponent,
        NotFoundComponent,
        HeaderComponent,
        FeedPostComponent,
        LoginComponent,
        SignUpComponent,
        FeedPostHeaderComponent,
        ProfilePictureComponent,
        FeedPostContentComponent,
        FeedPostFollowButtonComponent,
        FeedPostCommentsButtonComponent,
        MyProfileComponent,
        ProfilePictureCustomComponent,
        MyProfileContentMediaComponent,
        MyProfileContentPostsComponent,
        PartyManagementComponent,
        SearchComponent,
        MessagesComponent,
        PartyListRowComponent,
        ProfileContentPartiesComponent,
        ProfileContentPostsComponent,
        ProfileContentMediaComponent,
        MyProfileContentPartiesComponent,
        PostCreationComponent,
        PartyCreationComponent,
        ProfileSkeletonComponent,
        LivefeedPostComponent,
        LivefeedPostHeaderComponent,
        LivefeedPostFooterComponent,
        LivefeedPostContentComponent,
        PartyDetailsComponent,
        SettingsComponent,
        PartyGridComponent,
        SeeAllFollowedPartiesComponent,
        SeeAllUpcomingPartiesComponent,
        SeeAllRecentPartiesComponent,
        SeeAllMyPartiesComponent,
        MapComponent,
        ConversationListComponent,
        CreateConversationMenuComponent,
        UserNotFoundComponent,
        PartyNotFoundComponent,
        PostNotFoundComponent,
        ConversationMessagesComponent,
        ConversationMembersComponent,
        ReversePipe,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        BrowserAnimationsModule,
        FeedPostFooterComponent,
        OverlayComponent,
        LeftNavbarComponent,
        IonIconWrapperComponent,
        ReactiveFormsModule,
        FeedPostCommentsComponent,
        GoogleMapsModule,
        RecaptchaModule,
        InfiniteScrollModule,
        SProfilePictureCustomComponent
    ],   
    providers: [
      importProvidersFrom(HttpClientModule),
      {
          provide: 'userResolver',
          useFactory: userResolverFactory,
          deps: [UserManagementService],
      },
      {
          provide: LocationStrategy,
          useClass: PathLocationStrategy,
      },
      ReversePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
