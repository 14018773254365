import { AfterViewInit, Component, ViewChild } from '@angular/core';
// import { MapComponent } from './ChildComponents/map/map.component';

@Component({
  selector: 'hype-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements AfterViewInit {
  // Starts with search expanded
  searchIsExpanded: boolean = true;
  @ViewChild('sideOverlay') sideOverlay!: any;

  constructor() {}
  ngAfterViewInit(): void {
    // Start with the search collapsed after initialization of the view
    this.toggleSearch();
  }
  toggleSearch() {
    this.searchIsExpanded = !this.searchIsExpanded;
    this.sideOverlay.nativeElement.classList.toggle('collapsed');
  }
}
