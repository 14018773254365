import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './Components/home/home.component';
import { ProfileComponent } from './Components/profile/profile.component';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { LoginComponent } from './Components/login/login.component';
import { SignUpComponent } from './Components/sign-up/sign-up.component';
import { MyProfileComponent } from './Components/my-profile/my-profile.component';
import { PartyManagementComponent } from './Components/party-management/party-management.component';
import { SearchComponent } from './Components/search/search.component';
import { MessagesComponent } from './Components/messages/messages.component';
import { loginGuard } from './Security/login.guard';
import { userResolverFactory } from './Resolvers/user.resolver';
import { PostCreationComponent } from './Components/post-creation/post-creation.component';
import { PartyCreationComponent } from './Components/party-creation/party-creation.component';
import { SettingsComponent } from './Components/settings/settings.component';
import { SeeAllFollowedPartiesComponent } from './Components/party-management/ChildComponents/see-all-followed-parties/see-all-followed-parties.component';
import { SeeAllUpcomingPartiesComponent } from './Components/party-management/ChildComponents/see-all-upcoming-parties/see-all-upcoming-parties.component';
import { SeeAllRecentPartiesComponent } from './Components/party-management/ChildComponents/see-all-recent-parties/see-all-recent-parties.component';
import { SeeAllMyPartiesComponent } from './Components/party-management/ChildComponents/see-all-my-parties/see-all-my-parties.component';
import { PartyNotFoundComponent } from './Components/not-found/party-not-found/party-not-found.component';
import { UserNotFoundComponent } from './Components/not-found/user-not-found/user-not-found.component';
import { PostNotFoundComponent } from './Components/not-found/post-not-found/post-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'}, // Add this line to set the default route
  { path: 'home', component: HomeComponent },
  { 
    path: 'home/create-post', 
    component: PostCreationComponent, 
    canActivate: [loginGuard],
  },
  { 
    path: 'profile', 
    component: MyProfileComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'party/party-creation',
    component: PartyCreationComponent,
    canActivate: [loginGuard],
  },
  { 
    path: 'party', 
    component: PartyManagementComponent,
    canActivate: [loginGuard],
  },
  { 
    path: 'search', 
    component: SearchComponent,
    canActivate: [loginGuard],
  },
  { 
    path: 'messages', 
    component: MessagesComponent,
    canActivate: [loginGuard],
  },
  { 
    path: 'settings', 
    component: SettingsComponent,
    canActivate: [loginGuard],
  },
  { 
    path: 'party/followed-parties', 
    component: SeeAllFollowedPartiesComponent,
    canActivate: [loginGuard],
  },
  { 
    path: 'party/upcoming-parties', 
    component: SeeAllUpcomingPartiesComponent,
    canActivate: [loginGuard],
  },
  { 
    path: 'party/recent-parties', 
    component: SeeAllRecentPartiesComponent,
    canActivate: [loginGuard],
  },
  { 
    path: 'party/my-parties', 
    component: SeeAllMyPartiesComponent,
    canActivate: [loginGuard],
  },
  { 
    path: 'profile/:userhandle', 
    component: ProfileComponent,
    resolve: { 
      user: 'userResolver'
    },
  },
  { path: 'signin', component: LoginComponent },
  { path: 'login', redirectTo: 'signin' },
  { path: 'signup', component: SignUpComponent },
  { path: '404', component: NotFoundComponent }, // Add this line to handle the 404 error
  { path: '**', component: NotFoundComponent }, // Add this line to handle any other route
  { path: 'parties/404', component: PartyNotFoundComponent },
  { path: 'profiles/404', component: UserNotFoundComponent },
  { path: 'posts/404', component: PostNotFoundComponent },
  
  // { path: '**', redirectTo: '404' }, // Add this line to redirect all other routes to the home component
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
