import { Component, HostListener, Input } from '@angular/core';
import { CommentV2 } from 'src/app/Models/Comment.model';
import { MyPostV2, MyPostV2Factory } from 'src/app/Models/Post.model';
import { UserFactory } from 'src/app/Models/User.model';
import { PostsService } from 'src/app/Services/Posts/posts.service';

@Component({
  selector: 'hype-my-profile-content-posts',
  templateUrl: './my-profile-content-posts.component.html',
  styleUrls: ['./my-profile-content-posts.component.scss']
})
export class MyProfileContentPostsComponent {
  posts = MyPostV2Factory.shared.createMockArray(0);
  @Input() user = UserFactory.shared.createMock();
  parentScrollContainer: any;
  scrollTimeout: any;
  showingDeleteConfirmation: boolean = false;
  abortController = new AbortController();
  signal = this.abortController.signal;
  postToDelete: string | null = null;
  iconSize = '20px';
  showingComments: boolean = false;
  postId: string = '';
  pageNumber: number = 1;

  constructor(
    private postsService: PostsService,
  ) { }
  ngOnInit() {
    this.handleScroll();
  }
  ngAfterViewInit(): void {
    this.parentScrollContainer = document.querySelector('.profile-content-container');
    this.parentScrollContainer.addEventListener('scroll', this.throttle(this.closeDropdownMenuOnScroll, 500));
  }
  
  async handleScroll() {
    this.posts = this.posts.concat(await this.postsService.getMyPosts(this.pageNumber));
    this.pageNumber++;
  }

  async viewCommentsButtonClicked(post: MyPostV2){
    this.postId = post.postId;
    this.showingComments = !this.showingComments;
  }

  toggleDropdownMenu(event: any) {
    event.stopPropagation();

    // console.log('Toggle dropdown menu');
    let buttonClicked = event.target;
    // console.log('Button clicked:', buttonClicked);
    let dropdownMenu = buttonClicked.nextElementSibling;
    let dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
    if (dropdownMenu.classList.contains('show')) {
      dropdownMenu.classList.remove('show');
    } else {
      dropdownMenu.classList.add('show');
    }
    dropdownMenus.forEach((menu) => {
      if (menu !== dropdownMenu) {
        menu.classList.remove('show');
      }
    });
  }

  closeDropdownMenuOnScroll(event: any) {
    let dropdownMenu = document.querySelector('.dropdown-menu.show');
    if (dropdownMenu) dropdownMenu.classList.remove('show');
  }

  throttle = (callback: any, delay: number = 1000) => {
    let previousCall = new Date().getTime();
    return function() {
      const time = new Date().getTime();
      if ((time - previousCall) >= delay) {
        previousCall = time;
        callback.apply(null, arguments);
      }
    }
  }
  
  @HostListener('document:click', ['$event'])
  closeDropdownMenu(event: MouseEvent) {
    const dropdownMenu = document.querySelector('.dropdown-menu.show');
    if (dropdownMenu && !dropdownMenu.contains(event.target as Node)) {
      dropdownMenu.classList.remove('show');
    }
  }

  deleteConfirmed() {
    const postId = this.postToDelete;
    console.log('Delete confirmed');
    console.log('Post ID:', postId);
    this.showingDeleteConfirmation = false;
    if (!postId || postId.length <= 0) return;
    this.postsService.deletePost(postId).then(response => {
      this.posts = this.posts.filter((post) => post.postId !== postId);
    })
    .catch(error => {
      console.error('Error deleting post:', error);
      // Handle error
    });
    this.postToDelete = null;
  }

  deleteCancelled() {
    console.log('Delete cancelled');
    this.showingDeleteConfirmation = false;
    this.postToDelete = null;
  }

  // Menu actions
  editPost(post: any) {
    console.log('Edit post');
  }
  deletePost(post: any, event: MouseEvent) {
    // event.stopPropagation();
    // console.log('Delete post');
    this.abortController.abort();
    this.closeDropdownMenu(new MouseEvent('click'));
    this.showingDeleteConfirmation = true;
    this.postToDelete = post.postId;

    // wait just a but bit to make sure the menu is open
    // setTimeout(() => {
    //   return new Promise<void>((resolve, reject) => {
    //     const confirmDeleteButton = document.querySelector('.confirmDeleteButton');
    //     const cancelButton = document.querySelector('.cancelDeleteButton');
    //     confirmDeleteButton?.addEventListener('click', () => {
    //       this.deleteConfirmed(post.postId);
    //       // console.log('Delete confirmed and deleted');
    //       resolve();
    //     }, { signal: this.signal });
    //     cancelButton?.addEventListener('click', () => {
    //       this.showingDeleteConfirmation = false;
    //       resolve();
    //     }, { signal: this.signal });
    //     // close when clicking outside
    //     document.addEventListener('click', () => {
    //       console.log('Clicked outside');
    //       this.showingDeleteConfirmation = false;
    //       console.log(this.signal.aborted);
    //       resolve();
    //     }, { signal: this.signal });
    //   });
    // }, 10);
    
  }

  stoppingPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
