import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User, UserFactory } from 'src/app/Models/User.model';
import { FollowingsService } from 'src/app/Services/Followings/followings.service';
import { UserManagementService } from 'src/app/Services/user-management.service';

@Component({
  selector: 'hype-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit {
    selectedTab = 2;
    isLoading = true;
    user: User = UserFactory.shared.createMock();
    @ViewChild('mediaBtn') mediaBtn: any;
    @ViewChild('postsBtn') postsBtn: any;
    @ViewChild('partiesBtn') partiesBtn: any;
    constructor(
      private route: ActivatedRoute,
      private userManagementService: UserManagementService,
      private router: Router,
      private followingService: FollowingsService,
    ) { }
    ngAfterViewInit(): void {
      this.tabSelected(2);
    }
  
    ngOnInit(): void {
      // this.user = this.route.snapshot.data['user'];
      this.route.data.subscribe((data) => {
        this.user = data['user'];
        console.log('User:', this.user);
        if(this.user == undefined || this.user == null || this.user.userId == '' || this.user.userId == null){
          // console.error('User not found');
          this.router.navigate(['/profiles/404']);
        }
        if(this.user.userId === localStorage.getItem('userId')){
          this.router.navigate(['/profile']);
        }
        this.isLoading = false;
        
      });
      // this.tabSelected(2);
      // this.route.params.subscribe(params => {
      //   let userhandle = params['userhandle'];
      //   this.userService.getUserByUserhandle(userhandle).subscribe((user) => {
      //     console.log(user);
      //   });
      // });
    }

    async followButtonClicked(){
      console.log('Follow button clicked');
      const response = await this.followingService.followUser(this.user.userId);
      if(response.status === 200){
        console.log('Followed user');
        this.user.isFollowing = true;
        this.user.numFollowers += 1;
      }

    }
    async unfollowButtonClicked(){
      console.log('Unfollow button clicked');
      const response = await this.followingService.unfollowUser(this.user.userId);
      if(response.status === 200){
        console.log('Unfollowed user');
        this.user.isFollowing = false;
        this.user.numFollowers -= 1;
      }
    }
    tabSelected(tab: number){
      // console.log('Tab selected:', tab);
      this.selectedTab = tab;
      switch(tab){
        case 1:
          // Set tab 1 styling
          this.mediaBtn.nativeElement.classList.replace('button-inactive','button-active');
          this.postsBtn.nativeElement.classList.replace('button-active','button-inactive');
            this.partiesBtn.nativeElement.classList.replace('button-active','button-inactive');
          break;
        case 2:
          // Set tab 2 styling
          this.mediaBtn.nativeElement.classList.replace('button-active','button-inactive');
          this.postsBtn.nativeElement.classList.replace('button-inactive','button-active');
            this.partiesBtn.nativeElement.classList.replace('button-active','button-inactive');
          break;
        case 3:
            // Set tab 3 styling
            this.mediaBtn.nativeElement.classList.replace('button-active','button-inactive');
            this.postsBtn.nativeElement.classList.replace('button-active','button-inactive');
                this.partiesBtn.nativeElement.classList.replace('button-inactive','button-active');
            break;
        default:
          break;
      }
    }
    getMyUser(): Promise<User>{
      let userId = localStorage.getItem('userId') || '';
      let sessionId = localStorage.getItem('sessionId') || '';
      return this.userManagementService.getMyUser(userId, sessionId);
    }
}
