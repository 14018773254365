<!-- <p>home works!</p> -->
<div class="feed-wrapper" infinite-scroll (scrolled)="handleScroll()">
    <div class="feed-container">
        <div class="post-container" *ngFor="let post of feedPosts" >
            <!-- <hype-feed-post [post]="post"></hype-feed-post> -->
            <hype-livefeed-post [feedPost]="post"></hype-livefeed-post>
        </div>
    </div>
    <div class="sidebar-container">
        <button class="sidebar-button" (click)="writePostButtonClicked()">
            <hype-ion-icon-wrapper [iconName]="'create'"></hype-ion-icon-wrapper>
        </button>
    </div>
</div>