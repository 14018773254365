<div class="extrasize">
    <section class="login-wrapper">
        <div class="login-container">
            <div class="form-container">
                <h2>Welcome Back</h2>
                <form [formGroup]="loginForm" (submit)="submitLogin()">
                    <label for="username">Username</label>
                    <input type="text" id="username" formControlName="username">
                    <label for="password">Password</label>
                    <input type="password" id="password" formControlName="password">
                    <p *ngIf="hasLoginError" class="loginError">{{loginError}}</p>
                    <button type="submit" [disabled]="!loginForm.valid">Login</button>
                </form>
            </div>
        </div>
    </section>
</div>
