import { Inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { UserManagementService } from '../Services/user-management.service';
import { User, UserFactory } from '../Models/User.model';

export function userResolverFactory(userManagementService: UserManagementService): ResolveFn<User> {
  return async (route, state) => {
    var userhandle = route.paramMap.get('userhandle') || '';
    userhandle = userhandle.replace('@', '');

    // let result = await userManagementService.getUserByUserId(userhandle);
    let result = await userManagementService.getUserByUserhandle(userhandle);
    // console.log('Got User:', result[0]); // Adjusted for simplicity
    return result[0] as User;
  };
}

// export const userResolver: ResolveFn<boolean> = async (route, state) => {
//   let userManagementService = Inject(UserManagementService) as UserManagementService;
//   var userhandle = route.paramMap.get('userhandle') || '';
//   userhandle = userhandle.replace('@','');

//   // let result = await userManagementService.getUserByUserHandle(userhandle);
//   let result = await userManagementService.getUserByUserId(userhandle);
//   let userS = UserFactory.shared.createArrayFromHttp(result);
//   console.log('Got User:', userS[0]);
//   return true;
// };
