import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentV2, CommentV2Factory } from 'src/app/Models/Comment.model';
import { User, UserFactory } from 'src/app/Models/User.model';
import { UserManagementService } from 'src/app/Services/user-management.service';
import { SProfilePictureCustomComponent } from 'src/app/Components/Common/s-profile-picture-custom/s-profile-picture-custom.component';
import { IonIconWrapperComponent } from 'src/app/Components/Common/ion-icon-wrapper/ion-icon-wrapper.component';
import { PostsService } from 'src/app/Services/Posts/posts.service';
import { OverlayComponent } from 'src/app/Components/Common/overlay/overlay.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'hype-s-feed-post-comments',
  standalone: true,
  imports: [
    CommonModule, 
    SProfilePictureCustomComponent, 
    IonIconWrapperComponent,
    OverlayComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './s-feed-post-comments.component.html',
  styleUrls: ['./s-feed-post-comments.component.scss']
})
export class SFeedPostCommentsComponent {
  @Input() comment = CommentV2Factory.createMockComment();
  @Input() authorId = '';
  @Output() commentDeletedEvent = new EventEmitter<string>();
  commentAuthor: User[] = UserFactory.createMockUserArray(1);
  parentScrollContainer: any;
  parentCommentContainer: any;
  abortController = new AbortController();
  showingDeleteConfirmation: boolean = false;
  commentToDelete: string = '';
  postId: string = '';
  commentId: string = '';
  userId: string = '';
  showCreateReplyContainer: boolean = false;
  showCommentRepliesContainer: boolean = false;
  repliesText: string = 'View Replies';
  createReplyText: string = 'Reply';
  commentReplies: CommentV2[] = CommentV2Factory.createMockArray(3);

  commentReplyText = new FormControl('', Validators.required);



  constructor(
    private userManagementService: UserManagementService,
    private postsService: PostsService,
  ) {}

  async ngOnInit() {
    this.commentAuthor = await this.userManagementService.getUserByUserId(this.authorId);
    await this.userManagementService.getMyUser().then(async response => {
      this.userId = response.userId;
    });
  }

  ngAfterViewInit(): void {
    this.parentScrollContainer = document.querySelector('.comments-container');
    this.parentCommentContainer = document.querySelector('.comment-section-wrapper');
    this.parentScrollContainer.addEventListener('scroll', this.throttle(this.closeDropdownMenu, 500));
    this.parentCommentContainer.addEventListener('click', this.throttle(this.closeDropdownMenu, 500));
  }
  
  showReplies() {
    console.log('View replies clicked!');
    if(this.showCommentRepliesContainer) {
      this.showCommentRepliesContainer = false;
      this.repliesText = 'View Replies';
    } else {
      this.showCommentRepliesContainer = true;
      this.repliesText = 'Hide Replies';

    }
  }
  
  createReply() {
    console.log('Create reply clicked!');
    if(this.showCreateReplyContainer) {
      this.showCreateReplyContainer = false;
      this.createReplyText = 'Reply';
    } else {
      this.showCreateReplyContainer = true;
      this.createReplyText = 'Cancel Reply';
    }
  }

  sendCommentReply() {
    console.log('send comment reply clicked!')
  }

  reportComment(commentId: string) {
    console.log('Report comment clicked!');
    alert('No report feature yet :(');
  }
  
  likeComment(comment: CommentV2) {
    if (comment.isLiked){
      comment.isLiked = false;
      comment.numLikes -=1;
    } else {
      comment.isLiked = true;
      comment.numLikes +=1;
    }
  }
  
  deleteComment(postId: string, commentId: string, event: MouseEvent) {
    this.abortController.abort();
    this.closeDropdownMenu(new MouseEvent('click'));
    this.showingDeleteConfirmation = true;
    this.commentToDelete = commentId;
    this.postId = postId;
    this.commentId = commentId;
  }

  async deleteConfirmed() {
    const commentId = this.commentToDelete;
    this.showingDeleteConfirmation = false;
    if (!commentId || commentId.length <= 0) return;
    if (!this.postId || commentId.length <= 0) return;
    await this.postsService.deleteComment(this.postId, commentId).then(response => {
      this.commentDeletedEvent.emit(this.commentToDelete);
    })
    .catch(error => {
      console.error('Error deleting comment:', error);
    });
    this.commentToDelete = '';
  }

  deleteCancelled() {
    this.showingDeleteConfirmation = false;
    this.commentToDelete = '';
  }

  closeDropdownMenu(event: any) {
    let dropdownMenu = document.querySelector('.dropdown-menu.show');
    if (dropdownMenu) dropdownMenu.classList.remove('show');
  }

  throttle = (callback: any, delay: number = 1000) => {
    let previousCall = new Date().getTime();
    return function() {
      const time = new Date().getTime();
      if ((time - previousCall) >= delay) {
        previousCall = time;
        callback.apply(null, arguments);
      }
    }
  }
  
  toggleDropdownMenu(authorId: string, event: any) {
    if (authorId === this.userId) {
      event.stopPropagation();
      let buttonClicked = event.target;
      let dropdownMenu = buttonClicked.nextElementSibling;
      let dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
      if (dropdownMenu.classList.contains('show')) {
        dropdownMenu.classList.remove('show');
      } else {
        dropdownMenu.classList.add('show');
      }
      dropdownMenus.forEach((menu) => {
        if (menu !== dropdownMenu) {
          menu.classList.remove('show');
        }
      });
    } else {
      event.stopPropagation();
      let buttonClicked = event.target;
      let dropdownMenu = buttonClicked.nextElementSibling.nextElementSibling;
      let dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
      if (dropdownMenu.classList.contains('show')) {
        dropdownMenu.classList.remove('show');
      } else {
        dropdownMenu.classList.add('show');
      }
      dropdownMenus.forEach((menu) => {
        if (menu !== dropdownMenu) {
          menu.classList.remove('show');
        }
      });
    }
  }
}
