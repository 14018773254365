import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagementService } from './Services/user-management.service';
import { AuthenticationService } from './Services/Authentication/authentication.service';
import { PreferencesService } from './Services/Preferences/preferences.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'HypeTrain-Website';
  hideNav: boolean = false;

  constructor(
    private router: Router,
    private userManagementService: UserManagementService,
    private authService: AuthenticationService,
    private preferencesService: PreferencesService
    ){ }

  ngOnInit(){
    console.log('App component initialized');
    this.router.events.subscribe((val) => {
      if (this.router.url == '/signin' || this.router.url == '/signup'){
        this.hideNav = true;
      } else {
        this.hideNav = false;
      }
    });

    // Initialize preferences
    // this.initializePreferences();

    // Check if already signed in
    // If so, redirect to home
    let userId = localStorage.getItem('userId');
    let sessionId = localStorage.getItem('sessionId');
    if ((userId && sessionId) && (userId.length > 0 && sessionId.length > 0)){
      // this.userManagementService.getMyUser(userId, sessionId).then((response) => {
      //   if (response){
      //     console.log('User:', this.userManagementService.myUser);
      //     this.router.navigate(['/home']);
      //   }
      // });
      this.authService.isLoggedIn = true;
      // this.router.navigate(['/home']);

      // Check for the device id in local storage and set it
      let deviceId = localStorage.getItem('deviceId');
      if (deviceId && deviceId.length > 0){
        this.userManagementService.deviceId = deviceId;
      } else {
        // If the device id doesn't exist, create one
        let newDeviceId = this.userManagementService.generateDeviceId();
        localStorage.setItem('deviceId', newDeviceId);
        this.userManagementService.deviceId = newDeviceId;
      }
    }
  }

  ngAfterViewInit(){
    this.initializePreferences();
  }

  initializePreferences(){

    this.preferencesService.initializeTheme();

    // this.preferencesService.setTheme('default');

    // Check if preferences exist in local storage
    // Then get them preferences from the preferences service
    // If they don't exist, default to light theme
    // let preferences = localStorage.getItem('preferences');

    // get the body element
    // let body = document.querySelector('body');
    // let theme = 'dark';

    // if (preferences){
    //   console.log('Preferences:', preferences);
    //   let preferencesObj = JSON.parse(preferences);
    //   console.log('Preferences Obj:', preferencesObj);
    //   // Set the theme
    //   theme = preferencesObj.theme;
    //   console.log('Theme:', theme);
    // } else {
    //   console.log('No preferences found');
    //   // localStorage.setItem('preferences', JSON.stringify({
    //   //   theme: 'light',
    //   // }));
    // }

    // Set the theme
    // this.preferencesService.setTheme(theme);
    // if (!body) console.log('Body not found');
    // if (body) body.classList.add(`${theme}-theme`);
    
  }

}
