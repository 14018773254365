import { Factory, generateRandomFirstName, generateRandomLastName, getRandomSquareImage, generateRandomUsername, generateRandomMessage, generateRandomNumber } from "../MockData/CommonMockData";

export interface Message {
    messageId: string;
    timestamp: string;
    content: string;
    media: any[];
    author: {
        userId: string;
        username: string;
        userhandle: string;
        profileImage: {
            url: string;
        };
    };
}

export class Message implements Message {
    constructor(
        messageId?: string,
        timestamp?: string,
        content?: string,
        media?: any[],
        author?: { userId: string, username: string, userhandle: string, profileImage: { url: string } },
    ) 
    {
        this.messageId = messageId || '';
        this.timestamp = timestamp || '';
        this.content = content || '';
        this.media = media || [];
        this.author = author || { userId: '', username: '', userhandle: '', profileImage: { url: '' } };
    }
}

export class MessageFactory implements Factory {
    create: () => Message = MessageFactory.createNewMessage;
    createMock: () => Message = MessageFactory.createMockMessage;
    createMockArray: (n: number) => Message[] = MessageFactory.createMockMessageArray;
    createArrayFromHttp: (response: any) => Message[] = MessageFactory.createNewMessageArrayFromData;
    createFromSocketData: (data: any) => Message = MessageFactory.createNewMessageFromSocketData;
    static shared = new MessageFactory();
    static createNewMessage(): Message {
        return new Message();
    };
    static createMockMessage(): Message {
        return new Message(
            String(generateRandomNumber(1)),
            String(new Date()),
            generateRandomMessage(),
            [],
            {
                userId: String(generateRandomNumber(1)),
                username: generateRandomFirstName() + " " + generateRandomLastName(),
                userhandle: generateRandomUsername(),
                profileImage: { url: getRandomSquareImage() }
            }
        );
    };
    static createMockMessageArray(n: number): Message[] {
        let messages: Message[] = [];
        for (let i = 0; i < n; i++) {
            messages.push(MessageFactory.createMockMessage());
        };
        return messages;
    };
    static createNewMessageArrayFromData(response: any): Message[] {
        if(response.status !== 200) {
            console.log('Error: ', response?.statusMessage || "Unknown error");
            return [];
        };

        let data = response.data?.messages;
        let messages: Message[] = data.map((message: any) => {
            return new Message(
                message?.messageId || '',
                message?.timestamp || '',
                message?.content || '',
                message?.media || [],
                {
                    userId: message?.author?.userId || '',
                    username: message?.author?.username || '',
                    userhandle: message?.author?.userhandle || '',
                    profileImage: { url: message?.author?.profileImage?.url || '' }
                }
            );
        });
        return messages;
    };
    static createNewMessageFromSocketData(data: any): Message {
        // Serialize the data from the socket
        data = JSON.parse(data);
        return new Message(
            data?.messageId || '',
            data?.timestamp || '',
            data?.content || '',
            data?.media || [],
            {
                userId: data?.author?.userId || '',
                username: data?.author?.username || '',
                userhandle: data?.author?.userhandle || '',
                profileImage: { url: data?.author?.profileImage?.url || '' }
            }
        );
    };
}