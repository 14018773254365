import { CommonUrls, Factory, generateRandomFirstName, generateRandomLastName, generateRandomNumber, generateRandomUsername, getRandomSquareImage } from "../MockData/CommonMockData";

interface PrimitiveUser {
    userId: string;
    username: string;
    userhandle: string;
}

export interface User extends PrimitiveUser {
    bio: string;
    profilePicture: string;
    numFollowers: number;
    numFollowings: number;
    title?: string;
    hypescore?: number;
    isFollowing?: boolean | null;
    details: {
        firstName: string;
        lastName: string;
    }
}

export class User implements User {

    constructor(id?: string, username?: string, userhandle?: string, bio?: string, profilePicture?: string, numFollowers?: number, numFollowings?: number, hypescore?: number, isFollowing?: boolean, details?: { firstName: string, lastName: string; }) {
        this.userId = id || "";
        this.username = username || "";
        this.userhandle = userhandle || "";
        this.bio = bio || "";
        this.profilePicture = profilePicture || "";
        this.numFollowers = numFollowers || 0;
        this.numFollowings = numFollowings || 0;
        this.hypescore = hypescore || 0;
        this.isFollowing = isFollowing || null;
        this.details = details || { firstName: '', lastName: ''};
    }
}

export class UserFactory implements Factory {
    create: () => User = UserFactory.createNewUser;
    createMock: () => User = UserFactory.createMockUser;
    createMockArray: (n: number) => any[] = UserFactory.createMockUserArray;
    createFromHttp: (response: any) => User = UserFactory.createNewUserFromData;
    createArrayFromHttp: (response: any) => User[] = UserFactory.createNewUserArrayFromData;
    static shared = new UserFactory();
    static createNewUser(): User {
        return new User();
    }
    static createNewUserWithoutId(username: "bable"){
        return new User(undefined, username);
    }
    static createMockUser(): User {
        let fName = generateRandomFirstName();
        let lName = generateRandomLastName();
        return new User(
            undefined, 
            `${fName}`, 
            // `${fName[0]}${generateRandomNumber(4)}`,
            generateRandomUsername(fName, lName),
            "I am a mock user", 
            // CommonUrls.randomPhoto, 
            getRandomSquareImage(),
            generateRandomNumber(3), 
            generateRandomNumber(3),
        );
    }
    static createMockUserArray(n: number): User[] {
        let users: User[] = [];
        for (let i = 0; i < n; i++) {
            users.push(UserFactory.createMockUser());
        }
        return users;
    }
    static createNewUserFromData(response: any): User {
        if(response.status !== 200) {
            return new User();
        }
        let data = response.data.myUser;
        return new User(
            data?.userId || "",
            data.username,
            data?.userhandle || "",
            data?.biography || "",
            data.profileImage.url,
            data.numFollowers,
            data.numFollowings,
            data.hypescore || 0,
            data?.isFollowing,
            { firstName: data?.details.firstName || '', lastName: data?.details.lastName || '' },
        );
    }
    static createNewUserArrayFromData(response: any): User[] {
        if(response.status !== 200) {
            return [new User()];
        }
        let usersArray = response?.data?.users;
        if (!usersArray) return [new User()]; // return empty array if no users
        // let data = response.data.myUser;
        let users: User[] = [];
        users = usersArray.map((user: any) => {
            // console.log('User:', user);
            return new User(
                user?.userId || "",
                user.username,
                user?.userhandle || "",
                user?.biography || "",
                user?.profileImage?.url || "",
                user.numFollowers,
                user.numFollowings,
                user.hypescore,
                user?.isFollowing
            );
        });
        // for (let i = 0; i < data.length; i++) {
        //     users.push(UserFactory.createNewUserFromData(data[i]));
        // }
        return users;
    }

    static createMemberArrayFromData(response: any): User[] {
        if(response.status !== 200) {
            return [new User()];
        }
        let membersArray = response?.data?.members;
        if (!membersArray) return [new User()];
        let members: User[] = [];
        members = membersArray.map((member: any) => {
            return new User(
                member?.userId || "",
                member.username,
                member?.userhandle || "",
                member?.profileImage?.url || "",
                member.numFollowers,
                member.numFollowings,
                member.hypescore,
                member?.isFollowing
            );
        });
        return members;
    }
}