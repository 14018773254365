<div class="extrasize">
    <section class="login-wrapper">
        <div class="login-container">
            <div class="form-container">
                <h2>Welcome</h2>
                <form [formGroup]="loginForm" (submit)="submitSignUp()">
                    <label for="email">Email</label>
                    <input type="email" id="email" formControlName="email">
                    <label for="username">Username</label>
                    <input type="text" id="username" formControlName="username">
                    <label for="userhandle">Userhandle</label>
                    <input type="text" id="userhandle" formControlName="userhandle">
                    <label for="firstName">First Name</label>
                    <input type="text" id="firstName" formControlName="firstName">
                    <label for="lastName">Last Name</label>
                    <input type="text" id="lastName" formControlName="lastName">
                    <label for="password">Password</label>
                    <input type="password" id="password" formControlName="password">
                    <label for="confirmPassword">Confirm Password</label>
                    <input type="password" id="confirmPassword" formControlName="confirmPassword">
                    <re-captcha (resolved)="resolved($event)" siteKey="{{recapchaSiteKey}}"></re-captcha>
                    <p *ngIf="hasSignupError" class="loginError">{{signUpError}}</p>
                    <button type="submit" [disabled]="!loginForm.valid">Sign Up</button>
                </form>
            </div>
        </div>
    </section>
</div>
