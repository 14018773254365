import { Component } from '@angular/core';

@Component({
  selector: 'hype-see-all-recent-parties',
  templateUrl: './see-all-recent-parties.component.html',
  styleUrls: ['./see-all-recent-parties.component.scss']
})
export class SeeAllRecentPartiesComponent {

}
