import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Member } from 'src/app/Models/Members.model';
import { ConversationsService } from 'src/app/Services/Conversations/conversations.service';

@Component({
  selector: 'hype-conversation-members',
  templateUrl: './conversation-members.component.html',
  styleUrl: './conversation-members.component.scss'
})
export class ConversationMembersComponent {
  @Input() conversationId: string = "";
  @Input() display: boolean = false;
  @Output() displayChange = new EventEmitter<boolean>();
  members: Member[] = [];

  constructor(private conversationService: ConversationsService) {}

  async ngOnChanges(changes: SimpleChanges) {
    if(this.conversationId) {
      this.members = await this.conversationService.getConversationMembers(this.conversationId, 1);
    }
  }

  closeMembersMenu() {
    this.display = false;
    this.displayChange.emit(this.display);
  }
}
