/**
 * Represents a factory that can create objects.
 */
export interface Factory {
    create: Function;
    createMock: Function;
    createMockArray: (n: number) => any[];
    asyncCreateMock?: () => Promise<any>;
    asyncCreateMockArray?: (n: number) => Promise<any[]>;
}

/**
 * Generates a random first name from a predefined list.
 * @returns A random first name.
 */
export function generateRandomFirstName(): string {
    const firstNameList = firstNames;
    const randomIndex = Math.floor(Math.random() * firstNameList.length);
    return firstNameList[randomIndex];
}

/**
 * Generates a random last name from a predefined list.
 * @returns A random last name.
 */
export function generateRandomLastName(): string {
    const lastNameList = lastNames;
    const randomIndex = Math.floor(Math.random() * lastNameList.length);
    return lastNameList[randomIndex];
}

const firstNames = [
    "James", "Mary", "John", "Patricia", "Robert",
    "Jennifer", "Michael", "Linda", "William", "Elizabeth",
    "David", "Barbara", "Richard", "Susan", "Joseph",
    "Jessica", "Thomas", "Sarah", "Charles", "Karen",
    "Christopher", "Nancy", "Daniel", "Lisa", "Matthew",
    "Margaret", "Anthony", "Betty", "Mark", "Sandra",
    "Donald", "Ashley", "Steven", "Kimberly", "Paul",
    "Emily", "Andrew", "Donna", "Joshua", "Michelle",
    "Kenneth", "Dorothy", "Kevin", "Carol", "Brian",
    "Amanda", "George", "Melissa", "Edward", "Deborah",
    "Ronald", "Stephanie", "Timothy", "Rebecca", "Jason",
    "Laura"
];
  

const lastNames = [
    "Smith", "Johnson", "Williams", "Brown", "Jones",
    "Garcia", "Miller", "Davis", "Rodriguez", "Martinez",
    "Hernandez", "Lopez", "Gonzalez", "Wilson", "Anderson",
    "Thomas", "Taylor", "Moore", "Jackson", "Martin",
    "Lee", "Perez", "Thompson", "White", "Harris",
    "Sanchez", "Clark", "Ramirez", "Lewis", "Robinson",
    "Walker", "Young", "Allen", "King", "Wright",
    "Scott", "Torres", "Nguyen", "Hill", "Flores",
    "Green", "Adams", "Nelson", "Baker", "Hall",
    "Rivera", "Campbell", "Mitchell", "Carter", "Roberts"
];

/**
 * Generates a random number of length n.
 * @param n - The length of the number.
 * @returns A random number.
 */
export function generateRandomNumber(n: number): number {
    let numberString = '';
    for (let i = 0; i < n; i++) {
        const randomNumber = Math.floor(Math.random() * 10);
        numberString += randomNumber.toString();
    }
    return parseInt(numberString);
}

/**
 * Generates a random date.
 * @returns A random date.
 */
export function generateRandomDate(): Date {
    const date = new Date();
    const randomDays = Math.floor(Math.random() * 365);
    date.setDate(date.getDate() - randomDays);
    return date;
}

/**
 * Generates a random username.
 * @param fName - The first name.
 * @param lName - The last name.
 * @returns A random username.
 */
export function generateRandomUsername(fName?: string, lName?: string): string {
    const firstName = fName || generateRandomFirstName();
    const lastName = lName || generateRandomLastName();
    const username = `${firstName.toLowerCase()}.${lastName.toLowerCase()}`;
    return username;
}

/**
 * Represents a collection of common URLs.
 */
export enum CommonUrls{
    randomPhoto = "https://picsum.photos/400",
    templatePhoto = "https://via.placeholder.com/400",
    smallTemplatePhoto = "https://via.placeholder.com/150",
}

const randomImages = [
    "https://fastly.picsum.photos/id/918/400/400.jpg?hmac=X2r2PLFn6H8LOHvNyMChgfomjGKVeKOwRT-9ZtaTtcs",
    "https://fastly.picsum.photos/id/1014/400/400.jpg?hmac=OEgudIjURE8fnXbxtGivpSbudWaOMwc3EX_XNM3GGrM",
    "https://fastly.picsum.photos/id/1020/400/400.jpg?hmac=j2LfdMwlbYgAWkfKIpBGnLc7h0yA01uEb4SR_4jYlK8",
    "https://fastly.picsum.photos/id/515/400/400.jpg?hmac=ObxAgpXcysaLz2Z0PZFebIrE4osfn6Gq0ukt_bElwOM",
    "https://fastly.picsum.photos/id/80/400/400.jpg?hmac=6sJgehQvlHze3RCac4W21GGcQxP7IAOupdkWgBSsYJ4",
    "https://fastly.picsum.photos/id/73/400/400.jpg?hmac=zWpB1s_65SmeFv9qjEBkEIHyN0la8V7v6m_MalSPkAE",
    "https://fastly.picsum.photos/id/423/400/400.jpg?hmac=0Y_2e7lsenUvr1NfNU0qypVFPkACWzUNDqq-KE_aqic",
    "https://fastly.picsum.photos/id/36/400/400.jpg?hmac=-8d4uf98C3H4sqivMg-oraXCJcy1v0W1h7MHbCGQPl4",
    "https://fastly.picsum.photos/id/1078/400/400.jpg?hmac=FoVJ4owV28SWX9J39RKVQkOVAXmhCTWni2Hm2Z31YR8",
    "https://fastly.picsum.photos/id/1049/400/400.jpg?hmac=oRBYeEhCswXQYTTqjGjSNUlCJQ6kiDmbNcARLTS2T9U",
    "https://fastly.picsum.photos/id/678/400/400.jpg?hmac=_UyWsGoQX8ADlybW4nUY_kpn3xgCkfgEPZs688S5XJ4",
    "https://fastly.picsum.photos/id/686/400/400.jpg?hmac=vyAuUfLG282Gnl-ym3_Fh6faAR0mIj5wMllsXnlkTMY",
    "https://fastly.picsum.photos/id/858/400/400.jpg?hmac=Nb5PcrwaPuqbi7vAXj1JYKqBAWpPbTwK_RxAWv6wTFo",
    "https://fastly.picsum.photos/id/92/400/400.jpg?hmac=0BbTdsR0yJz23MxmYa6l9PLZbG2l-vH6F2qJa64ZQ7k",
    "https://fastly.picsum.photos/id/180/400/400.jpg?hmac=6wejBjIhQa_EVoTRrmCV1wSTaTulCihrn1jq8hRqBms",
    "https://fastly.picsum.photos/id/283/400/400.jpg?hmac=PdoBgKfrXTn2WjzwNMu62TIRIRNAAqdHODmaGp_p7eY",
    "https://fastly.picsum.photos/id/957/400/400.jpg?hmac=ZLXFL-zJUz6z8R-fhHBA7kwFRy-2rxXp3hIj34cFA7g",
    "https://fastly.picsum.photos/id/311/400/400.jpg?hmac=IE0X0ksBW2Qz4oloOckHgm8jfurttSVQ2eNdJ7ZIH3s",
    "https://fastly.picsum.photos/id/527/400/400.jpg?hmac=MXbXndpNxCTpTRo8t7CrDxkgox5K67HCUzBzUKx3_Lg",
    "https://fastly.picsum.photos/id/628/400/400.jpg?hmac=uQnrWHhKS3XBUnJaTHJHb7TBhQX7uZ0p1q_Y2hLnEWY",
    "https://fastly.picsum.photos/id/358/400/400.jpg?hmac=ws6ColdsxC23yxENxHqQAteSM4QnRH-X4vKygHx_B0g",
    "https://fastly.picsum.photos/id/317/400/400.jpg?hmac=3CV3QPZ6FqnuY4is5QJ2HImxUpBa1XoOUAnLrTrFjyI",
    "https://fastly.picsum.photos/id/941/400/400.jpg?hmac=WdghaFHvMg5lqjDBgxAM94fNAdL9HOClKE2Nc_oN3pA",
    "https://fastly.picsum.photos/id/174/400/400.jpg?hmac=_mWTlZ5OfHinSa-NEn9OPX6HQdEabzsNYj3wrOPVQLY",
    "https://fastly.picsum.photos/id/460/400/400.jpg?hmac=dpRzm5U6VOlRCUWSvelJa0FM46oL4yR7hHUrytuQT2Q",
    "https://fastly.picsum.photos/id/64/400/400.jpg?hmac=ErFmAl4erEx5YCaq80Ik_J0J5YXwmgvoDkCmyekuL_I",
    "https://fastly.picsum.photos/id/931/400/400.jpg?hmac=JN68cEgHw9fJ661Qz4RWgpfDGSSdE_CD8Td1-Og_u2g",
    "https://fastly.picsum.photos/id/611/400/400.jpg?hmac=dqUFohQSDmf7SOKUoEBL6RhPD7WY9RyxRYlhgZoc4zY",
    "https://fastly.picsum.photos/id/80/400/400.jpg?hmac=6sJgehQvlHze3RCac4W21GGcQxP7IAOupdkWgBSsYJ4",
    "https://fastly.picsum.photos/id/345/400/400.jpg?hmac=qKfm9j4QuRqIU1ypRBFXf4BBjSW-6uMoLcPv7wYZkLI",
    "https://fastly.picsum.photos/id/301/400/400.jpg?hmac=zyvrb1-rRPZWmcG3VlsvJKjNDConNI5Px-52zl23aoM",
    "https://fastly.picsum.photos/id/625/400/400.jpg?hmac=gRwxqn6B9CPci-idyZTa6Bi9rARS-8NqQnb9lh05wEA",
    "https://fastly.picsum.photos/id/1013/400/400.jpg?hmac=IPshdF4clrkLnbBc-G7DvuGrce6xlqEYnVizCwqrB3A",
    "https://fastly.picsum.photos/id/861/400/400.jpg?hmac=Bt3C22W8d4rkkTYLllIRhZyKnD8LLvwgzUmqhGjzKsI",
    "https://fastly.picsum.photos/id/1050/400/400.jpg?hmac=DDF_twEKATFkYxVZo8a84_jhKuy0Jgu5m47_rsimIqg",
    "https://fastly.picsum.photos/id/426/400/400.jpg?hmac=WpQ0DrGHfulfkq3xDlceS2pHbuPmh_vD3iE41IpjudA",
    "https://fastly.picsum.photos/id/888/400/400.jpg?hmac=7HcN9hCWo0fdEyjP79Kejg9ss3_OA9r-1v1P8YQ2hPY",
    "https://fastly.picsum.photos/id/534/400/400.jpg?hmac=q5ag31CPt_mcalYny_y_K_pLToHBJ1UW8aOF0UgeGhA",
    "https://fastly.picsum.photos/id/176/400/400.jpg?hmac=htsWa5W-TirEaolha3-eSmDojl6MOKOIZa7EeNGbS5Q",
    "https://fastly.picsum.photos/id/541/400/400.jpg?hmac=t7-QqvFJEPeBgOU1Ej6KxR5r4TEPpBuJG8ueyZPI_LE",
    "https://fastly.picsum.photos/id/911/400/400.jpg?hmac=co2_G5ovTdaAFQPZa2Qk2Iu9yI6DF5tX6-ix9CModKc",
    "https://fastly.picsum.photos/id/222/400/400.jpg?hmac=t8ijXImsBDqkVdvkhNlz7GVlhkXicPc4W7ZXvN7TBzI",
    "https://fastly.picsum.photos/id/334/400/400.jpg?hmac=UpHNsYIHrauzlcGPuvPr_NnVeOBwx-6ivqTCh1_IaYE",
]

/**
 * Gets a random image from the predefined list of random images.
 * @returns A random image.
 */
export function getRandomSquareImage(): string {
    const randomIndex = Math.floor(Math.random() * randomImages.length);
    return randomImages[randomIndex];
}



const randomPartyNames = [
    "Birthday Party", "Graduation Party", "Wedding", "Anniversary Party", "Retirement Party",
    "Baby Shower", "Bridal Shower", "Bar Mitzvah", "Bat Mitzvah", "Quinceañera",
    "Sweet 16", "Prom", "Homecoming", "Reunion", "Engagement Party",
    "Bachelor Party", "Bachelorette Party", "Housewarming Party", "Welcome Home Party", "Farewell Party",
    "Going Away Party", "Welcome Party", "New Year's Eve Party", "Halloween Party", "Christmas Party",
    "Easter Party", "Thanksgiving Party", "4th of July Party", "Labor Day Party", "Memorial Day Party",
    "Cinco de Mayo Party", "St. Patrick's Day Party", "Valentine's Day Party", "Mardi Gras Party", "Super Bowl Party",
    "Oscar Party", "Emmy Party", "Grammy Party", "Golden Globe Party", "Tony Party",
    "Academy Awards Party", "Game Night", "Movie Night", "Game Day", "Tailgate Party",
    "BBQ", "Cookout", "Picnic", "Potluck", "Dinner Party",
    "Brunch", "Breakfast", "Lunch", "Dinner", "Supper",
    "Tea Party", "Coffee Party", "Ice Cream Social", "Pajama Party", "Slumber Party",
    "Sleepover", "Pool Party", "Beach Party", "Luau", "Toga Party",
    "Pirate Party", "Princess Party", "Superhero Party", "Pajama Party", "Slumber Party",
    "Sleepover", "Pool Party", "Beach Party", "Luau", "Toga Party",
    "Pirate Party", "Princess Party", "Superhero Party", "Pirate Party", "Princess Party",
    "Superhero Party", "Pirate Party", "Princess Party", "Superhero Party", "Pirate Party",
];

/**
 * Generates a random party name from a predefined list.
 * @returns A random party name.
 */
export function generateRandomPartyName(): string {
    const randomIndex = Math.floor(Math.random() * randomPartyNames.length);
    return randomPartyNames[randomIndex];
}

const randomPartyDescriptions = [
    "Come and celebrate with us!", "Join us for a night of fun!", "You're invited to our party!",
    "Let's have a great time!", "It's party time!", "We're throwing a party!",
    "Come and join us!", "We're having a party!", "You're invited to our event!",
    "We're celebrating!", "Join us for a celebration!", "We're throwing a bash!",
    "We're having a blast!", "We're having a great time!", "We're having a good time!",
    "We're having a party!", 
];

/**
 * Generates a random party description from a predefined list.
 * @returns A random party description.
 */
export function generateRandomPartyDescription(): string {
    const randomIndex = Math.floor(Math.random() * randomPartyDescriptions.length);
    return randomPartyDescriptions[randomIndex];
}

const randomConversationNames = [
    "Family Chat", "Friends Chat", "Work Chat", "School Chat", "College Chat", "University Chat", 
    // "High School Chat", "Middle School Chat", "Elementary School Chat", "Kindergarten Chat", "Preschool Chat", "Church Chat", "Temple Chat", "Mosque Chat", "Synagogue Chat",
    "Club Chat", "Team Chat", "Squad Chat", "Group Chat", "Crew Chat",
    "Gang Chat", "Posse Chat", "Clique Chat", "Society Chat", "Association Chat",
    "Organization Chat", "Company Chat", "Corporation Chat", "Business Chat", "Enterprise Chat",
    "Firm Chat", "Agency Chat", "Office Chat", "Store Chat", "Shop Chat",
    "Restaurant Chat", "Cafe Chat", "Diner Chat", "Bistro Chat", "Bar Chat",
    "Pub Chat", "Tavern Chat", "Club Chat", "Lounge Chat", "Saloon Chat",
    "Gym Chat", "Fitness Chat", "Health Chat", "Wellness Chat", "Spa Chat",
    "Yoga Chat", "Pilates Chat", "Meditation Chat", "Mindfulness Chat", "Relaxation Chat",
    "Therapy Chat", "Counseling Chat", "Support Chat", "Group Chat", "Session Chat",
    "Meeting Chat", "Conference Chat", "Summit Chat", "Convention Chat", "Assembly Chat",
    "Gathering Chat", "Rally Chat", "Protest Chat", "March Chat", "Parade Chat",
    "Festival Chat", "Carnival Chat", "Fair Chat", "Expo Chat", "Show Chat",
    "Exhibition Chat", "Event Chat", "Party Chat", "Celebration Chat", "Gala Chat",
    "Ball Chat", "Prom Chat", "Dance Chat", "Concert Chat", "Performance Chat",
    "Show Chat", "Play Chat", "Musical Chat", "Theater Chat", "Cinema Chat",
    "Movie Chat", "Film Chat", "Screening Chat", "Premiere Chat", "Opening Chat",
    "Debut Chat", "Introduction Chat", "Launch Chat", "Release Chat", "Unveiling Chat",
    "Reveal Chat", "Presentation Chat", "Demonstration Chat", "Exhibition Chat", "Display Chat",
];

/**
 * Generates a random conversation name from a predefined list.
 * @returns A random conversation name.
 */
export function generateRandomConversationName(): string {
    const randomIndex = Math.floor(Math.random() * randomConversationNames.length);
    return randomConversationNames[randomIndex];
}

const randomMessages = [
    "Hello!", "How are you?", "I'm doing well.", "What's up?", "How's it going?",
    "I'm good.", "I'm great.", "I'm fantastic.", "I'm amazing.", "I'm wonderful.",
    "I'm awesome.", "I'm incredible.", "I'm spectacular.", "I'm fabulous.", "I'm marvelous.",
    "I'm terrific.", "I'm excellent.", "I'm superb.", "I'm outstanding.", "I'm phenomenal.",
    "I'm extraordinary.", "I'm exceptional.", "I'm remarkable.", "I'm unbelievable.",
];

/**
 * Generates a random message from a predefined list.
 * @returns A random message.
 */
export function generateRandomMessage(): string {
    const randomIndex = Math.floor(Math.random() * randomMessages.length);
    return randomMessages[randomIndex];
}