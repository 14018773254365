import { CommonUrls, Factory, generateRandomDate, generateRandomNumber, generateRandomPartyName, generateRandomPartyDescription, getRandomSquareImage } from "../MockData/CommonMockData";

interface PrimitiveParty {
    id: string;
    name: string;
}

export interface Party extends PrimitiveParty {
    description: string;
    imageUrl: string;
    dateCreated: Date;
}

export class Party implements Party {

    constructor(id?: string, name?: string, description?: string, imageUrl?: string, dateCreated?: Date) {
        this.id = id || "";
        this.name = name || "";
        this.description = description || "";
        this.imageUrl = imageUrl || "";
        this.dateCreated = dateCreated || new Date();
    }
}

export class PartyFactory implements Factory {
    create: () => Party = PartyFactory.createNewParty;
    createMock: () => Party = PartyFactory.createMockParty;
    createMockArray: (n: number) => Party[] = PartyFactory.createMockPartyArray;
    static shared = new PartyFactory();
    static createNewParty(): Party {
        return new Party();
    }
    static createMockParty(): Party {
        return new Party(
            undefined,
            generateRandomPartyName(),
            generateRandomPartyDescription(),
            getRandomSquareImage(),
            generateRandomDate()
        );
    }
    static createMockPartyArray(n: number): Party[] {
        let parties: Party[] = [];
        for (let i = 0; i < n; i++) {
            parties.push(PartyFactory.createMockParty());
        }
        return parties;
    }
}

export interface PartyV2 {
    hostId: string;
    partyId: string;
    name: string;
    description: string;
    date: {
        day: number;
        month: number;
        year: number;
    };
    time: {
        hour: number;
        minute: number;
    };
    timezone: string;
    hypescore: number;
    isSellingTickets: boolean;
    isRsvp: boolean;
    hasDj: boolean;
    entertainerId: string;
    timestamp: string;
    partyFlyer: string;
}

export class PartyV2 {
    constructor(
        hostId?: string,
        partyId?: string,
        name?: string,
        description?: string,
        date?: { day: number, month: number, year: number },
        time?: { hour: number, minute: number },
        timezone?: string,
        hypescore?: number,
        isSellingTickets?: boolean,
        isRsvp?: boolean,
        hasDj?: boolean,
        entertainerId?: string,
        timestamp?: string,
        partyFlyer?: string,
    ) 
    {
        this.hostId = hostId || '';
        this.partyId = partyId || '';
        this.name = name || '';
        this.description = description || '';
        this.date = date || { day: 0, month: 0, year: 0 };
        this.time = time || { hour: 0, minute: 0 };
        this.timezone = timezone || '';
        this.hypescore = hypescore || 0;
        this.isSellingTickets = isSellingTickets || false;
        this.isRsvp = isRsvp || false;
        this.hasDj = hasDj || false;
        this.entertainerId = entertainerId || '';
        this.timestamp = timestamp || '';
        this.partyFlyer = partyFlyer || '';
    }
}

export class PartyV2Factory implements Factory {
    create: () => PartyV2 = PartyV2Factory.createNewParty;
    createMock: () => PartyV2 = PartyV2Factory.createMockParty;
    createMockArray: (n: number) => PartyV2[] = PartyV2Factory.createMockPartyArray;
    createArrayFromHttp: (response: any) => PartyV2[] = PartyV2Factory.createNewPartyArrayFromData;
    static shared = new PartyV2Factory();
    static createNewParty(): PartyV2 {
        return new PartyV2();
    };
    static createMockParty(): PartyV2 {
        return new PartyV2(
            undefined,
            undefined,
            generateRandomPartyName(),
            generateRandomPartyDescription(),
            undefined,
            undefined,
            undefined,
            generateRandomNumber(1),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            getRandomSquareImage(),
        );
    };
    static createMockPartyArray (n: number): PartyV2[] {
        let parties: PartyV2[] = [];
        for (let i = 0; i < n; i++) {
            parties.push(PartyV2Factory.createMockParty());
        };
        return parties;
    };
    static createNewPartyArrayFromData(response: any): PartyV2[] {
        if(response.status !== 200) {
            console.log('Error: ', response?.statusMessage || "Unknown error");
            return [];
        };
        var partyProperty = '';
        if (response.data?.["myParties"]) partyProperty = "myParties"
        else if (response.data?.["myUpcomingParties"]) partyProperty = "myUpcomingParties"
        else if (response.data?.["myPastParties"]) partyProperty = "myPastParties"
        else if (response.data?.["myFollowedParties"]) partyProperty = "myFollowedParties"

        let data = response.data?.[partyProperty]
        let parties: PartyV2[] = data.map((party: any) => {
            return new PartyV2(
                party?.hostId || '',
                party?.partyId || '',
                party?.name || '',
                party?.description || '',
                { day: party?.date.day || 0, month: party?.date.month || 0, year: party?.date.year || 0 },
                { hour: party?.time.hour || 0, minute: party?.time.minute || 0 },
                party?.timezone || '',
                party?.hypescore || 0,
                party?.isSellingTickets || false,
                party?.isRsvp || false,
                party?.hasDj || false,
                party?.entertainerId || '',
                party?.timestamp || '',
                party?.partyFlyer.url || '',
            );
        });
        return parties;
    };
}