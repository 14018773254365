<section class="profile-wrapper">
    <div class="profile-container">
        <div class="profile-header-container">
            <hype-profile-picture-custom [size]="'150px'" [url]="user.profilePicture"></hype-profile-picture-custom>
            <div class="user-info-container">
                <h1>{{user.username || "Temp User"}}</h1>
                <h5>&#64;{{user.userhandle || "temp.userhandle"}}</h5>
                <div class="user-bio-container">
                    <p>{{user.bio}}</p>
                </div>
                <div class="edit-profile-container">
                    <button class="edit-profile-button" (click)="editProfile()" >Edit Profile</button>
                </div>
            </div>
        </div>
        <section class="profile-stats-container">
            <div class="followers-container">
                <h3>{{user.numFollowers}}</h3>
                <h5>Followers</h5>
            </div>
            <div class="following-container">
                <h3>{{user.numFollowings}}</h3>
                <h5>Following</h5>
            </div>
            <div class="hypescore-container">
                <h3>{{user.hypescore}}</h3>
                <h5>Hypescore</h5>
            </div>
        </section>
        <section class="profile-content-wrapper">
            <div class="profile-content-header">
                <div class="content-header-button-container">
                    <button class="button-inactive" (click)="tabSelected(1)" #mediaBtn>
                        Media
                    </button>
                </div>
                <div class="content-header-button-container">
                    <button class="button-inactive" (click)="tabSelected(2)" #postsBtn>
                        Posts
                    </button>
                </div>
                <div class="content-header-button-container">
                    <button class="button-inactive" (click)="tabSelected(3)" #partiesBtn>
                        Parties
                    </button>
                </div>
            </div>
            <section class="profile-content-container" >
                <div class="profile-content" *ngIf="selectedTab === 1">
                    <hype-my-profile-content-media></hype-my-profile-content-media>
                </div>
                <div class="profile-content" *ngIf="selectedTab === 2">
                    <hype-my-profile-content-posts [user]="user"></hype-my-profile-content-posts>
                </div>
                <div class="profile-content" *ngIf="selectedTab === 3">
                    <hype-my-profile-content-parties></hype-my-profile-content-parties>
                </div>
            </section>
        </section>
    </div>
</section>
<hype-overlay [(display)]="showingEditProfileMenu" [disableCloseOnClick]="true" (click)="editProfile()">
    <div class="edit-profile-overlay-container">
        <div class="edit-profile-overlay">
            <p>Edit profile</p>
            <div class="change-profile-picture">
                <p>Profile picture</p> 
                <div class="pfp-display-container">
                    <hype-profile-picture-custom [size]="'120px'" [url]="profilePicture"></hype-profile-picture-custom>
                </div>
                <label for="pfp-input" *ngIf="!pictureUploaded">Choose File</label> 
                <input 
                type="file"
                id="pfp-input"
                (change)="uploadProfilePicture($event)"
                accept="image/png, image/jpeg, image/jpg"
                [formControl]="profileImage"
                >
                <div class="confirm-cancel-buttons" *ngIf="pictureUploaded">
                    <button class="confirm" (click)="confirmProfilePicture()">Upload</button>
                    <button class="cancel" (click)="cancelProfilePicture()">Cancel</button>
                </div>
            </div>
            <form [formGroup]="editProfileForm">
                <div class="edit-profile-input">
                    <label for="username-input">Username</label>
                    <input id="username-input" type="text" class="username" placeholder="{{user.username}}" formControlName="username">
                    <small class="error" *ngIf="editProfileForm.get('username')?.hasError('minlength')">Username must be at least 2 characters long.</small>
                    <small class="error" *ngIf="editProfileForm.get('username')?.hasError('maxlength')">Username must be less than 21 characters long.</small>
                    <small class="error" *ngIf="editProfileForm.get('username')?.hasError('pattern')">Username cannot have leading, trailing or double spaces.</small>
                </div>
                <div class="edit-profile-input">
                    <label for="first-name-input">First name</label>
                    <input id="first-name-input" type="text" class="first-name" placeholder="{{user.details.firstName}}" formControlName="firstName">
                    <small class="error" *ngIf="editProfileForm.get('firstName')?.hasError('minlength')">First name must be at least 2 characters long.</small>
                    <small class="error" *ngIf="editProfileForm.get('firstName')?.hasError('maxlength')">First name must be less than 21 characters long.</small>
                    <small class="error" *ngIf="editProfileForm.get('firstName')?.hasError('pattern')">First name cannot have leading, trailing or double spaces.</small>
                </div>
                <div class="edit-profile-input">
                    <label for="last-name-input">Last name</label>
                    <input id="last-name-input" type="text" class="last-name" placeholder="{{user.details.lastName}}" formControlName="lastName">
                    <small class="error" *ngIf="editProfileForm.get('lastName')?.hasError('minlength')">Last name must be at least 2 characters long.</small>
                    <small class="error" *ngIf="editProfileForm.get('lastName')?.hasError('maxlength')">Last name must be less than 21 characters long.</small>
                    <small class="error" *ngIf="editProfileForm.get('lastName')?.hasError('pattern')">Last name cannot have leading, trailing or double spaces.</small>
                </div>
                <div class="edit-profile-input">
                    <label for="bio-input" >Bio</label>
                    <textarea id="bio-input" formControlName="bio" [placeholder]="user.bio ? user.bio : 'No bio yet'"></textarea>
                    <small class="error" *ngIf="editProfileForm.get('bio')?.hasError('maxlength')">Bio must be less than 120 characters long.</small>
                    <small class="error" *ngIf="editProfileForm.get('bio')?.hasError('pattern')">Bio cannot have leading, trailing or double spaces.</small>
                </div>
                <button class="save-edits-button" type="submit" (click)="saveProfileEdits()" [disabled]="editProfileForm.invalid">Save</button>
            </form>
        </div>
    </div>
</hype-overlay>