import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'hype-s-profile-picture-custom',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './s-profile-picture-custom.component.html',
  styleUrls: ['./s-profile-picture-custom.component.scss']
})
export class SProfilePictureCustomComponent implements AfterViewInit {
  @Input() url?: string = '';
  @Input() size?: string = '100px';
  @Input() borderSize?: string = '3px';
  @ViewChild('picture') picture: any;
  constructor() { }
  ngAfterViewInit(): void {
    this.picture.nativeElement.style.width = this.size;
    this.picture.nativeElement.style.height = this.size;
    this.picture.nativeElement.style.borderWidth = this.borderSize;
  }
}
 