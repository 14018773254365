<div class="posts-container" infinite-scroll (scrolled)="handleScroll()">
    <div class="post-item" *ngFor="let post of posts">
        <div class="post-header">
            <div class="header-left">
                <hype-profile-picture [url]="user.profilePicture"></hype-profile-picture>
                <h4>{{user.username}}</h4>
                <h4 class="sub-content">{{user.userhandle}}</h4>
                <h4 class="sub-content">•</h4>
                <h4 class="sub-content">{{post.getDateString()}}</h4>
            </div>
            <div class="header-right">
                <div class="dropdown-menu-button-container">
                    <!-- <hype-ion-icon-wrapper [iconName]="'more'"></hype-ion-icon-wrapper> -->
                    <h2 class="dropdown-menu-button" (click)="toggleDropdownMenu($event)">...</h2>
                    <div class="dropdown-menu">
                        <div class="dropdown-menu-item" (click)="editPost(post)">
                            <hype-ion-icon-wrapper [iconName]="'create'" ></hype-ion-icon-wrapper>
                            <h4>Edit Post</h4>
                        </div>
                        <div class="dropdown-menu-item" (click)="deletePost(post, $event)">
                            <hype-ion-icon-wrapper [iconName]="'trash'" style="color: rgb(182, 27, 27);"></hype-ion-icon-wrapper>
                            <h4>Delete Post</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="post-content">
            <p>{{post.textContent}}</p>
            <div class="images-container" *ngIf="post.mediaUrls.length > 0">
                <div class="image-container" *ngFor="let imageUrl of post.mediaUrls">
                    <img src="{{imageUrl}}" alt="">
                </div>
            </div>
        </div>
        <div class="post-footer">
            <div class="footer-item" (click)="viewCommentsButtonClicked(post)">
                <hype-ion-icon-wrapper [iconName]="'chatbubbles'" [iconSize]="iconSize" class="comments"></hype-ion-icon-wrapper>
                <h4>0</h4>
            </div>
            <div class="footer-item">
                <hype-ion-icon-wrapper [iconName]="'heart'" [iconSize]="iconSize"></hype-ion-icon-wrapper>
                <h4>{{post.hypescore}}</h4>
            </div>
            <div class="footer-item">
                <hype-ion-icon-wrapper [iconName]="'share'" [iconSize]="iconSize"></hype-ion-icon-wrapper>
                <h4>0</h4>
            </div>
        </div>
    </div>
    <div class="no-post-items" *ngIf="posts.length <= 0">
        <h4 class="simple-h4">You have no recent posts</h4>
    </div>
</div>
<hype-overlay [(display)]="showingDeleteConfirmation" [disableCloseOnClick]="true">
    <div class="confirmation-menu-container">
        <div class="text-container">
            <h4>Are you sure you want to delete this post?</h4>
        </div>
        <div class="button-container">
            <button class="cancelDeleteButton" (click)="deleteCancelled()">Cancel</button>
            <button class="confirmDeleteButton" (click)="deleteConfirmed()">Delete</button>
        </div>
    </div>
</hype-overlay>
<hype-overlay [(display)]="showingComments">
    <hype-feed-post-comments [postId]="postId" [(display)]="showingComments" class="comment-section"></hype-feed-post-comments>
</hype-overlay>