import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonUrls } from 'src/app/MockData/CommonMockData';

@Component({
  selector: 'hype-profile-picture-custom',
  templateUrl: './profile-picture-custom.component.html',
  styleUrls: ['./profile-picture-custom.component.scss']
})
export class ProfilePictureCustomComponent implements AfterViewInit {
  @Input() url?: string = CommonUrls.randomPhoto;
  @Input() size?: string = "100px";
  @Input() borderSize?: string = "3px";
  @ViewChild('picture') picture: any;
  constructor() { }
  ngAfterViewInit(): void {
    this.picture.nativeElement.style.width = this.size;
    this.picture.nativeElement.style.height = this.size;
    this.picture.nativeElement.style.borderWidth = this.borderSize;
  }
}
