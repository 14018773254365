import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  readonly defaultPreferences = {
    theme: 'default',
    language: 'en'
  };
  readonly themes = ['default', 'dark', 'dim', 'gray'];
  readonly languages = ['en', 'es'];
  readonly themeColors = {
    light: {
      primary: '#007bff',
      secondary: '#6c757d',
      success: '#28a745',
      danger: '#dc3545',
      warning: '#ffc107',
      info: '#17a2b8',
      light: '#f8f9fa',
      dark: '#343a40'
    },
    dark: {
      primary: '#007bff',
      secondary: '#6c757d',
      success: '#28a745',
      danger: '#dc3545',
      warning: '#ffc107',
      info: '#17a2b8',
      light: '#f8f9fa',
      dark: '#343a40'
    }
  };

  private readonly myPreferencesUrl = `${environment.apiBaseUrl}/users/my-preferences` 

  constructor(private http: HttpClient) { }

  getPreferences(){
    let preferences = localStorage.getItem('preferences');
    if (preferences){
      return JSON.parse(preferences);
    } else {
      return this.defaultPreferences;
    }
  }

  setTheme(theme: string){
    // validate theme
    if (!this.themes.includes(theme)){
      console.error('Invalid theme:', theme);
      return;
    }

    let body = document.querySelector('body');
    let preferences = this.getPreferences();
    preferences.theme = theme;
    localStorage.setItem('preferences', JSON.stringify(preferences));
    if (body){
      this.themes.forEach((theme) => {
        body?.classList.remove(`${theme}-theme`);
      });
      body.classList.add(`${theme}-theme`);
    }
  }

  initializeTheme(){
    let preferences = this.getPreferences();
    let body = document.querySelector('body');
    if (body){
      this.themes.forEach((theme) => {
        body?.classList.remove(`${theme}-theme`);
      });
      body.classList.add(`${preferences.theme}-theme`);
    }
  }

  async getMyPreferences() {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);

    let response = await this.http.get(this.myPreferencesUrl, { headers: headers }).toPromise();
    return response
  };

  async updateMyPreferences(theme: string) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    const body = {
      preferences: {
        theme: theme
      }
    };
    let response = await this.http.put(this.myPreferencesUrl, body, { headers: headers }).toPromise();
    return response
  };
}
