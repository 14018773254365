<section class="party-creation-wrapper">
    <div class="party-creation-container">
        <div class="progress-bar-container">
            <div class="progress-bar" #progressBar></div>
        </div>
        <div class="main-header-container">
            <h1>Host a Party</h1>
        </div>
        <div class="form-page-main-wrapper">
            <form action="" class="party-form" [formGroup]="partyForm" >
                <div class="form-page" [ngClass]="{ 'active-page': pageNumber === 1 }">
                    <div class="page-header">
                        <h2>Party Details</h2>
                    </div>
                    <div class="form-group">
                        <label for="partyName">Party Name</label>
                        <input type="text" id="partyName" name="partyName" formControlName="partyName">
                        <small class="error" *ngIf="partyForm.get('partyName')?.hasError('required') && partyForm.get('partyName')?.touched">Party name is required.</small>
                    </div>
                    <div class="form-group">
                        <label for="partyDescription">Party Description</label>
                        <textarea id="partyDescription" name="partyDescription" formControlName="partyDescription"></textarea>
                        <small class="error" *ngIf="partyForm.get('partyDescription')?.hasError('required') && partyForm.get('partyDescription')?.touched">Party description is required.</small>
                    </div>
                    <div class="form-group">
                        <label for="partyDate">Party Date</label>
                        <input type="date" id="partyDate" name="partyDate" formControlName="partyDate">
                        <small class="error" *ngIf="partyForm.get('partyDate')?.hasError('required') && partyForm.get('partyDate')?.touched">Party date is required.</small>
                    </div>
                    <div class="form-group">
                        <label for="partyTime">Party Time</label>
                        <input type="time" id="partyTime" name="partyTime" formControlName="partyTime">
                        <small class="error" *ngIf="partyForm.get('partyTime')?.hasError('required') && partyForm.get('partyTime')?.touched">Party time is required.</small>
                    </div>
                </div>
                <div class="form-page" [ngClass]="{ 'active-page': pageNumber === 2 }">
                    <div class="page-header">
                        <h2>Flyer</h2>
                    </div>
                    <section>
                        <div class="flyer-form-wrapper">
                            <div class="flyer-form"> 
                                <div class="flyer-wrapper" *ngFor="let photo of photos; let i = index"> 
                                    <img  [src]="photo" alt="photo-preview" >
                                    <p class="flyer-number">{{ i + 1}}</p>
                                    <button class="photo-remove-button" (click)="removeFlyer(i)">
                                        <hype-ion-icon-wrapper [iconName]="'close'"></hype-ion-icon-wrapper>
                                    </button>
                                </div>
                                <label for="flyer" class="upload-flyer-button">
                                    <hype-ion-icon-wrapper [iconName]="'image'"></hype-ion-icon-wrapper>
                                </label>
                                <input 
                                    type="file"
                                    id="flyer" 
                                    (change)="uploadFlyer($event)"
                                    accept="image/png, image/jpeg, image/jpg"
                                    formControlName="flyer"
                                >
                            </div>
                        </div>
                        
                    </section>
                </div>
                <div class="form-page" [ngClass]="{ 'active-page': pageNumber === 3 }">
                    <div class="page-header">
                        <h2>Music</h2>
                    </div>
                    <section>
                        <div class="music-form"> 
                            <div class="music-form-header"> 
                                <div class="dj-button-container"> 
                                    <button class="button-active" (click)="musicTabSelected(1)" #djButton> DJ </button> 
                                </div> 
                                <div class="bands-button-container"> 
                                    <button class="button-inactive" (click)="musicTabSelected(2)" #bandsButton> Bands </button> 
                                </div> 
                            </div> 
                            <section class="lists-container" > 
                                <div class="dj-list-container" *ngIf="selectedTab === 1">      
                                   <p>Djs list</p> <!--populate list once end point is implemented-->
                                </div> 
                                <div class="bands-list-container" *ngIf="selectedTab === 2">   
                                    <p>Bands list</p> <!--populate list once end point is implemented-->
                                </div> 
                            </section> 
                        </div>
                    </section>
                </div>
                <div class="form-page" [ngClass]="{ 'active-page': pageNumber === 4 }">
                    <div class="page-header">
                        <h2>Location</h2>
                    </div>
                    <div class="location-form"> 
                        <div class="location-form-header"> 
                            <div class="address-button-container"> 
                                <button class="button-active" (click)="locationTabSelected(1)" #addressButton> Address </button> 
                            </div> 
                            <div class="properties-button-container"> 
                                <button class="button-inactive" (click)="locationTabSelected(2)" #propertiesButton> Party Properties </button> 
                            </div> 
                        </div> 
                        <div class="address-form-container" *ngIf="locationSelectedTab === 1">
                            <div class="tba-option-container">
                                <p>To Be Announced</p>
                                <label for="tbaSlider" class="switch">
                                    <input type="checkbox" id="tbaSlider" formControlName="tbaSlider" (click)="toggleTbaCheck()">
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <div *ngIf="tbaIsChecked" class="tba-container">
                                <p>Your party will not be shown on the map and this cannot be changed after creating the party.</p>
                            </div>
                            <div *ngIf="!tbaIsChecked">
                                <div class="form-group">
                                    <label for="street">Street</label>
                                    <input type="text" id="street" formControlName="street">
                                    <small class="error" *ngIf="partyForm.get('street')?.hasError('required') && partyForm.get('street')?.touched">Street is required. </small>
                                </div>
                                <div class="form-group">
                                    <label for="city">City</label>
                                    <input type="text" id="city" formControlName="city">
                                    <small class="error" *ngIf="partyForm.get('city')?.hasError('required') && partyForm.get('city')?.touched">City is required. </small>
                                </div>
                                <div class="form-group">
                                    <label for="zipcode">Zip Code</label>
                                    <input type="text" id="zipcode" formControlName="zipcode">
                                    <small class="error" *ngIf="partyForm.get('zipcode')?.hasError('required') && partyForm.get('zipcode')?.touched">Zip code is required. </small>
                                </div>
                                <div class="form-group">
                                    <label for="state">State</label>
                                    <input type="text" id="state" formControlName="state">
                                    <small class="error" *ngIf="partyForm.get('state')?.hasError('required') && partyForm.get('state')?.touched">State is required. </small>
                                </div>
                                <div class="form-group">
                                    <label for="country">Country</label>
                                    <input type="" id="country" formControlName="country">
                                    <small class="error" *ngIf="partyForm.get('country')?.hasError('required') && partyForm.get('country')?.touched">Country is required. </small>
                                </div>
                            </div>
                        </div> 
                        <div class="properties-list-container" *ngIf="locationSelectedTab === 2">   
                            <p>Party Properties list</p> <!--populate list once end point is implemented-->
                        </div> 
                    </div>
                </div>
                <div class="form-page" [ngClass]="{ 'active-page': pageNumber === 5 }">
                    <div class="page-header">
                        <h2>Tickets</h2>
                    </div>
                    <section>
                        <div class="ticket-option-container">
                            <p>Enable Tickets</p>
                            <label for="ticketSlider" class="switch">
                                <input type="checkbox" id="ticketSlider" formControlName="ticketSlider" (click)="toggleTicketCheck()">
                                <span class="slider"></span>
                            </label>
                        </div>
                        <div *ngIf="ticketsIsChecked" class="tickets-container">
                            <button class="tickets-button" (click)="createTicket()">+</button>
                        </div>
                    </section>
                </div>
                <div class="form-page" [ngClass]="{ 'active-page': pageNumber === 6 }">
                    <div class="page-header">
                        <h2>Review</h2>
                    </div>
                    <section>
                        Work in here.. Don't worry about this for now...
                    </section>
                </div>
            </form>
        </div>
        
        <div class="pagination-buttons-container">
            <button class="previous-button active-button" (click)="previousPage()" #previousButton>Previous</button>
            <button class="next-button active-button" (click)="nextPage()" *ngIf="!lastPage" #nextButton>Next</button>
            <button class="submit-button active-button" type="submit" (click)="submitParty()" [disabled]="partyForm.invalid" *ngIf="lastPage" #submitButton>Submit</button>
        </div>
    </div>
</section>