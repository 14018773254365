<section class="conversation-list-wrapper">
    <div class="conversation-list-container">
        <div class="create-conversation-container">
            <div class="search-bar">
                <input type="text" placeholder="Search..." >
            </div>
            <button class="add-conversation" (click)="createConversation()">
                <hype-ion-icon-wrapper [iconSize]="'16px'" [iconName]="'add'"></hype-ion-icon-wrapper>
            </button>
        </div>
        <div class="conversations-container" *ngFor="let conversation of conversations" infinite-scroll (scrolled)="handleScroll()">
            <div *ngIf="conversation.unread">
                <div class="conversation-item unread" (click)="selectConversation(conversation.conversationId)" [ngClass]="{ 'active': checkSelected(conversation.conversationId) }">
                    <hype-s-profile-picture-custom class="profile-picture" [size]="'65px'" [borderSize]="'2px'" [url]="conversation.conversationImage"></hype-s-profile-picture-custom>
                    <div class="conversation-info">
                        <span class="name unread"><div class="indicator"></div>{{conversation.name}}</span>
                        <div class="message-info">
                            <span class="message unread">{{conversation.lastMessage}}</span>
                            <span class="separator"> • </span> 
                            <span class="message-date">{{conversation.getDateString()}}</span>
                        </div>
                    </div>
                    <button class="options">•••</button>
                </div>
            </div>
            <div *ngIf="!conversation.unread" >
                <div class="conversation-item" (click)="selectConversation(conversation.conversationId)" [ngClass]="{ 'active': checkSelected(conversation.conversationId) }">
                    <hype-s-profile-picture-custom class="profile-picture" [size]="'65px'" [borderSize]="'2px'" [url]="conversation.conversationImage"></hype-s-profile-picture-custom>
                    <div class="conversation-info">
                        <span class="name">{{conversation.name}}</span>
                        <div class="message-info">
                            <span class="message">{{conversation.lastMessage}}</span>
                            <span class="separator"> • </span>
                            <span class="message-date">{{conversation.getDateString()}}</span>
                        </div>
                    </div>
                    <button class="options">•••</button>
                </div>
            </div>
            
        </div>
    </div>
</section>

<hype-overlay [(display)]="displayMenu">
    <hype-create-conversation-menu [(display)]="displayMenu" (conversationCreated)="onConversationCreation($event)"></hype-create-conversation-menu>
</hype-overlay>