import { Component } from '@angular/core';

@Component({
  selector: 'hype-profile-skeleton',
  templateUrl: './profile-skeleton.component.html',
  styleUrls: ['./profile-skeleton.component.scss']
})
export class ProfileSkeletonComponent {

}
