<div class="group-members-menu-wrapper">
    <div class="group-members-menu-container">
        <div class="menu-header">
            <h2>Members</h2>
            <button class="close-button" (click)="closeMembersMenu()">
                <hype-ion-icon-wrapper [iconSize]="'17px'" [iconName]="'close'"></hype-ion-icon-wrapper>
            </button>
        </div>
        <div class="member-list">
            <div class="member" *ngFor="let member of members">
                <hype-s-profile-picture-custom [size]="'40px'" [borderSize]="'1px'" [url]="member.profilePicture"></hype-s-profile-picture-custom>
                <span class="member-name">{{member.username}}</span>
            </div>
        </div>
    </div>
</div>