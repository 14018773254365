<section class="post-creation-wrapper">
    <div class="post-creation-container">
        <div class="post-creation-header">
            <h1>Create Post</h1>
        </div>
        <form class="post-creation-content" [formGroup]="postForm" >
            <div class="post-creation-input-container">
                <textarea 
                    class="post-creation-input" 
                    placeholder="What's on your mind?"
                    formControlName="postText"
                ></textarea>
            </div>
            <div class="post-creation-photo-preview-container" *ngIf="photos.length > 0">
                <!-- Priview of images to be uploaded with the post -->
                <div class="photo-wrapper" *ngFor="let photo of photos; let i = index">
                    <div class="photo-container">
                        <img  [src]="photo" alt="photo-preview" >
                        <button class="photo-remove-button" (click)="removePhoto(i)">
                            <hype-ion-icon-wrapper [iconName]="'close'"></hype-ion-icon-wrapper>
                        </button>
                    </div>
                </div>
            </div>
            <div class="post-creation-menu-container">
                <label for="photo-input" class="post-creation-menu-item">
                    <hype-ion-icon-wrapper [iconName]="'camera'"></hype-ion-icon-wrapper>
                </label>
                <input 
                    type="file"
                    id="photo-input" 
                    (change)="attatchPhoto($event)"
                    accept="image/png, image/jpeg, image/jpg"
                >
                <button class="post-creation-menu-item" (click)="attatchPhoto(null)" type="menu">
                    <hype-ion-icon-wrapper [iconName]="'image'"></hype-ion-icon-wrapper>
                </button>
                <button class="post-creation-menu-item">
                    <hype-ion-icon-wrapper [iconName]="'videocam'"></hype-ion-icon-wrapper>
                </button>
                <!-- <button class="post-creation-menu-item">
                    <hype-ion-icon-wrapper [iconName]="'musical-notes'"></hype-ion-icon-wrapper>
                </button> -->
                <button class="post-creation-menu-item" id="send-button" (click)="submitPost()">
                    <hype-ion-icon-wrapper [iconName]="'send'"></hype-ion-icon-wrapper>
                </button>
            </div>
        </form>
    </div>
</section>