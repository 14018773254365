import { asyncGetRandomSquareImage, timestampToDate } from "../HelperFunctions/Common.helper";
import { Factory, generateRandomNumber, CommonUrls, generateRandomUsername, getRandomSquareImage } from "../MockData/CommonMockData";


interface PrimitivePost {
    id: string;
    authorId: string;
}

export interface Post extends PrimitivePost {
    title: string;
    content: any;
    description?: string;
    authorUsername: string;
    numLikes: number;
    numComments: number;
    dateCreated: Date;
    profilePicUrl: string;
    type: string;
    authorUserhandle?: string;
}

export class Post implements Post {

    constructor(id?: string, authorId?: string, authorUsername?: string, content?: string, description?: string, profilePic?: string, numLikes?: number, numComments?: number, type?: string, dateCreated?: Date, authorUserhandle?: string) {
        this.id = id || "";
        this.authorId = authorId || "";
        this.authorUsername = authorUsername || "";
        this.content = content || "";
        this.numLikes = numLikes || 0;
        this.numComments = numComments || 0;
        this.dateCreated = dateCreated || new Date();
        this.description = description || undefined;
        this.profilePicUrl = profilePic || CommonUrls.templatePhoto;
        // this.type = type || "invalid";
        // this.authorUserhandle = authorUserhandle || "";
    }
}

export class PostFactory implements Factory {
    asyncCreateMock: () => Promise<any> = PostFactory.asyncCreateMockPost;
    asyncCreateMockArray: (n: number) => Promise<any[]> = PostFactory.asyncCreateMockPostArray;
    create: () => Post = PostFactory.createNewPost;
    createMock: () => Post = PostFactory.createMockPost;
    createMockArray: (n: number) => Post[] = PostFactory.createMockPostArray;
    createFromHttp: (response: any) => Post = PostFactory.createNewPostFromData;
    createArrayFromHttp: (response: any) => Post[] = PostFactory.createNewPostArrayFromData;
    static shared = new PostFactory();
    static createNewPost(): Post {
        return new Post();
    }
    static createMockPost(): Post {
        return new Post(
            undefined,
            undefined,
            generateRandomUsername(),
            "I am a mock post",
            undefined,
            getRandomSquareImage(),
            generateRandomNumber(3),
            generateRandomNumber(3),
            "media",
            new Date()
        );
    }
    static createMockPostArray(n: number): Post[] {
        let posts: Post[] = [];
        for (let i = 0; i < n; i++) {
            posts.push(PostFactory.createMockPost());
        }
        return posts;
    }
    static async asyncCreateMockPost(): Promise<Post> {
        return new Promise((resolve, reject) => {
            asyncGetRandomSquareImage().then((url: string) => {
                let post  = PostFactory.createMockPost();
                post.profilePicUrl = url;
                console.log('Post created:', url);
                resolve(post);
            }).catch((err: Error) => {
                let post  = PostFactory.createMockPost();
                console.error('Error fetching image:', err);
                post.profilePicUrl = CommonUrls.templatePhoto;
                resolve(post);
            });
        });
    }
    static async asyncCreateMockPostArray(n: number): Promise<Post[]> {
        return new Promise(async (resolve, reject) => {
            let posts: Post[] = [];
            for (let i = 0; i < n; i++) {
                console.log('Creating post:', i);
                await PostFactory.asyncCreateMockPost().then((post) => {
                    console.log('Post created service:', i);
                    console.log('Post:', post);
                    posts.push(post);
                });
                console.log('Post created:', i);
                // posts.push(await PostFactory.asyncCreateMockPost());
            }
            console.log('Posts created:', posts);
            resolve(posts);
        });
    }
    static createNewPostFromData(response: any): Post {
        if(response.status !== 200) {
            return new Post();
        }
        let data = response.data;
        return new Post(
            data?.postId || "",
            data?.authorId || "",
            data?.authorUsername || "",
            data?.content || "",
            data?.description || "",
            data?.profilePicUrl || CommonUrls.templatePhoto,
            data?.hypescore || 0,
            data?.numComments || 0,
            data?.type || "invalid",
            new Date(data?.dateCreated || new Date())
        );
    }
    static createNewPostArrayFromData(response: any): Post[] {
        if(response.status !== 200) {
            return [new Post()];
        }
        let data = response.data.posts;
        // console.log('Data:', data);
        let posts: Post[] = data.map((post: any) => {
            return new Post(
                post?.postId || "",
                post?.authorId || "",
                post?.authorUsername || "",
                post?.content || "",
                post?.description || "",
                post?.profilePicUrl || CommonUrls.templatePhoto,
                post?.hypescore || 0,
                post?.numComments || 0,
                post?.type || "invalid",
                new Date(post?.dateCreated || new Date())
            );
        });
        // for (let i = 0; i < data.length; i++) {
        //     posts.push(PostFactory.createNewPostFromData(data[i]));
        // }
        return posts;
    }
}


// V2 Post Models

interface PrimitivePostV2 {
    postId: string;
    userId: string;
}

export interface MyPostV2 extends PrimitivePostV2 {
    postId: string;
    userId: string;
    hypescore: number;
    isPrivate: boolean;
    textContent: string;
    timestamp: Date;
    mediaUrls: string[];

    getDateString(): string;
}

export class MyPostV2 {
    
    constructor(
        id?: string, 
        userId?: string, 
        textContent?: string, 
        mediaUrls?: string[], 
        isPrivate?: boolean, 
        timestamp?: Date, 
        hypescore?: number,
    ) 
    {
        this.postId = id || "";
        this.userId = userId || "";
        this.hypescore = hypescore || 0;
        this.isPrivate = isPrivate || false;
        this.textContent = textContent || "";
        this.timestamp = timestamp || new Date();
        this.mediaUrls = mediaUrls || [];
    }

    getDateString(): string {
        const currentDate = new Date();
        const diff = currentDate.getTime() - this.timestamp.getTime();
        // difference in seconds, minutes, hours, days, weeks, months, years
        const hour = 1000 * 60 * 60;
        const twoHours = hour * 2;
        const minute = 1000 * 60;
        const twoMinutes = minute * 2;
        const day = 1000 * 60 * 60 * 24;
        const week = day * 7;
        const twoWeeks = week * 2;
        const month = day * 30;
        const twoMonths = month * 2;
        const year = day * 365;
        const twoYears = year * 2;
        const decade = year * 10;

        if (diff < minute) return 'Just now'; //shows when diff less than 60 seconds
        if (diff < twoMinutes) return 'A minute ago'; //shows when diff less than 120 seconds
        if (diff < (minute * 60)) {
            let minutes = Math.floor(diff / minute);
            return `${minutes} minutes ago`;
        }
        if (diff < twoHours) return 'An hour ago'; //shows when diff less than 120 minutes
        if (diff < (hour * 12)) {
            let hours = Math.floor(diff / hour);
            return `${hours} hours ago`;
        }
        if (diff < day) return 'Today';
        if (diff < (day * 2)) return 'Yesterday';
        if (diff < week) {
            let days = Math.floor(diff / day);
            return `${days} days ago`;
        }
        // if (diff < week) return 'This week';
        if (diff < twoWeeks) return 'A week ago';
        if (diff < month) {
            let weeks = Math.floor(diff / week);
            return `${weeks} weeks ago`;
        }
        // if (diff < month) return 'This month';
        if (diff < twoMonths) return 'A month ago';
        if (diff < year) {
            let months = Math.floor(diff / month);
            return `${months} months ago`;
        }
        if (diff < twoYears) return 'A year ago';
        if (diff < decade) {
            let years = Math.floor(diff / year);
            return `${years} years ago`;
        }
        // if (diff < year) return 'This year';
        return this.timestamp.toDateString();
    }
}

export class MyPostV2Factory implements Factory {
    create: () => MyPostV2 = MyPostV2Factory.createNewPost;
    createMock: () => MyPostV2 = MyPostV2Factory.createMockPost;
    createMockArray: (n: number) => MyPostV2[] = MyPostV2Factory.createMockPostArray;
    createFromHttp: (response: any) => MyPostV2 = MyPostV2Factory.createNewPostFromData;
    createArrayFromHttp: (response: any) => MyPostV2[] = MyPostV2Factory.createNewPostArrayFromData;
    static shared = new MyPostV2Factory();
    static createNewPost(): MyPostV2 {
        return new MyPostV2();
    }
    static createMockPost(): MyPostV2 {
        return new MyPostV2(
            undefined,
            undefined,
            "I am a mock post",
            [CommonUrls.randomPhoto],
            false,
            new Date(),
            generateRandomNumber(3)
        );
    }
    static createMockPostArray(n: number): MyPostV2[] {
        let posts: MyPostV2[] = [];
        for (let i = 0; i < n; i++) {
            posts.push(MyPostV2Factory.createMockPost());
        }
        return posts;
    }
    static createNewPostFromData(response: any): MyPostV2 {
        if(response.status !== 200) {
            return new MyPostV2();
        }
        let data = response.data;
        return new MyPostV2(
            data?.postId || "",
            data?.userId || "",
            data?.textContent || "",
            data?.mediaUrls || [],
            data?.isPrivate || false,
            new Date(data?.timestamp || new Date()),
            data?.hypescore || 0,
        );
    }
    static createNewPostArrayFromData(response: any): MyPostV2[] {
        if(response.status !== 200) return [new MyPostV2()];
        let data = response.data.myPosts;
        let posts: MyPostV2[] = data.map((post: any) => {
            var mediaUrls = post?.media.map((media: any) => {
                return media.location;
            });
            return new MyPostV2(
                post?.postId || "",
                post?.userId || "",
                post?.textContent || "",
                mediaUrls || [],
                post?.isPrivate || false,
                timestampToDate(post?.timestamp as string) || new Date(),
                post?.hypescore || 0,
            );
        });
        // console.log('Posts:', posts);
        return posts;
    }
}

export interface PostV2 extends PrimitivePostV2 {
    postId: string;
    userId: string;
    hypescore: number;
    textContent: string;
    timestamp: Date;
    mediaUrls: string[];
    isLiked: boolean;

    getDateString(): string;
}

export class PostV2 {
    constructor(
        id?: string, 
        userId?: string, 
        textContent?: string, 
        mediaUrls?: string[],
        timestamp?: Date, 
        hypescore?: number,
        isLiked?: boolean
    ) 
    {
        this.postId = id || "";
        this.userId = userId || "";
        this.hypescore = hypescore || 0;
        this.textContent = textContent || "";
        this.timestamp = timestamp || new Date();
        this.mediaUrls = mediaUrls || [];
        this.isLiked = isLiked || false;
    }

    getDateString(): string {
        const currentDate = new Date();
        const diff = currentDate.getTime() - this.timestamp.getTime();
        // difference in seconds, minutes, hours, days, weeks, months, years
        const hour = 1000 * 60 * 60;
        const twoHours = hour * 2;
        const minute = 1000 * 60;
        const twoMinutes = minute * 2;
        const day = 1000 * 60 * 60 * 24;
        const week = day * 7;
        const twoWeeks = week * 2;
        const month = day * 30;
        const twoMonths = month * 2;
        const year = day * 365;
        const twoYears = year * 2;
        const decade = year * 10;

        if (diff < minute) return 'Just now'; //shows when diff less than 60 seconds
        if (diff < twoMinutes) return 'A minute ago'; //shows when diff less than 120 seconds
        if (diff < (minute * 60)) {
            let minutes = Math.floor(diff / minute);
            return `${minutes} minutes ago`;
        }
        if (diff < twoHours) return 'An hour ago'; //shows when diff less than 120 minutes
        if (diff < (hour * 12)) {
            let hours = Math.floor(diff / hour);
            return `${hours} hours ago`;
        }
        if (diff < day) return 'Today';
        if (diff < (day * 2)) return 'Yesterday';
        if (diff < week) {
            let days = Math.floor(diff / day);
            return `${days} days ago`;
        }
        // if (diff < week) return 'This week';
        if (diff < twoWeeks) return 'A week ago';
        if (diff < month) {
            let weeks = Math.floor(diff / week);
            return `${weeks} weeks ago`;
        }
        // if (diff < month) return 'This month';
        if (diff < twoMonths) return 'A month ago';
        if (diff < year) {
            let months = Math.floor(diff / month);
            return `${months} months ago`;
        }
        if (diff < twoYears) return 'A year ago';
        if (diff < decade) {
            let years = Math.floor(diff / year);
            return `${years} years ago`;
        }
        // if (diff < year) return 'This year';
        return this.timestamp.toDateString();
    }
}

export class PostV2Factory implements Factory {
    create: () => PostV2 = PostV2Factory.createNewPost;
    createMock: () => PostV2 = PostV2Factory.createMockPost;
    createMockArray: (n: number) => PostV2[] = PostV2Factory.createMockPostArray;
    createFromHttp: (response: any) => PostV2 = PostV2Factory.createNewPostFromData;
    createArrayFromHttp: (response: any) => PostV2[] = PostV2Factory.createNewPostArrayFromData;
    static shared = new PostV2Factory();
    static createNewPost(): PostV2 {
        return new PostV2();
    }
    static createMockPost(): PostV2 {
        return new PostV2(
            undefined,
            undefined,
            "I am a mock post",
            [CommonUrls.randomPhoto],
            new Date(),
            generateRandomNumber(3)
        );
    }
    static createMockPostArray(n: number): PostV2[] {
        let posts: PostV2[] = [];
        for (let i = 0; i < n; i++) {
            posts.push(PostV2Factory.createMockPost());
        }
        return posts;
    }

    // http response data to model
    static createNewPostFromData(response: any): PostV2 {
        if(response.status !== 200) {
            return new PostV2();
        }
        let data = response.data;
        return new PostV2(
            data?.postId || "",
            data?.userId || "",
            data?.textContent || "",
            data?.mediaUrls || [],
            timestampToDate(data?.timestamp as string) || new Date(),
            // new Date(data?.timestamp || new Date()),
            data?.hypescore || 0
        );
    }
    static createNewPostArrayFromData(response: any): PostV2[] {
        if(response.status !== 200) {
            console.log('Error: ', response?.statusMessage || "Unknown error");
            return [];
        }
        let data = response.data.posts || response.data.myPosts || response.data.feedPosts || [];
        let posts: PostV2[] = data.map((post: any) => {
            var mediaUrls = post?.media.map((media: any) => {
                return media.location;
            });
            return new PostV2(
                post?.postId || "",
                post?.userId || "",
                post?.textContent || "",
                mediaUrls || [],
                timestampToDate(post?.timestamp as string) || new Date(),
                // new Date(post?.timestamp || new Date()),
                post?.hypescore || 0,
                post?.isLiked || false
            );
        });
        console.log('Posts:', posts);
        return posts;
    }
}
