import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapFactory } from 'src/app/Models/Map.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private readonly partySearchUrl = `${environment.apiBaseUrl}/maps-api/v1/parties/search`;

  constructor(private http: HttpClient) { }

  async searchParties(pageNumber: number, lat: number, lng: number, radius: number) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);

    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      },
      userLocation: {
        lat: lat,
        lng: lng
      },
      radius: radius
    };

    let response = await this.http.post(this.partySearchUrl, body, { headers: headers }).toPromise();
    return MapFactory.shared.createArrayFromHttp(response);
  }
}
