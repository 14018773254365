<div class="footer-container">
    <div class="content-controls-container">
        <button class="icon-container btn">
            <!-- <ion-icon name="heart-empty"></ion-icon> -->
            <!-- <hype-ion-icon-wrapper [iconName]="'heart-empty'"></hype-ion-icon-wrapper> -->
            <hype-ion-icon-wrapper 
                [iconName]="(post.isLiked)?'heart':'heart-empty'" 
                [ngClass]="{'heart':true,'liked':post.isLiked}" 
                [iconSize]="iconSize"
                (click)="likePostButtonClicked(post)"
            ></hype-ion-icon-wrapper>
            <h2>{{post.hypescore}}</h2>
        </button>
        <!-- <button class="icon-container btn">
            <ion-icon name="chatbubbles"></ion-icon>
        </button> -->
        <button class="icon-container btn" *ngIf="false">
            <!-- <ion-icon name="bookmark"></ion-icon> -->
            <hype-ion-icon-wrapper [iconName]="'bookmark'"></hype-ion-icon-wrapper>
        </button>
        <button class="icon-container btn" *ngIf="false">
            <!-- <ion-icon name="pricetag"></ion-icon> -->
            <hype-ion-icon-wrapper [iconName]="'pricetag'"></hype-ion-icon-wrapper>
        </button>
        <button class="icon-container btn">
            <!-- <ion-icon name="share-alt"></ion-icon> -->
            <hype-ion-icon-wrapper [iconName]="'share-alt'" [iconSize]="iconSize"></hype-ion-icon-wrapper>
        </button>
    </div>
    <div class="description-container" *ngIf="false">
        <p class="description">{{"This is a description"}}</p>
    </div>
    <div class="view-comments-container">
        <button class="view-comments-button" (click)="showComments()">
            <p class="view-comments-text">View Comments</p>
        </button>
    </div>
</div>
<hype-overlay [(display)]="showingAddMenu">
    <hype-feed-post-comments [postId]="postId" [(display)]="showingAddMenu"></hype-feed-post-comments>
</hype-overlay>