import { Component } from '@angular/core';

@Component({
  selector: 'hype-feed-post-follow-button',
  templateUrl: './feed-post-follow-button.component.html',
  styleUrls: ['./feed-post-follow-button.component.scss']
})
export class FeedPostFollowButtonComponent {
  constructor(
    
  ) { }
  followButtonClicked() {
    console.log('Follow button clicked');
  }
}
