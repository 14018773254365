import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message, MessageFactory } from 'src/app/Models/Message.model';
import { MessagesService } from 'src/app/Services/Messages/messages.service';
import { UserManagementService } from 'src/app/Services/user-management.service';

@Pipe({ name: 'reverse' })
export class ReversePipe implements PipeTransform {
  transform(value: any) {
    console.log(value);
    return value.slice().reverse();
  }
}
@Component({
  selector: 'hype-conversation-messages',
  templateUrl: './conversation-messages.component.html',
  styleUrl: './conversation-messages.component.scss'
})
export class ConversationMessagesComponent implements OnInit, OnDestroy, OnChanges {
  private manageSubscription: Subscription = new Subscription();
  profileImage: string = '';
  messages: Message[] = [];
  messageGroups: Message[][] = [];
  myUserId: string = '';
  showingGroupMembersMenu: boolean = false;
  newMessage: string = '';

  @Input() conversationId: string = "";
  @Input() display: boolean = false;
  @Output() displayChange = new EventEmitter<boolean>();
  
  constructor(
    private messagesService: MessagesService, 
    private userService: UserManagementService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    await this.userService.getMyUser().then(response => {
      this.profileImage = response.profilePicture;
      this.myUserId = response.userId;
    })

    if (this.conversationId !== '') {
      this.messagesService.connect(this.conversationId);

      this.manageSubscription = this.messagesService.messagesObservable.subscribe((message: any) => {
        // console.log('Message received:', message);
        // this.messages.push(message);
        this.cdr.detectChanges();
      });
      this.messagesService.getMessages().subscribe((message: string) => {
        // console.log('Message received:', message);
        this.onRecieveMessage(message);
        // this.messages.push(message);
        // this.cdr.detectChanges();
      })
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['conversationId'] && !changes['conversationId'].isFirstChange()) {
      this.messages = await this.messagesService.getConversationMessages(this.conversationId, 1);
      this.messages = this.messages.reverse();
    }

    // If the conversationId changes, we need to subscribe to the new conversation and unsubscribe from the old one
    if (changes['conversationId'] && !changes['conversationId'].isFirstChange()) {
      this.messagesService.disconnect();
      this.messagesService.connect(this.conversationId);

      this.manageSubscription.unsubscribe();
      this.manageSubscription = this.messagesService.messagesObservable.subscribe((message: any) => {
        // console.log('Message received:', message);
        this.onRecieveMessage(message);
        this.cdr.detectChanges();
      });
      
    } else if (changes['conversationId'] && changes['conversationId'].isFirstChange()) {
      this.manageSubscription = this.messagesService.messagesObservable.subscribe((message: any) => {
        // console.log('Message received:', message);
        this.onRecieveMessage(message);
        this.cdr.detectChanges();
      });
    }
  }

  ngOnDestroy() {
    this.manageSubscription.unsubscribe();
  }

  showGroupMembers() {
    this.showingGroupMembersMenu = !this.showingGroupMembersMenu;
  }

  addMessage() {
    if (this.newMessage.trim() !== '') {
      this.messagesService.sendMessage(this.newMessage);
      this.newMessage = '';
    }
    // console.log(this.messages);
  }

  onRecieveMessage(message: any) {
    // console.log('onRecieveMessage:', message); 
    let newMessage = MessageFactory.createNewMessageFromSocketData(message);
    if (
      newMessage?.author?.userId === '' || 
      newMessage?.author?.userId === null || 
      newMessage?.author?.userId === undefined
    ) {
      return;
    }
    // console.log('newMessage: ', newMessage);
    this.messages.push(newMessage);
    // console.log(this.messages);
  }

  identify(index: number, item: Message) {
    return item.messageId;
  }

  // Messages Logic
}

