import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PostFactory, PostV2Factory } from 'src/app/Models/Post.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedService {
  // private readonly baseUrl = "https://api.hypetrain.online/users/test-feed"; // API Gateway
  private readonly testUrl = `${environment.apiBaseUrl}/users/test-feed`; // API Gateway
  private readonly baseUrl = `${environment.apiBaseUrl}/core-api/v2/users/live-feed`; // API Gateway
  // private readonly testUrl = "http://localhost:3000/users/test-feed";
  constructor(
    private http: HttpClient,
  ) { }

  async getTestFeed(): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 10,
        pageNumber: 1,
      }
    }
    let response = await this.http.post(this.testUrl, body, { headers: headers }).toPromise();
    // console.log(response);
    return PostFactory.shared.createArrayFromHttp(response);
  }

  async getLiveFeed(pageNumber: number): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      }
    }
    let response = await this.http.post(this.baseUrl, body, { headers: headers }).toPromise();
    console.log(response);
    return PostV2Factory.shared.createArrayFromHttp(response);
    // return PostFactory.shared.createArrayFromHttp(response);
  }
}
