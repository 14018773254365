import { Component } from '@angular/core';

@Component({
  selector: 'hype-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent {
  selectedConversationId: string = '';

  onConversationIdChange(newConversationId: string) {
    this.selectedConversationId = newConversationId;
  }
}
