import { Injectable } from '@angular/core';
import { UserManagementService } from '../user-management.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // private readonly baseUrl = "https://api.hypetrain.online/authentication/login";
  // private readonly logoutUrl = "https://api.hypetrain.online/authentication/logout";
  private readonly baseUrl = `${environment.apiBaseUrl}/authentication/login`;
  private readonly logoutUrl = `${environment.apiBaseUrl}/authentication/logout`;
  isLoggedIn: boolean = false;
  // userId: string = '';
  // sessionId: string = '';
  constructor(
    private http: HttpClient,
    private userManagementService: UserManagementService,
  ) { }

  async submitLogin(username: string, password: string): Promise<any> {
    let body = {
      email: username,
      password: password
    }
    let response = await this.http.post(this.baseUrl, body).toPromise() as any;
    console.log(response);
    if (response?.statusMessage !== 'Success!') {
      console.log("No success!!!!")
      return {
        userId: undefined,
        sessionId: undefined,
      };
    }
    try {
      //let myUser = await this.userManagementService.getMyUser();
      let sessionId = response.sessionId;
      let userId = response.userId;
      console.log(`User ID: ${userId}, Session ID: ${sessionId}`);
      this.isLoggedIn = true;
      localStorage.setItem('userId', userId);
      localStorage.setItem('sessionId', sessionId);
      await this.userManagementService.getMyUser(userId, sessionId);
      console.log('User:', this.userManagementService.myUser);
      return {
        userId: userId,
        sessionId: sessionId,
      };
    } catch (error) {
      console.log("We got an error!");
      console.error(error);
      return {
        userId: undefined,
        sessionId: undefined,
      };
    }
  }

  async logout(): Promise<boolean> {
    let userId = localStorage.getItem('userId') || '';
    let sessionId = localStorage.getItem('sessionId') || '';
    let headers = {
      'authorization': sessionId,
      'X-UserId': userId,
    }
    let response = await this.http.post(this.logoutUrl, {}, { headers: headers }).toPromise() as any;
    console.log(response);
    if (response?.statusMessage !== 'Logout Successful!') {
      console.log("No success!!!!")
      return false;
    }
    localStorage.removeItem('userId');
    localStorage.removeItem('sessionId');
    this.userManagementService.myUser = undefined;
    this.isLoggedIn = false;
    return true;
  }

  async submitSignup(username: string, password: string, email: string, firstName: string, lastName: string, userhandle: string): Promise<any> {
    let body = {
      email: email,
      password: password,
      username: username,
      firstName: firstName,
      lastName: lastName,
      userhandle: userhandle,
    }
    let response = await this.http.post(this.baseUrl, body).toPromise() as any;
    console.log(response);
    if (response?.statusMessage !== 'Success!') {
      console.log("No success!!!!")
      return {
        userId: undefined,
        sessionId: undefined,
      };
    }
    try {
      let sessionId = response.sessionId;
      let userId = response.userId;
      console.log(`User ID: ${userId}, Session ID: ${sessionId}`);
      this.isLoggedIn = true;
      localStorage.setItem('userId', userId);
      localStorage.setItem('sessionId', sessionId);
      await this.userManagementService.getMyUser(userId, sessionId);
      console.log('User:', this.userManagementService.myUser);
      return {
        userId: userId,
        sessionId: sessionId,
      };
    } catch (error) {
      console.log("We got an error!");
      console.error(error);
      return {
        userId: undefined,
        sessionId: undefined,
      };
    }
  }
}
