<section class="comment-section-wrapper">
    <div class="comment-section-container">
        <div class="comment-section-header">
            <div class="header-left"></div>
            <div class="header-center">{{numComments || 0}} Comments</div>
            <div class="header-right">
                <button class="close-button" (click)="closeComments()">X</button>
            </div>
        </div>
        <div class="comments-container" infinite-scroll (scrolled)="handleScroll()" [scrollWindow]="false">
            <div class="comment-container" *ngFor="let comment of comments">
                <hype-s-feed-post-comments (commentDeletedEvent)="updateCommentArray($event)" [authorId]="comment.authorId" [comment]="comment"></hype-s-feed-post-comments>
            </div>
        </div>
        <div class="comment-section-footer-container">
            <div class="left-comment-footer-container"> 
                <hype-s-profile-picture-custom [size]="'40px'" [borderSize]="'1px'" [url]="user.profilePicture"></hype-s-profile-picture-custom>
            </div>
            <div class="right-comment-footer-container">
                <form class="create-comment-box">
                    <textarea 
                        class="comment-creation-input" 
                        placeholder="Add comment"
                        [formControl]="commentText"
                    ></textarea>
                </form>
                <button class="send-comment-button" [disabled]="commentText.invalid" id="send-button" (click)="sendComment()">
                    <hype-ion-icon-wrapper [iconName]="'send'"></hype-ion-icon-wrapper>
                </button>
            </div>
        </div>
    </div>
</section>