<section class="party-management-wrapper">
    <div class="party-management-container">
        <div class="party-management-header-container">
            <h1>Party Management</h1>
        </div>
        <section class="party-categories-wrapper">
            <!-- Followed Parties -->
            <div class="category-container">
                <div class="category-header">
                    <h3>Followed Parties</h3>
                    <button (click)="viewAllFollowedParties()">View All</button>
                </div>
                <div class="category-content">
                    <hype-party-list-row [previewParties]="followedParties"></hype-party-list-row>
                </div>
            </div>
            <!-- Upcoming Parties -->
            <div class="category-container">
                <div class="category-header">
                    <h3>Upcoming Parties</h3>
                    <button (click)="viewAllUpcomingParties()">View All</button>
                </div>
                <div class="category-content">
                    <hype-party-list-row [previewParties]="upcomingParties"></hype-party-list-row>
                </div>
            </div>
            <!-- Recent Parties -->
            <div class="category-container">
                <div class="category-header">
                    <h3>Recent Parties</h3>
                    <button (click)="viewAllRecentParties()">View All</button>
                </div>
                <div class="category-content">
                    <hype-party-list-row [previewParties]="pastParties"></hype-party-list-row>
                </div>
            </div>
            <!-- Your Parties -->
            <div class="category-container">
                <div class="category-header">
                    <h3>Your Parties</h3>
                    <button (click)="viewAllMyParties()">View All</button>
                </div>
                <div class="category-content">
                    <hype-party-list-row [previewParties]="myParties"></hype-party-list-row>
                </div>
            </div>
        </section>
        <button class="new-party-button" (click)="goToPartyCreation()">Host Party</button>
    </div>
</section>