import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommentV2Factory } from 'src/app/Models/Comment.model';
import { MyPostV2Factory, PostFactory, PostV2Factory } from 'src/app/Models/Post.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  private readonly userPostsBaseUrl = `${environment.apiBaseUrl}/core-api/v2/users/user-posts`;
  private readonly myUserPostsBaseUrl = `${environment.apiBaseUrl}/core-api/v2/users/my-posts`;
  private readonly likePostBaseUrl = `${environment.apiBaseUrl}/core-api/v2/posts/likes`;
  private readonly postCommentsBaseUrl = `${environment.apiBaseUrl}/core-api/v2/posts/comments`;
  
  constructor(
    private http: HttpClient,
  ) { }

  async getMyPosts(pageNumber: number): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    // headers.append('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      }
    }
    let response = await this.http.post(this.myUserPostsBaseUrl, body, { headers: headers }).toPromise();
    // console.log(response);
    return MyPostV2Factory.shared.createArrayFromHttp(response);
  }

  async getUserPosts(userId: string, pageNumber: number): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    // headers.append('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      },
      userId: userId
    }
    let response = await this.http.post(this.userPostsBaseUrl, body, { headers: headers }).toPromise();
    // console.log(response);
    return PostV2Factory.shared.createArrayFromHttp(response);
    // return PostFactory.shared.createArrayFromHttp(response);
  }

  async createPost(post: FormData): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    const headers = new HttpHeaders({
      'authorization': `${sessionToken}`,
      'X-UserId': myUserId,
    });
    let response = await this.http.put(this.myUserPostsBaseUrl, post, { headers }).toPromise();
    // console.log(response);
    return response;
  }

  async deletePost(postId: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    const headers = new HttpHeaders({
      'authorization': `${sessionToken}`,
      'X-UserId': myUserId,
    });
    const body = {
      postId: postId
    }
    let response = await this.http.delete(this.myUserPostsBaseUrl, { headers: headers, body: body }).toPromise();
    // console.log(response);
    return response;
  }

  async likePost(postId: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    const headers = new HttpHeaders({
      'authorization': `${sessionToken}`,
      'X-UserId': myUserId,
    });
    const body = {
      postId: postId
    }
    let response = await this.http.put(this.likePostBaseUrl, body, { headers: headers }).toPromise();
    // console.log(response);
    return response;
  }

  async unlikePost(postId: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    const headers = new HttpHeaders({
      'authorization': `${sessionToken}`,
      'X-UserId': myUserId,
    });
    const body = {
      postId: postId
    }
    let response = await this.http.delete(this.likePostBaseUrl, { headers: headers, body: body }).toPromise();
    // console.log(response);
    return response;
  }

  async getPostComments(postId: string, pageNumber: number): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 10,
        pageNumber: pageNumber,
      },
      postId: postId
    }
    
    let response = await this.http.post(this.postCommentsBaseUrl, body, { headers: headers }).toPromise();
    return [response, CommentV2Factory.shared.createArrayFromHttp(response)];
  }

  async createComment(postId: string, commentText: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      newComment: {
        text: commentText
      },
      postId: postId
    }
    
    let response = await this.http.put(this.postCommentsBaseUrl, body, { headers: headers }).toPromise();
    return response;
  }

  async deleteComment(postId: string, commentId: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    const headers = new HttpHeaders({
      'authorization': `${sessionToken}`,
      'X-UserId': myUserId,
    });
    const body = {
      postId: postId,
      commentId: commentId
    }
    
    let response = await this.http.delete(this.postCommentsBaseUrl, { headers: headers, body: body }).toPromise();
    return response;
  }
}