import { Component } from '@angular/core';
import { PreferencesService } from 'src/app/Services/Preferences/preferences.service';

@Component({
  selector: 'hype-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  selectedTheme = this.preferencesService.getPreferences().theme || 'default';
  response: any = '';

  constructor(
    private preferencesService: PreferencesService,
  ) {}

  async ngOnInit() {
    this.response = await this.preferencesService.getMyPreferences().then(response => {
      console.log('preferences response', response)
    }).catch(response => {
      console.log('preferences response error', response)
    })
  }

  toggleLightMode() {
    this.preferencesService.setTheme('default');
    this.selectedTheme = 'default';
  }

  toggleDimMode() {
    this.preferencesService.setTheme('dim');
    this.selectedTheme = 'dim';
  }
  
  toggleGrayMode() {
    this.preferencesService.setTheme('gray');
    this.selectedTheme = 'gray';
  }

  toggleDarkMode() {
    this.preferencesService.setTheme('dark');
    this.selectedTheme = 'dark';
  } 

  saveTheme() {
    this.preferencesService.updateMyPreferences(this.selectedTheme).then(response => {
      console.log('updated!', response)
    }).catch(response => {
      console.log('failed to update', response)
    })
  }
}
