import { Factory, generateRandomFirstName, generateRandomLastName, generateRandomNumber, generateRandomUsername, getRandomSquareImage } from "../MockData/CommonMockData";

export interface Member {
    userId: string;
    username: string;
    userhandle: string;
    profilePicture: string;
    numFollowers: number;
    numFollowings: number;
    hypescore?: number;
    isFollowing?: boolean | null;
}

export class Member implements Member {
    constructor(id?: string, username?: string, userhandle?: string, profilePicture?: string, numFollowers?: number, numFollowings?: number, hypescore?: number, isFollowing?: boolean) {
        this.userId = id || "";
        this.username = username || "";
        this.userhandle = userhandle || "";
        this.profilePicture = profilePicture || "";
        this.numFollowers = numFollowers || 0;
        this.numFollowings = numFollowings || 0;
        this.hypescore = hypescore || 0;
        this.isFollowing = isFollowing || null;
    }
}

export class MemberFactory implements Factory {
    create: () => Member = MemberFactory.createNewMember;
    createMock: () => Member = MemberFactory.createMockMember;
    createMockArray: (n: number) => any[] = MemberFactory.createMockMemberArray;
    createFromHttp: (response: any) => Member = MemberFactory.createNewMemberFromData;
    createArrayFromHttp: (response: any) => Member[] = MemberFactory.createNewMemberArrayFromData;

    static shared = new MemberFactory();
    
    static createNewMember(): Member {
        return new Member();
    }

    static createMockMember(): Member {
        return new Member(
            undefined, 
            generateRandomUsername(),
            generateRandomFirstName() + '_' + generateRandomLastName(),
            getRandomSquareImage(),
            generateRandomNumber(2), 
            generateRandomNumber(2),
            generateRandomNumber(3),
            false
        );
    }
    static createMockMemberArray(n: number): Member[] {
        let members: Member[] = [];
        for (let i = 0; i < n; i++) {
            members.push(MemberFactory.createMockMember());
        }
        return members;
    }
    static createNewMemberFromData(response: any): Member {
        if(response.status !== 200) {
            return new Member();
        }
        let data = response.data.myUser;
        return new Member(
            data?.userId || "",
            data.username,
            data?.userhandle || "",
            data.profileImage.url,
            data.numFollowers,
            data.numFollowings,
            data.hypescore || 0,
            data?.isFollowing,
        );
    }

    static createNewMemberArrayFromData(response: any): Member[] {
        if(response.status !== 200) {
            return [new Member()];
        }

        var property = '';
        if (response.data?.["members"]) property = "members";
        else if (response.data?.["mutualFollowings"]) property = "mutualFollowings";

        let data = response.data?.[property];

        let membersArray = data;
        if (!membersArray) return [new Member()];
        let members: Member[] = [];
        members = membersArray.map((member: any) => {
            return new Member(
                member?.userId || "",
                member.username,
                member?.userhandle || "",
                member?.profileImage?.url || "",
                member.numFollowers,
                member.numFollowings,
                member.hypescore,
                member?.isFollowing
            );
        });
        return members;
    }
}