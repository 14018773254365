import { Component, Input } from '@angular/core';
import { PartyV2, PartyV2Factory } from 'src/app/Models/Party.model';
import { PartyService } from 'src/app/Services/Party/party.service';

@Component({
  selector: 'hype-party-grid',
  templateUrl: './party-grid.component.html',
  styleUrls: ['./party-grid.component.scss']
})
export class PartyGridComponent {
  @Input() parties: PartyV2[] = PartyV2Factory.shared.createMockArray(0);
  @Input() listType: string = '';
  @Input() userId: string = '';
  showingPartyDetails: boolean = false;
  selectedParty: PartyV2 = PartyV2Factory.shared.createMock();
  pageNumber: number = 1;

  constructor(
    private partyService: PartyService,
  ) { }
  
  ngOnInit() {
    this.handleScroll();
  }

  showPartyDetails(party: PartyV2) {
    this.selectedParty = party;
    this.showingPartyDetails = true;
  }
  
  closePartyDetails() {
    this.showingPartyDetails = false;
  }
    
  async handleScroll() {
    if(this.listType === 'My') {
      this.parties = this.parties.concat(await this.partyService.getMyParties(this.pageNumber));
    } else if(this.listType === 'Followed') {
      this.parties = this.parties.concat(await this.partyService.getMyFollowedParties(this.pageNumber));
    } else if (this.listType === 'Recent') {
      this.parties = this.parties.concat(await this.partyService.getMyPastParties(this.pageNumber));
    } else if (this.listType === 'Upcoming') {
      this.parties = this.parties.concat(await this.partyService.getMyUpcomingParties(this.pageNumber));
    } else if (this.listType === 'User') {
      this.parties = this.parties.concat(await this.partyService.getPartiesByUserId(this.userId, this.pageNumber)); // pagination needs to be fixed for this to work properly
    }
    this.pageNumber++;
  }
}
