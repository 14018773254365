import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PartyV2Factory } from 'src/app/Models/Party.model';
import { User } from 'src/app/Models/User.model';

@Injectable({
  providedIn: 'root'
})


export class PartyService {
  private readonly myPartiesUrl = `${environment.apiBaseUrl}/core-api/v2/users/my-parties`;
  private readonly myFollowedPartiesUrl = `${environment.apiBaseUrl}/core-api/v2/users/my-followed-parties`;
  private readonly myUpcomingPartiesUrl = `${environment.apiBaseUrl}/core-api/v2/users/my-upcoming-parties`;
  private readonly myPastPartiesUrl = `${environment.apiBaseUrl}/core-api/v2/users/my-past-parties`;
  private readonly partiesByUserIdUrl = `${environment.apiBaseUrl}/core-api/v2/users/user-parties`;

  constructor(private http: HttpClient) { }

  async getMyParties(pageNumber: number) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);

    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      }
    };

    let response = await this.http.post(this.myPartiesUrl, body, { headers: headers }).toPromise();
    return PartyV2Factory.shared.createArrayFromHttp(response);
  };

  
  async createParty(party: FormData): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    const headers = new HttpHeaders({
      'authorization': `${sessionToken}`,
      'X-UserId': myUserId,
    });
    let response = await this.http.put(this.myPartiesUrl, party, { headers }).toPromise();
    console.log('create party', response);
    return response;
  };

  async deleteParty(partyId: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    const headers = new HttpHeaders({
      'authorization': `${sessionToken}`,
      'X-UserId': myUserId,
    });
    const body = {
      partyId: partyId
    };
    let response = await this.http.delete(this.myPartiesUrl, {headers: headers, body: body}).toPromise();
    return response
  };

  async getMyFollowedParties(pageNumber: number) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);

    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      }
    };

    let response = await this.http.post(this.myFollowedPartiesUrl, body, { headers: headers }).toPromise();
    return PartyV2Factory.shared.createArrayFromHttp(response);
  };

  async getMyUpcomingParties(pageNumber: number) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);

    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      }
    };

    let response = await this.http.post(this.myUpcomingPartiesUrl, body, { headers: headers }).toPromise();
    return PartyV2Factory.shared.createArrayFromHttp(response);
  };

  async getMyPastParties(pageNumber: number) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);

    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      }
    };

    let response = await this.http.post(this.myPastPartiesUrl, body, { headers: headers }).toPromise();
    return PartyV2Factory.shared.createArrayFromHttp(response);
  };

  async getPartiesByUserId(userId: string, pageNumber: number) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);

    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      },
      userId: userId
    };

    let response = await this.http.post(this.partiesByUserIdUrl, body, { headers: headers }).toPromise();
    return PartyV2Factory.shared.createArrayFromHttp(response);
  };
}
