<div class="not-found-container">
    <div class="not-found-message">
        <h1 class="error-code">404</h1>
        <p class="error-message">{{notFoundText}} Not Found</p>
        <p class="error-description">{{notFoundDescription}}</p>
        <button class="not-found-button" (click)="rerouteUser()">
            <hype-ion-icon-wrapper [iconName]="notFoundIcon"></hype-ion-icon-wrapper> {{buttonText}}
        </button>
    </div>
    <div class="not-found-image">
        <img src="..\..\..\..\assets\train.png">
    </div>
</div>