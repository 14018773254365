import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Post, PostFactory, PostV2 } from 'src/app/Models/Post.model';
import { FeedService } from 'src/app/Services/Feed/feed.service';

@Component({
  selector: 'hype-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  posts = PostFactory.shared.createMockArray(5);
  feedPosts: PostV2[] = [];
  pageNumber: number = 1;

  constructor(
    private feedService: FeedService,
    private router: Router,
  ) { }
  async ngOnInit() {
    // this.posts = await PostFactory.shared.asyncCreateMockArray(5);
    this.handleScroll();
    this.posts = await this.feedService.getTestFeed();
    console.log('We got the Posts!!:', this.feedPosts);
    // console.log('We got the Posts!!:', this.posts);
    // console.log(this.posts);
    // this.posts = []; 
  }

  async followButtonClicked(){
    console.log('Follow button clicked');
  }

  async likeButtonClicked(){
    console.log('Like button clicked');
  }

  writePostButtonClicked(){
    // Navigate to write post page
    this.router.navigate(['/home/create-post']);
  }

  async handleScroll() {
    this.feedPosts = this.feedPosts.concat(await this.feedService.getLiveFeed(this.pageNumber)); // pagination needs to be fixed for this to work properly
    this.pageNumber++;
  }
}
