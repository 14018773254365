import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Conversation } from 'src/app/Models/Conversation.model';
import { ConversationsService } from 'src/app/Services/Conversations/conversations.service';

@Component({
    selector: 'hype-conversation-list',
    templateUrl: './conversation-list.component.html',
    styleUrl: './conversation-list.component.scss',
})

export class ConversationListComponent implements OnInit {
  conversations: Conversation[] = [];
  pageNumber: number = 1;
  selectedConversationId: string = '';
  displayMenu: boolean = false;
  
  @Output() conversationId = new EventEmitter<string>();
  @Input() display: boolean = false;
  @Output() displayChange = new EventEmitter<boolean>();
  
  constructor(private conversationService: ConversationsService ) {}

  ngOnInit() {
    this.handleScroll();
  }

  async handleScroll() {
    this.conversations = this.conversations.concat(await this.conversationService.getConversations(this.pageNumber));
    this.pageNumber++;
  }

  async selectConversation(id: string) {
    this.selectedConversationId = id;
    this.conversationId.emit(id);
    const selectedConversation = this.conversations.find(conversation => conversation.conversationId === id);
    if (selectedConversation && selectedConversation.unread) {
      await this.conversationService.updateConversations(id);
      selectedConversation.unread = false;
    }
  }

  checkSelected(id: string) {
    return id === this.selectedConversationId;
  }

  createConversation() {
    this.displayMenu = !this.displayMenu;
  }

  onConversationCreation(newConversation: Conversation) {
    this.conversations.unshift(newConversation);
    this.selectConversation(newConversation.conversationId);
  }
}
