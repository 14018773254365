<google-map 
  [options]="options"
  height="{{viewHeight}}px"
  width="100%"
  [center]="center"
  mapId="DEMO_MAP_ID" 
  (mapClick)="moveMap($event)"
  (mapMousemove)="move($event)" >
>
  <map-advanced-marker *ngFor="let party of parties; trackBy: trackByLocation" 
    [id]="party.partyId"
    [position]="party.location" 
    [content]="party.location.content"
    (mapClick)="onMarkerClick()"
  ></map-advanced-marker>
</google-map>