import { Component } from '@angular/core';
import { CommonUrls, getRandomSquareImage } from 'src/app/MockData/CommonMockData';

@Component({
  selector: 'hype-feed-post-content',
  templateUrl: './feed-post-content.component.html',
  styleUrls: ['./feed-post-content.component.scss']
})
export class FeedPostContentComponent {
  randomPhoto = getRandomSquareImage();
}

//https://picsum.photos/400