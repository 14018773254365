<div class="footer-container">
    <div class="content-controls-container">
        <button class="icon-container btn">
            <ion-icon name="heart-empty"></ion-icon>
        </button>
        <!-- <button class="icon-container btn">
            <ion-icon name="chatbubbles"></ion-icon>
        </button> -->
        <button class="icon-container btn">
            <ion-icon name="bookmark"></ion-icon>
        </button>
        <button class="icon-container btn">
            <ion-icon name="pricetag"></ion-icon>
        </button>
        <button class="icon-container btn">
            <ion-icon name="share-alt"></ion-icon>
        </button>
    </div>
    <div class="description-container">
        <p class="description">{{post.description || "This is a description"}}</p>
    </div>
    <div class="view-comments-container">
        <button class="view-comments-button" (click)="showComments(post)">
            <p class="view-comments-text">View Comments</p>
        </button>
    </div>
</div>
<hype-overlay [(display)]="showingAddMenu">
    <hype-feed-post-comments [postId]="postId" [(display)]="showingAddMenu"></hype-feed-post-comments>
</hype-overlay>