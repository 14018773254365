import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConversationFactory } from 'src/app/Models/Conversation.model';
import { MemberFactory } from 'src/app/Models/Members.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConversationsService {
  private readonly conversationsUrl = `${environment.apiBaseUrl}/core-api/v2/conversations`;
  

  constructor(private http: HttpClient) { }

  async getConversations(pageNumber: number) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);

    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      }
    };

    let response = await this.http.post(this.conversationsUrl, body, { headers: headers }).toPromise();
    return ConversationFactory.shared.createArrayFromHttp(response);
  }

  async updateConversations(conversationId: string) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);

    const body = {
    };

    let response = await this.http.patch(`${this.conversationsUrl}/${conversationId}/status?unread=false`, body, { headers: headers }).toPromise();
    return response;
  }

  async getConversationMembers(conversationId: string, pageNumber: number) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);

    const body = {
      pagination: {
        numRecords: 20,
        pageNumber: pageNumber,
      }
    };

    let response = await this.http.post(`${this.conversationsUrl}/${conversationId}/members`, body, { headers: headers }).toPromise();
    return MemberFactory.createNewMemberArrayFromData(response);
  }

  async createConversation(initialMessage: string, members: []) {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);

    const body = {
      message: initialMessage,
      members: members,
    };

    let response = await this.http.put(this.conversationsUrl, body, { headers: headers }).toPromise();
    return ConversationFactory.shared.createFromHttp(response);
  }
}
