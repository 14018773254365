import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'hype-ion-icon-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ion-icon-wrapper.component.html',
  styleUrls: ['./ion-icon-wrapper.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IonIconWrapperComponent implements AfterViewInit, OnInit {
  @Input() iconName: string = "rocket";
  @Input() iconSize: string = "1em";
  @ViewChild('icon') icon: any;
  constructor() { }
  ngOnInit(): void { }
  ngAfterViewInit(): void {
    if (this.icon) {
      this.icon.nativeElement.style.height = this.iconSize;
      this.icon.nativeElement.style.width = this.iconSize;
    }
  }
}
