import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserFactory } from '../Models/User.model';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  // readonly baseUrl = "https://api.hypetrain.online/users/my-user";
  readonly baseUrl = `${environment.apiBaseUrl}/users/my-user`;
  static readonly userUrl = `${environment.apiBaseUrl}/users/user`;
  readonly userhandleUrl = `${environment.apiBaseUrl}/core-api/v2/users/user`;
  readonly userProfileImageUrl = `${environment.apiBaseUrl}/core-api/v2/users/my-profile-image`; 
  readonly myUsernameUrl = `${environment.apiBaseUrl}/users/my-username`;
  readonly myDetailsUrl = `${environment.apiBaseUrl}/users/my-details`;
  readonly myBioUrl = `${environment.apiBaseUrl}/core-api/v2/users/my-biography`;
  // static readonly userUrl = "http://localhost:3000/users/user";
  userId = "";
  sessionToken = "";
  deviceId = "";

  myUser: any;
  static http: HttpClient;

  constructor(private http: HttpClient) { }

  async getMyUser(userId?: string, sessionId?: string): Promise<any> {
    if (this.myUser) return this.myUser;
    // let headers = new HttpHeaders().set('authorization', `${this.sessionToken}`);
    // console.log("Attempting to get from storage");
    let myUserId = localStorage.getItem('userId') || '';
    let mySessionId = localStorage.getItem('sessionId') || '';
    // console.log(myUserId, mySessionId);
    sessionId = mySessionId;
    userId = myUserId;
    let headers = new HttpHeaders().set('authorization', `${sessionId}`);
    // headers = headers.set('X-UserId', this.userId);
    headers = headers.set('X-UserId', userId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      // "userId": userId
    }
    let response = await this.http.post(this.baseUrl, body, { headers: headers }).toPromise();
    // console.log(response);
    this.myUser = UserFactory.shared.createFromHttp(response);
    return this.myUser;
  }

  async getUserByUserhandle(userhandle: string): Promise<any> {
    let headers = new HttpHeaders().set('authorization', `${this.sessionToken}`);
    let myUserId = localStorage.getItem('userId') || '';
    if (myUserId !== '') {
      headers = headers.set('X-UserId', myUserId);
    }
    headers = headers.set('Content-Type', 'application/json');
    // console.log(headers);
    const body = {
      "userhandle": userhandle
    }
    // let response = await this.http.post(this.baseUrl, body, { headers: headers }).toPromise();
    let response = await this.http.get(`${this.userhandleUrl}/${userhandle}`, { headers: headers }).toPromise();
    console.log(response);
    return UserFactory.shared.createArrayFromHttp(response);
  }

  async getUserByUserId(userId: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    this.sessionToken = sessionToken;
    this.userId = myUserId = myUserId;
    let headers = new HttpHeaders().set('authorization', `${this.sessionToken}`);
    headers = headers.set('X-UserId', this.userId);
    headers = headers.set('Content-Type', 'application/json');
    // console.log(headers);
    const body = {
      "userIds": [userId]
    }
    let response = await this.http.post(UserManagementService.userUrl, body, { headers: headers }).toPromise();
    // console.log(response);
    return UserFactory.shared.createArrayFromHttp(response);
    //return UserFactory.shared.createFromHttp(response);
  }

  static async getUserByUserId(userId: string): Promise<any> {
    
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    // console.log(headers);
    const body = {
      "userIds": [userId]
    }
    let response = await this.http.post(this.userUrl, body, { headers: headers }).toPromise();
    // console.log(response);
    return UserFactory.shared.createArrayFromHttp(response);
    //return UserFactory.shared.createFromHttp(response);
  }

  async updateProfileImage(profileImage: FormData): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    let response = await this.http.put(this.userProfileImageUrl, profileImage, { headers }).toPromise();
    return response;
  }
  async updateDisplayName(userId: string, username: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    const body = {
      "userId": userId,
      "username": username,
    }
    let response = await this.http.put(this.myUsernameUrl, body, { headers }).toPromise();
    return response;
  }
  async updateUserDetails(userId: string, firstName: string, lastName: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    const body = {
      "userId": userId,
      "firstName": firstName,
      "lastName": lastName
    }
    let response = await this.http.put(this.myDetailsUrl, body, { headers }).toPromise();
    return response;
  }
  async updateUserBio(bio: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    const body = {
      "content": bio
    }
    let response = await this.http.patch(this.myBioUrl, body, { headers: headers }).toPromise();
    return response;
  }

  generateDeviceId(): string {
    let deviceId = 'web';
    deviceId = `${deviceId}--${uuidv4()}`;
    return deviceId;
  }
}