<div class="comment-container">
    <div class="left-comment-container"> 
        <hype-s-profile-picture-custom [size]="'40px'" [borderSize]="'1px'" [url]="commentAuthor[0].profilePicture"></hype-s-profile-picture-custom>
    </div>
    <div class="right-comment-container">
        <div class="comment-header-container">
            <div class="comment-header-username-container">
                <p>{{ commentAuthor[0].username }}</p> 
            </div>
            <div class="comment-header-datecreated-container">
                <p class="gray">• {{ comment.getDateString() }}</p>
            </div>
            <div class="dropdown-menu-button-container">
                <h2 class="dropdown-menu-button" (click)="toggleDropdownMenu(comment.authorId, $event)">...</h2>
                <div class="dropdown-menu">
                    <div class="dropdown-menu-item" (click)="deleteComment(comment.postId, comment.commentId, $event)">
                        <hype-ion-icon-wrapper [iconName]="'trash'" style="color: rgb(182, 27, 27);"></hype-ion-icon-wrapper>
                        <h4>Delete Comment</h4>
                    </div>
                </div>
                <div class="dropdown-menu">
                    <div class="dropdown-menu-item" (click)="reportComment(comment.commentId)">
                        <hype-ion-icon-wrapper [iconName]="'flag'" style="color: rgb(182, 27, 27);"></hype-ion-icon-wrapper>
                        <h4>Report</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="comment-text-container">
            <p>{{ comment.text }}</p>
        </div>
        <div class="comment-footer-container">
            <div class="footer-buttons-container">
                <div class="footer-reply-container">
                    <button id="simple-button" class="gray" (click)="createReply()">{{ createReplyText }}</button>
                </div>
                <div class="footer-view-replies-container">
                    <button id="simple-button" class="gray" (click)="showReplies()">{{ repliesText }}</button>
                </div>
                <div class="footer-comment-likebutton-container"(click)="likeComment(comment)" >
                    <hype-ion-icon-wrapper [iconName]="(comment.isLiked)?'heart':'heart-empty'" [ngClass]="{'heart':true,'liked':comment.isLiked}"></hype-ion-icon-wrapper>
                </div> 
                <div class="footer-comment-likescount-container">
                    <p class="gray">{{ comment.numLikes }}</p> 
                </div>
            </div>
            <div class="replies-container">
                <div class="create-reply-container" *ngIf="showCreateReplyContainer">
                    <div class="right-comment-footer-container">
                        <form class="create-comment-box">
                            <textarea 
                                class="comment-creation-input" 
                                placeholder="Add comment"
                                [formControl]="commentReplyText"
                            ></textarea>
                        </form>
                        <button class="send-comment-button" [disabled]="commentReplyText.invalid" id="send-button" (click)="sendCommentReply()">
                            <hype-ion-icon-wrapper [iconName]="'send'"></hype-ion-icon-wrapper>
                        </button>
                    </div>
                </div>
                <div class="comment-replies-container" *ngIf="showCommentRepliesContainer">
                    <div class="comment-reply" *ngFor="let reply of commentReplies">
                        <div class="left-comment-reply-container"> 
                            <hype-s-profile-picture-custom [size]="'25px'" [borderSize]="'1px'" [url]=""></hype-s-profile-picture-custom>
                        </div>
                        <div class="right-comment-reply-container">
                            <div class="comment-reply-header-container">
                                <div class="comment-reply-username-container">
                                    <p>{{ reply.authorId }}</p> 
                                </div>
                                <div class="comment-reply-datecreated-container">
                                    <p class="gray">• {{ reply.getDateString() }}</p>
                                </div>
                            </div>
                            <div class="comment-reply-body-container">
                                <p>{{ reply.text }}</p>
                                <div class="comment-reply-likebutton-container"(click)="likeComment(reply)" >
                                    <hype-ion-icon-wrapper [iconName]="(reply.isLiked)?'heart':'heart-empty'" [ngClass]="{'heart':true,'liked':reply.isLiked}"></hype-ion-icon-wrapper>
                                </div> 
                                <div class="comment-reply-likescount-container">
                                    <p class="gray">{{ reply.numLikes }}</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<hype-overlay [(display)]="showingDeleteConfirmation" [disableCloseOnClick]="true">
    <div class="confirmation-menu-container">
        <div class="text-container">
            <h4>Are you sure you want to delete this comment?</h4>
        </div>
        <div class="button-container">
            <button class="cancelDeleteButton" (click)="deleteCancelled()">Cancel</button>
            <button class="confirmDeleteButton" (click)="deleteConfirmed()">Delete</button>
        </div>
    </div>
</hype-overlay>