<div class="header-wrapper" [ngClass]="{'solid':solidHeader}" >
    <div class="header-container">
        <div class="header-left">
            <div class="logo">
                <img src="../../../../assets/logo1.png" alt="logo" (click)="navigateToHome()">
            </div>
        </div>
        <div class="header-right">
            <div class="settings-container" (click)="navigateToSettings()" *ngIf="hideLogout">
                <hype-ion-icon-wrapper [iconName]="'settings'"></hype-ion-icon-wrapper>
            </div>
            <button id="signin" *ngIf="!hideRightNav" (click)="navigateToSignIn()">Sign In</button>
            <button id="signup" *ngIf="!hideRightNav" (click)="navigateToSignUp()">Sign Up</button>
            <button id="logout" *ngIf="hideLogout" (click)="logout()">Logout</button>
        </div>
    </div>
</div>