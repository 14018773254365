import { Component } from '@angular/core';

@Component({
  selector: 'hype-see-all-upcoming-parties',
  templateUrl: './see-all-upcoming-parties.component.html',
  styleUrls: ['./see-all-upcoming-parties.component.scss']
})
export class SeeAllUpcomingPartiesComponent {

}
