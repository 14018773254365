import { Component } from '@angular/core';

@Component({
  selector: 'hype-post-not-found',
  templateUrl: './post-not-found.component.html',
  styleUrl: './post-not-found.component.scss'
})
export class PostNotFoundComponent {

}
