import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { User, UserFactory } from 'src/app/Models/User.model';
import { UserManagementService } from 'src/app/Services/user-management.service';

@Component({
  selector: 'hype-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements AfterViewInit, OnInit{
  selectedTab = 2;
  profileCoverImageUrl: string = "https://picsum.photos/800/200";
  showingEditProfileMenu: boolean = false;
  profilePicture: string = '';
  file: any = '';
  pictureUploaded: boolean = false;
  editProfileForm: FormGroup;

  profileImage = new FormControl('', Validators.required);

  @Input() user: User = UserFactory.shared.createMock();
  @ViewChild('mediaBtn') mediaBtn: any;
  @ViewChild('postsBtn') postsBtn: any;
  @ViewChild('partiesBtn') partiesBtn: any;

  constructor(
    private userManagementService: UserManagementService,
    private formBuilder: FormBuilder,
  ) { 
    this.editProfileForm = this.formBuilder.group({
      username: ['', [
        Validators.minLength(2), 
        Validators.maxLength(20), 
        Validators.pattern(/^(?!.*\s{2})[a-zA-Z0-9!@#$%^&*()\-_=+`~[\]{}|;:'",.<>/?\s]*$/),Validators.pattern(/^\S(.*\S)?$/)
      ]],
      firstName: ['', [
        Validators.minLength(2), 
        Validators.maxLength(20), 
        Validators.pattern(/^(?!.*\s{2})[a-zA-Z0-9!@#$%^&*()\-_=+`~[\]{}|;:'",.<>/?\s]*$/),Validators.pattern(/^\S(.*\S)?$/)
      ]],
      lastName: ['', [
        Validators.minLength(2), 
        Validators.maxLength(20), 
        Validators.pattern(/^(?!.*\s{2})[a-zA-Z0-9!@#$%^&*()\-_=+`~[\]{}|;:'",.<>/?\s]*$/),Validators.pattern(/^\S(.*\S)?$/)
      ]],
      bio: ['', [
        Validators.maxLength(120), 
        Validators.pattern(/^(?!.*\s{2})[a-zA-Z0-9!@#$%^&*()\-_=+`~[\]{}|;:'",.<>/?\s]*$/),Validators.pattern(/^\S(.*\S)?$/)
      ]],
    });
  }
  
  async ngOnInit(): Promise<void> {
    this.user = this.userManagementService.myUser || await this.getMyUser();
    this.profilePicture = this.user.profilePicture;
    // this.tabSelected(2);
    //this.user = await this.getMyUser();
  }
  ngAfterViewInit(): void {
    this.tabSelected(2);
  }
  
  async followButtonClicked(){
    let userA = await this.userManagementService.getUserByUserId('SomeRandomUUID');
    console.log('User A:', userA[0]);
    console.log('Follow button clicked');
  }

  async saveProfileEdits() {
    if(this.editProfileForm.get('username')?.value) {
      const username = this.editProfileForm.get('username')?.value;
      await this.userManagementService.updateDisplayName(this.user.userId, username).then(response => {
        this.user.username = response.data.username;
      }).catch(error => {
        console.log('Failed to update display name', error);
      })
      this.editProfileForm.get('username')?.setValue('');
    }

    if(this.editProfileForm.get('firstName')?.value || this.editProfileForm.get('lastName')?.value) {
      await this.userManagementService.updateUserDetails(this.user.userId, this.editProfileForm.get('firstName')?.value, this.editProfileForm.get('lastName')?.value).then(response => {
        this.user.details.firstName = response.data.firstName.S;
        this.user.details.lastName = response.data.lastName.S; 
        console.log(response)
      }).catch(error => {
        console.log('Failed to update user details', error);
      })
      this.editProfileForm.get('firstName')?.setValue('');
      this.editProfileForm.get('lastName')?.setValue('');
    }

    if(this.editProfileForm.get('bio')?.value) {
      await this.userManagementService.updateUserBio(this.editProfileForm.get('bio')?.value).then(response => {
        this.user.bio = response.data.userBio;
      }).catch(error => {
        console.log('Failed to update user bio', error);
      })
      this.editProfileForm.get('bio')?.setValue('');
    }
    }

  editProfile() {
    if(this.showingEditProfileMenu) {
      this.showingEditProfileMenu = false;
    } else {
      this.showingEditProfileMenu = true;
    }
  }

  uploadProfilePicture(event: any) {
    const element = event.target as HTMLInputElement;
    this.file = element.files;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.profilePicture = e.target.result;
    };
    reader.readAsDataURL(this.file[0]);
    this.pictureUploaded = true;
  }
  
  async confirmProfilePicture() {
    this.pictureUploaded = false;
    // this.profileImage.setValue('');
    const formData = new FormData();
    formData.append('image', this.file[0]);
    await this.userManagementService.updateProfileImage(formData).then(response => {
      console.log('Profile picture updated', response);
      this.user.profilePicture = response.data.newUrl;
    }).catch(error => {
      console.log('Profile picture update error', error);
    });
  }

  cancelProfilePicture() {
    this.file = '';
    this.profilePicture = this.user.profilePicture;
    this.profileImage.setValue('');
    this.pictureUploaded = false;
  }

  tabSelected(tab: number){
    // console.log('Tab selected:', tab);
    this.selectedTab = tab;
    switch(tab){
      case 1:
        // Set tab 1 styling
        this.mediaBtn.nativeElement.classList.replace('button-inactive','button-active');
        this.postsBtn.nativeElement.classList.replace('button-active','button-inactive');
        this.partiesBtn.nativeElement.classList.replace('button-active','button-inactive');
        break;
      case 2:
        // Set tab 2 styling
        this.mediaBtn.nativeElement.classList.replace('button-active','button-inactive');
        this.postsBtn.nativeElement.classList.replace('button-inactive','button-active');
        this.partiesBtn.nativeElement.classList.replace('button-active','button-inactive');
        break;
      case 3:
        // Set tab 3 styling
        this.mediaBtn.nativeElement.classList.replace('button-active','button-inactive');
        this.postsBtn.nativeElement.classList.replace('button-active','button-inactive');
        this.partiesBtn.nativeElement.classList.replace('button-inactive','button-active');
        break;
      default:
        break;
    }
  }
  getMyUser(): Promise<User>{
    let userId = localStorage.getItem('userId') || '';
    let sessionId = localStorage.getItem('sessionId') || '';
    return this.userManagementService.getMyUser(userId, sessionId);
  }
  // getMyUser(): User{
  //   //return this.userManagementService.getMyUser();
  // }
}
