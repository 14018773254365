import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'hype-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit{
  notFoundText: string = 'Page';
  notFoundDescription: string = 'This page could not be found';
  notFoundIcon: string = 'home';
  buttonText: string = 'Back to Home'
  constructor(private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(){
    this.router.events.subscribe((val) => {
      if (this.router.url.startsWith('/profile')){
        this.notFoundText = 'User';
        this.notFoundDescription = 'This user may not exist anymore';
        this.notFoundIcon = 'search';
        this.buttonText = 'Search';
      }
      if (this.router.url.startsWith('/post')){
        this.notFoundText = 'Post';
        this.notFoundDescription = 'This post may not exist anymore';
        this.notFoundIcon = 'search';
        this.buttonText = 'Search';
      }
      if (this.router.url.startsWith('/party')){
        this.notFoundText = 'Party';
        this.notFoundDescription = 'This party may not exist anymore';
        this.notFoundIcon = 'search';
        this.buttonText = 'Search';
      }
    });
  }

  rerouteUser() {
    if(this.notFoundIcon === 'home') {
      this.router.navigate(['/home']);
    }
  }
}
