import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SignupService {
  private readonly baseUrl = `${environment.apiBaseUrl}/authentication/signup`;
  constructor(
    private http: HttpClient,
  ) { }

  async submitSignup(username: string, password: string, email: string, confirmPassword: string, firstName: string, lastName: string, userhandle: string, reCaptchaResponse: string): Promise<any> {
    let body = {
      username: username,
      password: password,
      email: email,
      // confirmPassword: confirmPassword,
      firstName: firstName,
      lastName: lastName,
      userhandle: userhandle
    }

    let headers = new HttpHeaders().set('X-reCaptcha-Key', reCaptchaResponse);
    let response = await this.http.post(this.baseUrl, body, { headers: headers }).toPromise() as any;
    console.log(response);
    if (response?.statusMessage !== 'Request was Succesful') {
      return {
        message: response?.statusMessage,
        reason: response?.reason,
        success: false
      }
    } else {
      return {
        message: response?.statusMessage,
        nextAction: 'Verify your email address to complete the signup process.',
        success: true
      }
    }
  }
}
