<div *ngIf="isLoading; else profileContent">
    LOADING...
    <hype-profile-skeleton></hype-profile-skeleton>
</div>
<ng-template #profileContent>
    <section class="profile-wrapper">
        <div class="profile-container">
            <div class="profile-header-container">
                <hype-profile-picture-custom [size]="'150px'" [url]="user.profilePicture"></hype-profile-picture-custom>
                <div class="user-info-container">
                    <h1>{{user.username || "Temp User"}}</h1>
                    <h5>{{user.userhandle || "temp.userhandle"}}</h5>
                    <div class="user-bio-container">
                        <p>{{user.bio}}</p>
                    </div>
                    <div class="follow-button-container">
                        <button *ngIf="!(user.isFollowing || false)" class="follow-button" (click)="followButtonClicked()">
                            Follow
                        </button>
                        <button *ngIf="user.isFollowing || false" class="unfollow-button" (click)="unfollowButtonClicked()">
                            Unfollow
                        </button>
                    </div>
                </div>
            </div>
            <section class="profile-stats-container">
                <div class="followers-container">
                    <h3>{{user.numFollowers}}</h3>
                    <h5>Followers</h5>
                </div>
                <div class="following-container">
                    <h3>{{user.numFollowings}}</h3>
                    <h5>Following</h5>
                </div>
                <div class="hypescore-container">
                    <h3>{{user.hypescore}}</h3>
                    <h5>Hypescore</h5>
                </div>
            </section>
            <section class="profile-content-wrapper">
                <div class="profile-content-header">
                    <div class="content-header-button-container">
                        <button class="button-inactive" (click)="tabSelected(1)" #mediaBtn>
                            Media
                        </button>
                    </div>
                    <div class="content-header-button-container">
                        <button class="button-inactive" (click)="tabSelected(2)" #postsBtn>
                            Posts
                        </button>
                    </div>
                    <div class="content-header-button-container">
                        <button class="button-inactive" (click)="tabSelected(3)" #partiesBtn>
                            Parties
                        </button>
                    </div>
                </div>
                <section class="profile-content-container">
                    <div class="profile-content" *ngIf="selectedTab === 1">
                        <hype-my-profile-content-media></hype-my-profile-content-media>
                    </div>
                    <div class="profile-content" *ngIf="selectedTab === 2">
                        <hype-profile-content-posts [user]="user"></hype-profile-content-posts>
                    </div>
                    <div class="profile-content" *ngIf="selectedTab === 3">
                        <hype-profile-content-parties [user]="user"></hype-profile-content-parties>
                    </div>
                </section>
            </section>
        </div>
    </section>
</ng-template>
