import { Component } from '@angular/core';

@Component({
  selector: 'hype-user-not-found',
  templateUrl: './user-not-found.component.html',
  styleUrl: './user-not-found.component.scss'
})
export class UserNotFoundComponent {

}
