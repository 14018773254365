import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/Services/Authentication/authentication.service';
import { UserManagementService } from 'src/app/Services/user-management.service';

@Component({
  selector: 'hype-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  hideRightNav: boolean = false;
  hideLogout: boolean = false;
  solidHeader: boolean = false;

  constructor(private router: Router, private authService: AuthenticationService, private userManagementService: UserManagementService) { }

  ngOnInit(){
    // console.log('Header component initialized');
    this.router.events.subscribe((val) => {
      if (this.router.url == '/signin' || this.router.url == '/signup' || this.authService.isLoggedIn){
        this.hideRightNav = true;
      } else {
        this.hideRightNav = false;
      }
      if (this.authService.isLoggedIn){
        this.hideLogout = true;
      } else {
        this.hideLogout = false;
      }
      if (this.router.url.startsWith('/profile')) {
        this.solidHeader = true;
      } else {
        this.solidHeader = false;
      }
    });
  }
  navigateToSignIn(){
    this.router.navigate(['/signin']);
  }
  navigateToSignUp(){
    this.router.navigate(['/signup']);
  }
  navigateToHome(){
    this.router.navigate(['/home']);
  }
  navigateToSettings() {
    this.router.navigate(['/settings'])
  }
  async logout(){
    // Call logout endpoint

    // localStorage.removeItem('userId');
    // localStorage.removeItem('sessionId');
    // this.userManagementService.myUser = undefined;
    // this.authService.isLoggedIn = false;
    if (await this.authService.logout()){
      this.router.navigate(['/signin']);
    } else {
      console.error('Logout failed');
    }
    // this.router.navigate(['/signin']);
  }
}
