import { Component, EventEmitter, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/Services/Authentication/authentication.service';

@Component({
  selector: 'hype-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string = '';
  password: string = '';
  loginError: string = '';
  hasLoginError: boolean = false;
  loginForm: FormGroup = this.fb.group({
    username: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
  });
  
  submitLogin(): EventEmitter<any> {
    // Submit password and username to the server
    // If the server returns a token, store it in the local storage
    // If the server returns an error, display the error message
    this.authService.submitLogin(this.username, this.password).then((response) => {
      if (response.userId && response.sessionId) {
        console.log('Login successful');
        this.router.navigate(['/home']);
      } else {
        console.log('Login failed');
        this.hasLoginError = true;
        this.loginError = 'Invalid username or password';
      }
    }).catch((error) => {
      console.error(error);
      this.hasLoginError = true;
      this.loginError = 'Email is not verified.';
    });
    return new EventEmitter();
    // this.router.navigate(['/home']);
    // return new EventEmitter();
  }
  constructor(
    private fb: FormBuilder, 
    private authService: AuthenticationService,
    private router: Router,
  ) { }
  ngOnInit(): void {
    this.loginForm.valueChanges.subscribe((value) => { 
      // console.log('Form value changed:', value);
      this.username = value.username;
      this.password = value.password;
      if (this.loginForm.valid) {
        console.log('Form is valid');
        this.hasLoginError = false;
      }
    });
  }
}
