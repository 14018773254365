import axios from 'axios';

export async function asyncGetRandomSquareImage(size: number = 400): Promise<string> {
    try {
        const response = await axios.get(`https://picsum.photos/${size}`);
        // console.log('Image response:', response.headers["picsum-id"]);
        // return `https://picsum.photos/id/${response.headers["picsum-id"]}`;
        return `https://picsum.photos/id/${response.headers["picsum-id"]}/${size}/${size}`;
    } catch (error) {
        console.error('Error fetching image:', error);
        throw error;
    }
}

/**
 * Converts a timestamp to a Date object
 * @param timestamp The timestamp to convert
 * @returns The Date object
 * @example
 * timestampToDate(1620000000000)
 * // Returns a Date object representing the timestamp
 * @category HelperFunction
 */
 export function timestampToDate(timestamp: number | string): Date {
    // if (typeof timestamp !== 'string' || typeof timestamp !== 'number') {
    //     // throw new Error('Timestamp must be a string or number');
    //     console.error('Timestamp must be a string or number');
    //     return new Date();
    // } else if (typeof timestamp === 'string') {
    //     var timestampString = timestamp as string;
    //     timestamp = parseInt(timestampString.split('-')[6] || '50000');
    // }
    var timestampString = timestamp as string;
        timestamp = parseInt(timestampString.split('-')[6] || '50000');
    return new Date(timestamp);
}