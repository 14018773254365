import { Component, Input } from '@angular/core';
import { Post, PostFactory } from 'src/app/Models/Post.model';

@Component({
  selector: 'hype-my-profile-content-media',
  templateUrl: './my-profile-content-media.component.html',
  styleUrls: ['./my-profile-content-media.component.scss']
})
export class MyProfileContentMediaComponent {
  @Input() posts: Post[] = PostFactory.shared.createMockArray(23);
}

// [1,2,3,4,5,6,7,8,8,8,8,8]