import { timestampToDate } from "../HelperFunctions/Common.helper";
import { CommonUrls, Factory, generateRandomFirstName, generateRandomLastName, generateRandomNumber, generateRandomUsername, getRandomSquareImage } from "../MockData/CommonMockData";

interface PrimitiveComment {
    id: string;
    authorId: string;
}

export interface Comment extends PrimitiveComment {
    content: string;
    dateCreated: Date;
    authorUsername: string;
    profilePicUrl: string;
    numLikes: number;
    numReplies: number;
    isLiked?: boolean;
}

export class Comment implements Comment {

    constructor(id?: string, authorId?: string, authorUsername?: string, content?: string, profilePic?: string, dateCreated?: Date, numLikes?: number, numReplies?: number, isLiked?: boolean) {
        this.id = id || "";
        this.authorId = authorId || "";
        this.authorUsername = authorUsername || "";
        this.content = content || "";
        this.dateCreated = dateCreated || new Date();
        this.profilePicUrl = profilePic || CommonUrls.templatePhoto;
        this.numLikes = numLikes || 0;
        this.numReplies = numReplies || 0;
        this.isLiked = isLiked || false;
    }
}

export class CommentFactory implements Factory {
    create: () => Comment = CommentFactory.createNewComment;
    createMock: () => Comment = CommentFactory.createMockComment;
    createMockArray: (n: number) => Comment[] = CommentFactory.createMockCommentArray;
    static shared = new CommentFactory();
    static createNewComment(): Comment {
        return new Comment();
    }
    static createMockComment(): Comment {
        return new Comment(
            undefined,
            undefined,
            generateRandomUsername(),
            "I am a mock comment",
            getRandomSquareImage(),
            new Date(),
            generateRandomNumber(3),
            generateRandomNumber(3),
            false,
        );
    }
    static createMockCommentArray(n: number): Comment[] {
        let comments: Comment[] = [];
        for (let i = 0; i < n; i++) {
            comments.push(CommentFactory.createMockComment());
        }
        return comments;
    }
}

export interface CommentV2  {
    postId: string;
    commentId: string;
    authorId: string;
    text: string;
    numLikes: number;
    numReplies: number;
    timestamp: Date;
    getDateString(): string;
    isLiked: boolean;
}

export class CommentV2 {
    constructor(
        postId?: string,
        commentId?: string,
        authorId?: string,
        text?: string,
        numLikes?: number,
        numReplies?: number,
        timestamp?: Date,
        isLiked?: boolean,
    ) {
        this.postId = postId || '';
        this.commentId = commentId || '';
        this.authorId = authorId || '';
        this.text = text || '';
        this.numLikes = numLikes || 0;
        this.numReplies = numReplies || 0;
        this.timestamp = timestamp || new Date();
        this.isLiked = isLiked || false;
    }
    getDateString(): string {
        const currentDate = new Date();
        const diff = currentDate.getTime() - this.timestamp.getTime();
        // difference in seconds, minutes, hours, days, weeks, months, years
        const hour = 1000 * 60 * 60;
        const twoHours = hour * 2;
        const minute = 1000 * 60;
        const twoMinutes = minute * 2;
        const day = 1000 * 60 * 60 * 24;
        const week = day * 7;
        const twoWeeks = week * 2;
        const month = day * 30;
        const twoMonths = month * 2;
        const year = day * 365;
        const twoYears = year * 2;
        const decade = year * 10;

        if (diff < minute) return 'Just now'; //shows when diff less than 60 seconds
        if (diff < twoMinutes) return 'A minute ago'; //shows when diff less than 120 seconds
        if (diff < (minute * 60)) {
            let minutes = Math.floor(diff / minute);
            return `${minutes} minutes ago`;
        }
        if (diff < twoHours) return 'An hour ago'; //shows when diff less than 120 minutes
        if (diff < (hour * 12)) {
            let hours = Math.floor(diff / hour);
            return `${hours} hours ago`;
        }
        if (diff < day) return 'Today';
        if (diff < (day * 2)) return 'Yesterday';
        if (diff < week) {
            let days = Math.floor(diff / day);
            return `${days} days ago`;
        }
        // if (diff < week) return 'This week';
        if (diff < twoWeeks) return 'A week ago';
        if (diff < month) {
            let weeks = Math.floor(diff / week);
            return `${weeks} weeks ago`;
        }
        // if (diff < month) return 'This month';
        if (diff < twoMonths) return 'A month ago';
        if (diff < year) {
            let months = Math.floor(diff / month);
            return `${months} months ago`;
        }
        if (diff < twoYears) return 'A year ago';
        if (diff < decade) {
            let years = Math.floor(diff / year);
            return `${years} years ago`;
        }
        // if (diff < year) return 'This year';
        return this.timestamp.toDateString();
    }
}

export class CommentV2Factory implements Factory {
    create: () => CommentV2 = CommentV2Factory.createNewComment;
    createMock: () => CommentV2 = CommentV2Factory.createMockComment;
    createMockArray: (n: number) => CommentV2[] = CommentV2Factory.createMockArray;
    createFromHttp: (response: any) => CommentV2 = CommentV2Factory.createCommentFromData;
    createArrayFromHttp: (response: any) => CommentV2[] = CommentV2Factory.createCommentArrayFromData
    static shared = new CommentV2Factory()
    static createNewComment(): CommentV2 {
        return new CommentV2();
    }
    static createMockComment(): CommentV2 {
        return new CommentV2(
            undefined,
            undefined,
            generateRandomUsername(),
            "I am a mock comment",
            generateRandomNumber(2),
            generateRandomNumber(1),
            new Date(),
            false,
        );
    }
    static createMockArray(n: number): CommentV2[] {
        let comments: CommentV2[] = []
        for (let i = 0; i < n; i++) {
            comments.push(CommentV2Factory.createMockComment());
        }
        return comments;
    }
    static createCommentFromData(response: any): CommentV2 {
        if(response.status !== 200) {
            console.log('Error: ', response?.statusMessage || "Unknown error");
            return new CommentV2();
        };
        let data = response.data.comment;
            return new CommentV2(
                data?.postId || '',
                data?.commentId || '',
                data?.authorId || '',
                data?.text || '',
                data?.numLikes || 0,
                data?.numReplies || 0,
                timestampToDate(data?.timestamp as string) || new Date(),
                data?.isLiked || false,
            )
    }
    static createCommentArrayFromData(response: any): CommentV2[] {
        if(response.status !== 200) {
            console.log('Error: ', response?.statusMessage || "Unknown error");
            return [];
        };
        let data = response.data.comments
        let comments: CommentV2[] = data.map((comment: any) => {
            return new CommentV2(
                comment?.postId || '',
                comment?.commentId || '',
                comment?.authorId || '',
                comment?.text || '',
                comment?.numLikes || 0,
                comment?.numReplies || 0,
                timestampToDate(comment?.timestamp as string) || new Date(),
                comment?.isLiked || false,

            )
        })
        return comments;
    }
}