<div class="party-list-container">
    <div class="party" *ngFor="let party of previewParties" (click)="showPartyDetails(party)">
        <img src="{{party.partyFlyer}}" alt="party-image">
        <!-- <h1>Hi</h1> -->
        <div class="overlay">
            <h4>{{party.name}}</h4>
        </div>
    </div>
</div>
<hype-overlay [(display)]="showingPartyDetails">
    <section class="party-details-wrapper">
        <div class="header">
            <!-- <h3>Party Details</h3> -->
            <h3>{{selectedParty.name}}</h3>
            <button (click)="closePartyDetails()">
                <hype-ion-icon-wrapper [iconName]="'close'"></hype-ion-icon-wrapper>
            </button>
        </div>
        <hype-party-details [party]="selectedParty" style="height: 100%;"></hype-party-details>
    </section>
</hype-overlay>