<div class="nav-container">
    <nav class="navbar">
        <button id="home" (click)="navigateToHome()">
            <ion-icon name="home"></ion-icon>
            <h1>Home</h1>
        </button>
        <button id="party" (click)="navigateToParty()">
            <ion-icon name="star"></ion-icon>
            <h1>Party</h1>
        </button>
        <button id="search" (click)="navigateToSearch()">
            <ion-icon name="compass"></ion-icon>
            <h1>Search</h1>
        </button>
        <button id="messages" (click)="navigateToMessages()">
            <ion-icon name="chatbubbles"></ion-icon>
            <h1>Messages</h1>
        </button>
        <button id="profile" (click)="navigateToProfile()">
            <ion-icon name="person"></ion-icon>
            <h1>Profile</h1>
        </button>
    </nav>
    <div class="copyright-container">
        <p>&copy; <i>2024 HypeTrain</i></p>
    </div>
</div>