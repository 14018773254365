import { Component, OnInit } from '@angular/core';
import { MapService } from 'src/app/Services/Map/map.service';

@Component({
  selector: 'hype-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  center: google.maps.LatLngLiteral = {lat: 34, lng: -82};
  viewHeight = document.documentElement.clientHeight - 70;
  parties: any[] = [];
  display!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    center: { lat: 40, lng: -20 },
    zoom: 17,
    mapId: "DEMO_MAP_ID",
  };

  constructor(private mapService: MapService) {
    window.addEventListener('resize', () => {
      this.viewHeight = document.documentElement.clientHeight - 70;
    });
  }

  ngOnInit() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        console.log(position.coords.latitude, position.coords.longitude)
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const locationKey: string = 'location';
        await this.mapService.searchParties(1, position.coords.latitude, position.coords.longitude, 0.5).then((parties) => {
          this.parties = parties.map((party: any) => {
            let partyMarker = this.createMarker(party.name, party.time)
            return {...party, [locationKey]: { lat: party.location.lat, lng: party.location.lng, content: partyMarker }}
          })
        })
      });
    }
    this.display = this.center;
  }

  trackByLocation(index: number, party: any): string {
    return party.location.lat + ',' + party.location.lng;
  }

  onMarkerClick() {

  }
  
  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng!.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng!.toJSON();
  }

  createButton(text: string, div: any) {
    let button = document.createElement('button');
    button.textContent = text;
    div.appendChild(button);
  }

  createMarker(name: string, time: string) {
    let partyMarker = document.createElement("div");
    partyMarker.classList.add('marker');
    partyMarker.innerHTML = `<strong>${name}</strong><br>${time}`;

    let infoDiv = document.createElement("div");
    infoDiv.classList.add("info", "hide");
    partyMarker.appendChild(infoDiv);

    this.createButton('Get Directions', infoDiv);
    this.createButton('Buy Tickets', infoDiv);
    this.createButton('Share', infoDiv);

    partyMarker.addEventListener("click", function() {
        infoDiv.classList.toggle("hide");
    });
    infoDiv.addEventListener("click", function(event) {
      event.stopPropagation(); 
    });

    return partyMarker
  }
}
