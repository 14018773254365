import { Component, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment-timezone';
import { PartyService } from 'src/app/Services/Party/party.service';
import { Router } from '@angular/router';

@Component({
    selector: 'hype-party-creation',
    templateUrl: './party-creation.component.html',
    styleUrls: ['./party-creation.component.scss'],
})
export class PartyCreationComponent {
    @ViewChild('progressBar') progressBar: any;
    @ViewChild('submitButton') submitButton: any;
    @ViewChild('nextButton') nextButton: any;
    @ViewChild('previousButton') previousButton: any;
    @ViewChild('djButton') djButton: any;
    @ViewChild('bandsButton') bandsButton: any;
    @ViewChild('addressButton') addressButton: any;
    @ViewChild('propertiesButton') propertiesButton: any;
    pageNumber: number = 1;
    numPages: number = 6;
    lastPage: boolean = false;
    photos: string[] = [];
    files: any[] = [];
    tbaIsChecked: boolean = false;
    selectedTab = 1;
    locationSelectedTab = 1;
    ticketsIsChecked: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private partyService: PartyService,
        private router: Router,
    ) {}

    ngOnInit(): void {}

    partyForm = this.formBuilder.group({
        partyName: ['', Validators.required],
        partyDescription: ['', Validators.required],
        partyDate: ['', Validators.required],
        partyTime: ['', Validators.required],
        flyer: [[]],
        tbaSlider: [false],
        street: ['', Validators.required],
        city: ['', Validators.required],
        zipcode: ['', Validators.required],
        state: ['', Validators.required],
        country: ['', Validators.required],
        ticketSlider: [false],
    });

    ngAfterViewInit() {
        this.progressBar.nativeElement.style.width = `${
            (this.pageNumber / this.numPages) * 100
        }%`;
        this.disableButton(this.previousButton.nativeElement);
    }

    goToPage(pageNumber: number) {
        this.pageNumber = pageNumber;
        this.progressBar.nativeElement.style.width = `${
            (pageNumber / this.numPages) * 100
        }%`;
        this.pageNumber === this.numPages
            ? (this.lastPage = true)
            : (this.lastPage = false);
        this.pageNumber === 1
            ? this.disableButton(this.previousButton.nativeElement)
            : this.enableButton(this.previousButton.nativeElement);
    }

    nextPage() {
        if (this.pageNumber < this.numPages) this.goToPage(this.pageNumber + 1);
        console.log('party data', this.partyForm.value);
        console.log('Last page:', this.lastPage);
    }

    previousPage() {
        if (this.pageNumber > 1) this.goToPage(this.pageNumber - 1);
        // (this.pageNumber === this.numPages) ? this.lastPage = true : this.lastPage = false;
        // (this.pageNumber === 1) ? this.disableButton(this.previousButton.nativeElement) : this.enableButton(this.previousButton.nativeElement);
    }

    toggleTbaCheck() {
        this.tbaIsChecked = !this.tbaIsChecked;
    }

    toggleTicketCheck() {
        this.ticketsIsChecked = !this.ticketsIsChecked;
    }

    createTicket() {
        console.log('Ticket creation button clicked!');
    }

    submitParty() {
        const formData = new FormData();
        const partyName = this.partyForm.get('partyName')?.value;
        const partyDescription = this.partyForm.get('partyDescription')?.value;
        const partyDate = (
            this.partyForm.get('partyDate')?.value as string
        )?.split('-');
        const partyTime = (
            this.partyForm.get('partyTime')?.value as string
        )?.split(':');
        const ticketSlider = this.partyForm.get('ticketSlider')?.value;
        const hasLocation = !this.partyForm.get('tbaSlider')?.value;
        const location =
            this.partyForm.get('street')?.value +
            ', ' +
            this.partyForm.get('city')?.value +
            ' ' +
            this.partyForm.get('state')?.value + 
            ' ' +
            this.partyForm.get('zipcode')?.value;
        const timezone = moment().tz(moment.tz.guess()).format('z');

        for (const file of this.files) {
          formData.append('image', file, file.name);
        };

        formData.append(
            'data',
            JSON.stringify({
                newParty: {
                    name: partyName,
                    description: partyDescription,
                    date: {
                        month: partyDate[1],
                        day: partyDate[2],
                        year: partyDate[0],
                    },
                    time: {
                        hour: partyTime[0],
                        minute: partyTime[1],
                        timezone: timezone, 
                    },
                    isRsvp: false, //temporary, add rsvp form input?
                    isSellingTickets: ticketSlider,
                    hasDj: false, //temporary, change when dj/bands option implemented
                    hasProperty: false, //temporary, change when property option added
                    hasLocation: hasLocation,
                    entertainerId: '', //temporary, change when entertainer option added
                    propertyId: '', //temporary, change when property option added
                    locationAddress: location, 
                    tickets: [], //temporary, change when ticket creation implemented
                },
            })
        );

        this.partyService.createParty(formData).then(response => {
          console.log('Party created response', response);
          this.router.navigate(['/party']);
        }).catch(error => {
          console.log('Party creation error', error);
        });
    }

    // Adds the disabled-button class to the button
    disableButton(button: any) {
        button.classList.add('disabled-button');
    }

    // Removes the disabled-button class from the button
    enableButton(button: any) {
        button.classList.remove('disabled-button');
    }

    removeFlyer(index: number) {
        this.photos.splice(index, 1);
        this.files.splice(index, 1);
    }

    uploadFlyer(event: any) {
        const element = event.target as HTMLInputElement;
        if (element.files && element.files[0]) {
            const files = element.files;
            this.files.push(files[0]);
            Array.from(files).forEach((file) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    this.photos.push(e.target.result);
                };
                reader.readAsDataURL(file);
            });
        }
    }

    musicTabSelected(tab: number) {
        this.selectedTab = tab;
        switch (tab) {
            case 1:
                this.djButton.nativeElement.classList.replace(
                    'button-inactive',
                    'button-active'
                );
                this.bandsButton.nativeElement.classList.replace(
                    'button-active',
                    'button-inactive'
                );
                break;
            case 2:
                this.djButton.nativeElement.classList.replace(
                    'button-active',
                    'button-inactive'
                );
                this.bandsButton.nativeElement.classList.replace(
                    'button-inactive',
                    'button-active'
                );
                break;
        }
    }

    locationTabSelected(tab: number) {
        this.locationSelectedTab = tab;
        switch (tab) {
            case 1:
                this.addressButton.nativeElement.classList.replace(
                    'button-inactive',
                    'button-active'
                );
                this.propertiesButton.nativeElement.classList.replace(
                    'button-active',
                    'button-inactive'
                );
                break;
            case 2:
                this.addressButton.nativeElement.classList.replace(
                    'button-active',
                    'button-inactive'
                );
                this.propertiesButton.nativeElement.classList.replace(
                    'button-inactive',
                    'button-active'
                );
                break;
        }
    }
}
