<section class="search-wrapper">
    <section class="map-wrapper">
        <hype-map></hype-map>
    </section>

    <!-- Side overlay for searching -->
    <div class="side-overlay-wrapper" #sideOverlay>
        <button class="expand-button" (click)="toggleSearch()">
            <hype-ion-icon-wrapper [iconName]="(searchIsExpanded) ? 'arrow-forward': 'arrow-back'"></hype-ion-icon-wrapper>
        </button>
        <input type="text" placeholder="Search for a party">
        
    </div>

    <!-- Top Search Overlay -->
    <div class="search-overlay-wrapper">

    </div>
    
</section>

