import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MemberFactory } from 'src/app/Models/Members.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FollowingsService {
  readonly baseUrl = `${environment.apiBaseUrl}/core-api/v2/users/my-followings`
  readonly mutualsUrl = `${environment.apiBaseUrl}/core-api/v2/users/my-mutual-followings`;

  // readonly baseUrl = "https://api.hypetrain.online/core-api/v2/users/my-followings";
  // readonly baseUrl = "http://localhost:3000/core-api/v2/users/my-followings";
  constructor(
    private http: HttpClient,
  ) { }

  async getFollowings(userId: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 10,
        pageNumber: 1,
      },
      userId: userId
    }
    let response = await this.http.post(this.baseUrl, body, { headers: headers }).toPromise();
    return response;
  }

  async followUser(followUserId: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      followeeId: followUserId
    }
    let response = await this.http.put(this.baseUrl, body, { headers: headers }).toPromise();
    return response;
  }

  async unfollowUser(followUserId: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      followeeId: followUserId
    }
    let response = await this.http.delete(this.baseUrl, { headers: headers, body: body }).toPromise();
    return response;
  }

  async getFollowers(userId: string): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        numRecords: 10,
        pageNumber: 1,
      },
      userId: userId
    }
    let response = await this.http.post(this.baseUrl, body, { headers: headers }).toPromise();
    return response;
  }

  async getMutuals(pageNumber: number): Promise<any> {
    let sessionToken = localStorage.getItem('sessionId') || '';
    let myUserId = localStorage.getItem('userId') || '';
    let headers = new HttpHeaders().set('authorization', `${sessionToken}`);
    headers = headers.set('X-UserId', myUserId);
    headers = headers.set('Content-Type', 'application/json');
    const body = {
      pagination: {
        pageNumber: pageNumber,
        numRecords: 20,
      }
    }
    let response = await this.http.post(this.mutualsUrl, body, { headers: headers }).toPromise();
    return MemberFactory.createNewMemberArrayFromData(response);
  }
}
