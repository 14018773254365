<div class="party-see-all-container">
    <h2 class="list-type">{{listType}} Parties</h2>
    <div class="party-list-container" infinite-scroll (scrolled)="handleScroll()">
        <div class="party" *ngFor="let party of parties" (click)="showPartyDetails(party)">
            <img src="{{party.partyFlyer}}" alt="party-flyer-image">
            <div class="overlay">
                <h4>{{party.name}}</h4>
            </div>
        </div>
    </div>
</div>
<hype-overlay [(display)]="showingPartyDetails">
    <section class="party-details-wrapper">
        <div class="header">
            <h3>{{selectedParty.name}}</h3>
            <button (click)="closePartyDetails()">
                <hype-ion-icon-wrapper [iconName]="'close'"></hype-ion-icon-wrapper>
            </button>
        </div>
        <hype-party-details [party]="selectedParty" style="height: 100%;"></hype-party-details>
    </section>
</hype-overlay>