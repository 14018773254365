import { Factory, generateRandomDate, generateRandomNumber, generateRandomPartyName, generateRandomPartyDescription, getRandomSquareImage } from "../MockData/CommonMockData";


export interface searchParty {
    date: string;
    description: string;
    entertainerId: string;
    hasDj: boolean;
    hostId: string;
    hypescore: number;
    isSellingTickets: boolean;
    isRsvp: boolean;
    location: {
        lat: number;
        lng: number;
    };
    name: string;
    partyFlyer: string;
    partyId: string;
    time: string;
    timezone: string;
}

export class searchParty {
    constructor(
        date?: string,
        description?: string,
        entertainerId?: string,
        hasDj?: boolean,
        hostId?: string,
        hypescore?: number,
        isSellingTickets?: boolean,
        isRsvp?: boolean,
        location?: { lat: number, lng: number },
        name?: string,
        partyFlyer?: string,
        partyId?: string,
        time?: string,
        timezone?: string,
    ) 
    {
        this.date = date || '';
        this.description = description || '';
        this.entertainerId = entertainerId || '';
        this.hasDj = hasDj || false;
        this.hostId = hostId || '';
        this.hypescore = hypescore || 0;
        this.isSellingTickets = isSellingTickets || false;
        this.isRsvp = isRsvp || false;
        this.location = location || { lat: 0, lng: 0}
        this.name = name || '';
        this.partyFlyer = partyFlyer || '';
        this.partyId = partyId || '';
        this.time = time || '';
        this.timezone = timezone || '';
    }
}

export class MapFactory implements Factory {
    create: () => searchParty = MapFactory.createNewParty;
    createMock: () => searchParty = MapFactory.createMockParty;
    createMockArray: (n: number) => searchParty[] = MapFactory.createMockPartyArray;
    createArrayFromHttp: (response: any) => searchParty[] = MapFactory.createNewPartyArrayFromData;
    static shared = new MapFactory();
    static createNewParty(): searchParty {
        return new searchParty();
    };
    static createMockParty(): searchParty {
        return new searchParty(
            String(generateRandomDate()),
            generateRandomPartyDescription(),
            undefined,
            false,
            undefined,
            generateRandomNumber(2),
            false,
            false,
            undefined,
            generateRandomPartyName(),
            getRandomSquareImage(),
            undefined,
            undefined,
            undefined,
        );
    };
    static createMockPartyArray (n: number): searchParty[] {
        let parties: searchParty[] = [];
        for (let i = 0; i < n; i++) {
            parties.push(MapFactory.createMockParty());
        };
        return parties;
    };
    static createNewPartyArrayFromData(response: any): searchParty[] {
        if(response.status !== 200) {
            console.log('Error: ', response?.statusMessage || "Unknown error");
            return [];
        };

        let data = response.data?.parties
        let parties: searchParty[] = data.map((party: any) => {
            return new searchParty(
                party?.date || '',
                party?.description || '',
                party?.entertainerId || '',
                party?.hasDj || false,
                party?.hostId || '',
                party?.hypescore || 0,
                party?.isSellingTickets || false,
                party?.isRsvp || false,
                { lat: Number(party?.location.latitude) || 0, lng: Number(party?.location.longitude) || 0 },
                party?.name || '',
                party?.partyFlyer.url || '',
                party?.partyId || '',
                party?.time,
                party?.timezone || '',
            );
        });
        return parties;
    };
}