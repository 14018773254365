import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, NgModule } from '@angular/core';
import { OverlayComponent } from 'src/app/Components/Common/overlay/overlay.component';
import { Post, PostFactory } from 'src/app/Models/Post.model';
import { AppModule } from 'src/app/app.module';
import { CommonModule } from '@angular/common';
import { FeedPostCommentsComponent } from '../feed-post-comments/feed-post-comments.component';


@Component({
  standalone: true,
  selector: 'hype-feed-post-footer',
  templateUrl: './feed-post-footer.component.html',
  styleUrls: ['./feed-post-footer.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [OverlayComponent, CommonModule, FeedPostCommentsComponent]
})
export class FeedPostFooterComponent {
  @Input() post: Post = PostFactory.shared.createMock();
  postId: string = '';
  showingAddMenu: boolean = false;
  
  showComments(post: any) {
    this.showingAddMenu = !this.showingAddMenu;
    console.log(this.showingAddMenu);
    this.postId = post.postId;
  }
}
