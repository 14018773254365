import { timestampToDate } from "../HelperFunctions/Common.helper";
import { CommonUrls, Factory, generateRandomConversationName, generateRandomDate, generateRandomMessage, generateRandomNumber, generateRandomUsername, getRandomSquareImage } from "../MockData/CommonMockData";

export interface Conversation {
    conversationId: string;
    memberCount: number;
    lastMessage: string;
    lastMessageTimestamp: Date;
    lastMessageAuthor: string;
    name: string;
    conversationImage: string;
    unread: boolean;
    getDateString(): string;
}

export class Conversation implements Conversation {
    constructor(
        conversationId?: string,
        memberCount?: number,
        lastMessage?: string,
        lastMessageTimestamp?: Date,
        lastMessageAuthor?: string,
        name?: string,
        conversationImage?: string,
        unread?: boolean,
    ) 
    {
        this.conversationId = conversationId || '';
        this.memberCount = memberCount || 0;
        this.lastMessage = lastMessage || '';
        this.lastMessageTimestamp = lastMessageTimestamp || new Date();
        this.lastMessageAuthor = lastMessageAuthor || '';
        this.name = name || '';
        this.conversationImage = conversationImage || '';
        this.unread = unread || false;
    }
    getDateString(): string {
        const currentDate = new Date();
        const diff = currentDate.getTime() - this.lastMessageTimestamp.getTime();
        // difference in seconds, minutes, hours, days, weeks, months, years
        const hour = 1000 * 60 * 60;
        const twoHours = hour * 2;
        const minute = 1000 * 60;
        const twoMinutes = minute * 2;
        const day = 1000 * 60 * 60 * 24;
        const week = day * 7;
        const twoWeeks = week * 2;
        const month = day * 30;
        const twoMonths = month * 2;
        const year = day * 365;
        const twoYears = year * 2;
        const decade = year * 10;

        if (diff < minute) return 'Just now'; //shows when diff less than 60 seconds
        if (diff < twoMinutes) return 'A minute ago'; //shows when diff less than 120 seconds
        if (diff < (minute * 60)) {
            let minutes = Math.floor(diff / minute);
            return `${minutes} minutes ago`;
        }
        if (diff < twoHours) return 'An hour ago'; //shows when diff less than 120 minutes
        if (diff < (hour * 12)) {
            let hours = Math.floor(diff / hour);
            return `${hours} hours ago`;
        }
        if (diff < day) return 'Today';
        if (diff < (day * 2)) return 'Yesterday';
        if (diff < week) {
            let days = Math.floor(diff / day);
            return `${days} days ago`;
        }
        // if (diff < week) return 'This week';
        if (diff < twoWeeks) return 'A week ago';
        if (diff < month) {
            let weeks = Math.floor(diff / week);
            return `${weeks} weeks ago`;
        }
        // if (diff < month) return 'This month';
        if (diff < twoMonths) return 'A month ago';
        if (diff < year) {
            let months = Math.floor(diff / month);
            return `${months} months ago`;
        }
        if (diff < twoYears) return 'A year ago';
        if (diff < decade) {
            let years = Math.floor(diff / year);
            return `${years} years ago`;
        }
        // if (diff < year) return 'This year';
        return this.lastMessageTimestamp.toDateString();
    }
}

export class ConversationFactory implements Factory {
    create: () => Conversation = ConversationFactory.createNewConversation;
    createMock: () => Conversation = ConversationFactory.createMockConversation;
    createMockArray: (n: number) => Conversation[] = ConversationFactory.createMockConversationArray;
    createFromHttp: (response: any) => Conversation = ConversationFactory.createNewConversationFromData;
    createArrayFromHttp: (response: any) => Conversation[] = ConversationFactory.createNewConversationArrayFromData;
    static shared = new ConversationFactory();
    static createNewConversation(): Conversation {
        return new Conversation();
    };
    static createMockConversation(): Conversation {
        return new Conversation(
            undefined,
            generateRandomNumber(1),
            generateRandomMessage(),
            generateRandomDate(),
            generateRandomUsername(),
            generateRandomConversationName(),
            getRandomSquareImage(),
            false,
        );
    };

    static createMockConversationArray (n: number): Conversation[] {
        let conversations: Conversation[] = [];
        for (let i = 0; i < n; i++) {
            conversations.push(ConversationFactory.createMockConversation());
        };
        return conversations;
    };

    static createNewConversationFromData(response: any): Conversation {
        if(response.status !== 200) {
            console.log('Error: ', response?.statusMessage || "Unknown error");
            return new Conversation();
        };

        let conversation = response.data?.conversation;
            return new Conversation(
                conversation?.conversationId || '',
                conversation?.memberCount || 0,
                conversation?.lastMessage || '',
                timestampToDate(conversation?.lastMessageTimestamp as string) || new Date(),
                conversation?.lastMessageAuthor || '',
                conversation?.name || '',
                conversation?.conversationImage?.url || '',
                conversation?.unread || false,
            );
    };

    static createNewConversationArrayFromData(response: any): Conversation[] {
        if(response.status !== 200) {
            console.log('Error: ', response?.statusMessage || "Unknown error");
            return [];
        };

        let data = response.data?.conversations;
        let conversations: Conversation[] = data.map((conversation: any) => {
            return new Conversation(
                conversation?.conversationId || '',
                conversation?.memberCount || 0,
                conversation?.lastMessage || '',
                timestampToDate(conversation?.lastMessageTimestamp as string) || new Date(),
                conversation?.lastMessageAuthor || '',
                conversation?.name || '',
                conversation?.conversationImage?.url || '',
                conversation?.unread || false,
            );
        });
        return conversations;
    };
}